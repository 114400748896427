// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Button from "@mui/material/Button"

import SearchQuery from "../../components/LocalElements/Search/SearchQuery"
import FilterMetersBase from "../../components/Meters/FilterMetersBase"
import TableWater from "../../components/Meters/MeterWater/Tab"
import TableGas from "../../components/Meters/MeterGas/Tab"
import Selector from "../../components/LocalElements/Selector/reactSelect/Selector"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import SearchIcon from "../../../img/icon/SearchIcon"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import CloseIcon from '@mui/icons-material/Close'
import filterIcon from '../../../img/filter.svg'

import RightSideContainer from "../../components/LocalElements/RightSideContainer/RightSideContainer"

import { setSearchQuery } from "../../../store/slices/app/views/meters"
import { refreshMeters } from "../../../store/slices/app/controlers/updater"
import { setOpen as setOpenRightContainer } from "../../../store/slices/app/comps/rightContainer"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"
import { changeFilterParams, changeFilterMethods, changeFilterTitles, changeFilterCustomers } from "../../../store/slices/app/views/meters"
import useWindowDimensions from "../../hooks/useWindowDimensions"

import R from "../../../services/app/request.service"
import D from "../../../store/localStorage/dispatcher"

const Meters = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mainRef = useRef()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const measurement_id = useSelector((state) => state.meterReducer.measurement_id)
  const selectedMeters = useSelector((state) => state.meterReducer.selectedMeters)

  const customersDataForFilter = useSelector((state) => state.meterReducer.filterParams.customers)
  const titlesDataForFilter = useSelector((state) => state.meterReducer.filterParams.titles)
  const methodsDataForFilter = useSelector((state) => state.meterReducer.filterParams.methods)

  const methods = useSelector((state) => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector((state) => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector((state) => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector((state) => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector((state) => state.meterReducer.filterParams.isPDF)

  const [ searchVisible, setSearchVisible ] = useState(false)
  const [ scrollSearchVisible, setScrollSearchVisible ] = useState(false)

  const count = useMemo(() => {
    let filtersCount = 0

    if ( methods.length > 0 ) filtersCount++
    if ( customers ) filtersCount++
    if ( titles ) filtersCount++
    if ( linkSI ) filtersCount++
    if ( pdf ) filtersCount++

    if ( methods.length === 0 && !customers && !titles && !linkSI ) filtersCount = 0

    false && console.log(methods, customers, titles, linkSI)

    return filtersCount
  }, [ methods, customers, titles, linkSI, pdf ])

  const [searchQueryLocal, setSearchQueryLocal] = useState("")

  const changeSearchQuery = (param) => {
    setSearchQueryLocal(param)
    dispatch(setSearchQuery(param))
  }

  const deleteMeters =  async () => {
    const { status, data } = await R.deleteMeters(measurement_id, {
      ids: selectedMeters
    })

    if ( status === 200 ) {
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(
        setMessage(
          "Приборы были успешно удалены"
        )
      )

      dispatch(refreshMeters())
    }

    false && console.log(data)
  }

  const handleMetersClick = () => {
    if ( measurement_id === 1 ) {
      navigate("/metriva/meters/create_water_base")
    } else if ( measurement_id === 5 ) {
      navigate("/metriva/meters/create_gas_base")
    }
  }

  useEffect(() => {
    false && console.log({
      ids: selectedMeters
    })
  }, [ selectedMeters ])

  useEffect(() => {
    // ----------------------------------------------------------------
    // доработать эту логику постепенно
    // ----------------------------------------------------------------

    if ( searchQueryLocal.length > 0 ) {
      setTimeout(() => { false && setSearchVisible(true) }, 300)
    }
  }, [ searchQueryLocal ])

  return (
    <main
      ref={mainRef} 
      className={isToggle ? "main_open" : "main"}
      onScroll={() => {
        if ( mainRef.current.scrollTop > 600 ) {
          setScrollSearchVisible(true)
          setSearchVisible(false)
        } else if ( mainRef.current.scrollTop < 500 ) {
          setScrollSearchVisible(false)
        }
      }}
    >
      <RightSideContainer blockTitle={"Фильтры"}>
        <div className="right-side-container">
          <div className="right-side-container-block">
            <span
              style={{
                display: "block",
                width: "100%",
                fontSize: "15px",
                fontWeight: 600
              }}
            >
              Завод-изготовитель
            </span>
            <div style={{ width: "100%" }}>
              <Selector
                placeholder={"Выберите один вариант"}
                options={customersDataForFilter}
                onChange={option => {
                  dispatch(changeFilterCustomers(option.label))
                }}
                value={customers ? customersDataForFilter.find((option) => option.label === customers) : null}
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => "Нет доступных вариантов"}
              />
            </div>
          </div>
          <div className="right-side-container-block">
            <span
              style={{
                display: "block",
                width: "100%",
                fontSize: "15px",
                fontWeight: 600
              }}
            >
              Наименование и тип СИ
            </span>
            <div style={{ width: "100%" }}>
              <Selector
                placeholder={"Начните вводить название"}
                options={titlesDataForFilter}
                onChange={option => {
                  dispatch(changeFilterTitles(option.label))
                }}
                value={titlesDataForFilter.find((option) => option.label === titles) || null}
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => "Нет доступных вариантов"}
              />
            </div>
          </div>
          <div className="right-side-container-block">
            <span
              style={{
                display: "block",
                width: "100%",
                fontSize: "15px",
                fontWeight: 600
              }}
            >
              Методика поверки
            </span>
            <div style={{ width: "100%" }}>
              <Selector
                placeholder={"Выберите одну или несколько"}
                options={methodsDataForFilter}
                onChange={options => {
                  dispatch(
                    changeFilterMethods(
                      options.map(option => option.label)
                    )
                  )
                }}
                isMulti
                value={methods.map(method => ({ value: method, label: method }))}
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => "Нет доступных вариантов"}
              />
            </div>
          </div>
          <div className="right-side-container-block">
            <span
              style={{
                display: "block",
                width: "100%",
                fontSize: "15px",
                fontWeight: 600
              }}
            >
              Ссылка на ФГИС Аршин
            </span>
            <div style={{ width: "100%" }}>
              { !!!false && <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={linkSI || null}
                onChange={event => {
                  false && console.log(event.target.value)

                  dispatch(changeFilterParams({
                    value: 'isLinkSI',
                    label: event.target.value,
                  }))
                }}
                style={{
                  flexDirection: "row",
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: "#123532",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "0px",
                  paddingLeft: "5px",
                }}
              >
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="Нет ссылки"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="Есть ссылка"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
              </RadioGroup> }
            </div>
          </div>
          <div className="right-side-container-block">
            <span
              style={{
                display: "block",
                width: "100%",
                fontSize: "15px",
                fontWeight: 600
              }}
            >
              PDF с описанием типа
            </span>
            <div style={{ width: "100%" }}>
              { !!!false && <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={pdf || null}
                onChange={event => {
                  false && console.log(event.target.value)

                  dispatch(changeFilterParams({
                    value: 'isPDF',
                    label: event.target.value,
                  }))
                }}
                style={{
                  flexDirection: "row",
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: "#123532",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "0px",
                  paddingLeft: "5px",
                }}
              >
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="PDF отсутствует"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="PDF присутствует"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
              </RadioGroup> }
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(changeFilterCustomers(""))
                dispatch(changeFilterTitles(""))
                dispatch(changeFilterMethods([]))
                dispatch(changeFilterParams({
                  value: 'isLinkSI',
                  label: null,
                }))
                dispatch(changeFilterParams({
                  value: 'isPDF',
                  label: null,
                }))
              }}
              style={{
                width: "100%",
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                borderRadius: "12px",
              }}
            >
              Сбросить все значения
            </Button>
          </div>
        </div>
      </RightSideContainer>
      <div className="header_box">
        <div 
          className="flexContainerWithGap"
          style={ width > 1050 ? {
            flexWrap: 'wrap',
            width: '100%',
            gap: '12px',
            position: 'relative',
          } : {
            width: !!!scrollSearchVisible ? "100%" : "100vw",
            gap: '12px',
            marginTop: !!!scrollSearchVisible ? "0px" : "0px",
            marginBottom: "0px",
            flexDirection: "row",
            alignItems: "center",
            position: !!!scrollSearchVisible ? "relative" : "fixed",
            backgroundColor: !!!scrollSearchVisible ? "transparent" : "#f8f9fc",
            paddingTop: !!!scrollSearchVisible ? "0px" : "7.2px",
            paddingBottom: !!!scrollSearchVisible ? "0px" : "10px",
            paddingLeft: !!!scrollSearchVisible ? "0px" : "20px",
            paddingRight: !!!scrollSearchVisible ? "0px" : "30px",
            zIndex: 3,
            left: scrollSearchVisible && 0,
            top: scrollSearchVisible && "68px"
          }}
        >
          { true && <div 
            className="selector" 
            style={{ width: '100%', position: 'relative' }}
          >
            <FilterMetersBase />
            {( width < 1050 && searchVisible ) ? <div
              style={{
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                width: '100%',
                opacity: searchVisible,
                top: 0
              }}
            >
              <SearchQuery
                searchQuery={searchQueryLocal}
                setSearchQuery={changeSearchQuery}
              />
              <CloseIcon 
                onClick={event => {
                  event.stopPropagation()
                  setSearchVisible(false)
                  changeSearchQuery("")
                }}
                style={{
                  display: 'block',
                  position: 'absolute',
                  fontSize: '28px',
                  color: 'gray',
                  left: '100%',
                  marginLeft: '-40px',
                }}
              />
            </div> : null }
          </div> }

          {( width <= 1050 && !!!searchVisible ) ? <React.Fragment>
            <SearchIcon 
              onClick={() => setSearchVisible(true)}
              style={{
                display: 'block',
                fontSize: '26px',
                color: 'rgb(0, 132, 226)',
                marginLeft: "6px"
              }}
            />
            <div style={{ marginTop: '3px' }}>
              <img 
                alt={""}
                src={filterIcon}
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
              />
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21.9px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-13px",
                  marginTop: "-8px",
                  transition: "all 300ms",
                  opacity: count > 0 ? 1 : 0
                }}
              >
                { count }
              </span>
            </div>
          </React.Fragment> : null }

          { width > 1050 && <div 
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '12px'
            }}
          >
            {( userRole === "super" && width > 1050 ) && (
              <div style={{ width: width > 1050 ? "18%" : "100%" }}>
                <Button
                  variant={ width > 1050 ? "contained" : "outlined"}
                  onClick={handleMetersClick}
                  disabled={
                    // ---------------------------------------------------------------- 
                    // ( measurement_id === 1 && process.env.REACT_APP_API_URL === 'https://app-develop.metriva.ru' )
                    // ----------------------------------------------------------------
                    ( measurement_id === 1 ) 
                      ? false 
                      : true 
                  }
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "14px 20px",
                    margin: 0,
                    borderRadius: "12px",
                    width: "100%",
                    height: "54.4px"
                  }}
                >
                  Добавить прибор
                </Button>
              </div>
            )}
            <div style={{ width: width > 1050 ? "82%" : "100%" }}>
              <SearchQuery
                searchQuery={searchQueryLocal}
                setSearchQuery={changeSearchQuery}
              />
            </div>
            <div style={{ width: width > 1050 ? "16%" : "100%", position: "relative" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterAltIcon />}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "0px 20px",
                  borderRadius: "12px",
                  height: "54.4px"
                }}
              >
                Фильтры
              </Button>
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-18px",
                  marginTop: "-4px",
                  transition: "all 300ms",
                  opacity: count > 0 ? 1 : 0
                }}
              >
                { count }
              </span>
            </div>
          </div> }
          {( selectedMeters.length > 0 && width > 1050 ) && <div
            className="flexContainerWithGap"
          >
            <Button
              variant="text"
              startIcon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.75 6.16667C2.75 5.70644 3.09538 5.33335 3.52143 5.33335L6.18567 5.3329C6.71502 5.31841 7.18202 4.95482 7.36214 4.41691C7.36688 4.40277 7.37232 4.38532 7.39185 4.32203L7.50665 3.94993C7.5769 3.72179 7.6381 3.52303 7.72375 3.34536C8.06209 2.64349 8.68808 2.1561 9.41147 2.03132C9.59457 1.99973 9.78848 1.99987 10.0111 2.00002H13.4891C13.7117 1.99987 13.9056 1.99973 14.0887 2.03132C14.8121 2.1561 15.4381 2.64349 15.7764 3.34536C15.8621 3.52303 15.9233 3.72179 15.9935 3.94993L16.1083 4.32203C16.1279 4.38532 16.1333 4.40277 16.138 4.41691C16.3182 4.95482 16.8778 5.31886 17.4071 5.33335H19.9786C20.4046 5.33335 20.75 5.70644 20.75 6.16667C20.75 6.62691 20.4046 7 19.9786 7H3.52143C3.09538 7 2.75 6.62691 2.75 6.16667Z" fill="#4B5962"/>
                  <path d="M11.6068 21.9998H12.3937C15.1012 21.9998 16.4549 21.9998 17.3351 21.1366C18.2153 20.2734 18.3054 18.8575 18.4855 16.0256L18.745 11.945C18.8427 10.4085 18.8916 9.6402 18.45 9.15335C18.0084 8.6665 17.2628 8.6665 15.7714 8.6665H8.22905C6.73771 8.6665 5.99204 8.6665 5.55047 9.15335C5.10891 9.6402 5.15777 10.4085 5.25549 11.945L5.515 16.0256C5.6951 18.8575 5.78515 20.2734 6.66534 21.1366C7.54553 21.9998 8.89927 21.9998 11.6068 21.9998Z" fill="#4B5962"/>
                </svg>
              }
              onClick={deleteMeters}
              style={{
                width: width > 1050 ? "" : "100%",
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                borderRadius: "12px",
                color: "#123532",
              }}
            >
              Полностью удалить
            </Button>
          </div> }
        </div>
      </div>

      { measurement_id === 1 && <TableWater onDelete={() => {}} />}
      { measurement_id === 5 && <TableGas onDelete={() => {}} />}
    </main>
  )
}

export default Meters
