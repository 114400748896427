import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const Selector = ({
  placeholder,
  isClearable = true,
  isSearchable,
  isMulti = false,
  onChange,
  value,
  options,
  noOptionsMessage,
  defaultValue = null,
  isDisabled = false,
  onInputChange,
  label,
}) => {
  const animatedComponents = makeAnimated();

  const selectStyles = {
    control: (theme) => ({
      ...theme,
      border: "none",
      outline: "none",
      boxShadow: "0px 0px 1.5px grey",
      minHeight: "55px",
      // ----------------------------------------------------------------
      // height: '50px',
      // ----------------------------------------------------------------
      fontSize: "14px",
      paddingBottom: "1px",
      paddingTop: "1px",
      borderRadius: "4px",
    }),
    placeholder: (theme) => ({
      ...theme,
      paddingLeft: "4px",
      paddingBottom: "2px",
    }),
    singleValue: (theme) => ({
      ...theme,
      paddingLeft: "4px",
    }),
    input: (theme) => ({
      ...theme,
      paddingLeft: "4px",
    }),
    menu: (theme) => ({
      ...theme,
      overflow: "hidden",
      paddingTop: 6,
      paddingBottom: 6,
      width: "90%",
      marginTop: 10,
      border: "none",
      zIndex: 20,
    }),
    menuList: (theme) => ({
      ...theme,
      display: "block",
      position: "relative",
      width: "calc(100% + 23px)",
    }),
    option: (theme, { isSelected }) => ({
      ...theme,
      fontSize: "13px",
      padding: "12px 0",
      paddingLeft: "13px",
      paddingBottom: "12.8px",
      lineHeight: "20px",
      cursor: "pointer",
      color: isSelected ? "white" : "#2E2E2E",
    }),
    multiValueLabel: (theme) => ({
      ...theme,
      color: "#123532",
      height: "30px",
      lineHeight: "24px",
      fontSize: "13px",
      paddingLeft: "10px",
      paddingRight: "10px",
    }),
    multiValueRemove: (theme) => ({
      ...theme,
      marginRight: "8px",
      marginLeft: "2px",
      marginTop: "1px",
      color: "#123532",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotateX(180deg)" : null,
      transition: "transform 0.3s ease",
    }),
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        {label && (
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              position: "absolute",
              zIndex: 2,
              marginTop: "-12px",
              marginLeft: "8px",
            }}
          >
            <span
              style={{
                display: "block",
                position: "relative",
                width: "100%",
                height: "12px",
                backgroundColor: label.topBg,
              }}
            />
            <span
              style={{
                display: "block",
                position: "relative",
                width: "100%",
                height: "12px",
                backgroundColor: label.bottomBg,
              }}
            />
            <span
              style={{
                display: "block",
                position: "relative",
                fontSize: "13px",
                marginTop: "-21px",
                padding: "0px 10px",
                letterSpacing: "0.6px",
              }}
            >
              {label.text}
            </span>
          </div>
        )}
        <Select
          classNamePrefix="custom-select"
          components={animatedComponents}
          defaultValue={defaultValue ?? null}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          value={value}
          noOptionsMessage={noOptionsMessage}
          isDisabled={isDisabled}
          styles={selectStyles}
          onInputChange={onInputChange}
        />
      </div>
    </React.Fragment>
  );
};

export default Selector;
