// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { Button, Tooltip, Collapse } from "@mui/material"
import FilterAltIcon from "@mui/icons-material/FilterAlt"

import Csv from "../../../services/app/protocol-csv-excel-download.service"

import RightSideContainer from "../../components/LocalElements/RightSideContainer/RightSideContainer"
import FilterMeasurement from "../../components/LocalElements/Filter/FilterMeasurement"
import FilterUsers from "../../components/LocalElements/Filter/FilterUsers"
import Approved from "../../components/LocalElements/Filter/Approved"
import DatePicker from "../../components/LocalElements/DatePicker/DatePicker"

import FilterCompany from "../../components/LocalElements/Filter/FilterCompany"
import BasicTabs from "../../components/LocalElements/TabsMenu/TabsMenu"

import SearchQuery from "../../components/LocalElements/Search/SearchQuery"
import Table from "../../components/LocalElements/Table/Table"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import { setState } from "../../components/Methodologies/UniversalShow/state"
import R from "../../../services/app/request.service"

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBD,
  resetState,
} from "../../../store/slices/app/comps/modal"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"
import {
  setMeasurementIdFilter,
  setUserId,
  setStatusId,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setDateFrom,
  setDateTo,
  setSelectedProtocols,
  setViewVariantMain,
  setSearchQuery as setSearchQueryProtocol,
} from "../../../store/slices/app/views/protocols"
import { setName } from "../../../store/slices/app/controlers/pageName"

import { refreshProtocols } from "../../../store/slices/app/controlers/updater"
import { setOpen as setOpenRightContainer } from "../../../store/slices/app/comps/rightContainer"

const AllProtocols = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const userRole = useSelector((state) => state.headerReducer.role)
  const protocolsData = useSelector(
    (state) => state.protocolReducer.protocolsList
  )
  const isTrusted = useSelector((state) => state.headerReducer.isTrusted)
  const viewVariant = useSelector(
    (state) => state.protocolReducer.viewVariantMain
  )
  const selectedProtocols = useSelector(
    (state) => state.protocolReducer.selectedProtocols
  )
  const protocolSearchQuery = useSelector(
    (state) => state.protocolReducer.searchQuery
  )
  const measurementIdFilter = useSelector(
    (state) => state.protocolReducer.measurementIdFilter
  )
  const userId = useSelector((state) => state.protocolReducer.userId)
  const statusProtocol = useSelector((state) => state.protocolReducer.statusId)
  const approvedTransfer = useSelector(
    (state) => state.protocolReducer.approvedTransfer
  )
  const approvedArshin = useSelector(
    (state) => state.protocolReducer.approvedArshin
  )
  const approvedFsa = useSelector((state) => state.protocolReducer.approvedFsa)
  const dateFrom = useSelector((state) => state.protocolReducer.dateFrom)
  const dateTo = useSelector((state) => state.protocolReducer.dateTo)

  const resetActions = [
    setMeasurementIdFilter,
    setUserId,
    setStatusId,
    setApprovedTransfer,
    setApprovedArshin,
    setApprovedFsa,
    setDateFrom,
    setDateTo,
  ]

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (statusProtocol) filtersCount++
    if (approvedTransfer) filtersCount++
    if (approvedArshin) filtersCount++
    if (approvedFsa) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (
      !measurementIdFilter &&
      !userId &&
      !statusProtocol &&
      !approvedTransfer &&
      !approvedArshin &&
      !approvedFsa &&
      !dateFrom &&
      !dateTo
    )
      filtersCount = 0
  }, [
    measurementIdFilter,
    userId,
    statusProtocol,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  const countArchive = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (!measurementIdFilter && !userId && !dateFrom && !dateTo)
      filtersCount = 0

    return filtersCount
  }, [measurementIdFilter, userId, dateFrom, dateTo])

  const handleVerifiedAtChange = (date) => {
    if (date) {
      dispatch(setDateFrom(date.format("YYYY-MM-DD")))
    } else {
      dispatch(setDateFrom(null))
    }
  }

  const handleExpiredAtChange = (date) => {
    if (date) {
      dispatch(setDateTo(date.format("YYYY-MM-DD")))
    } else {
      dispatch(setDateTo(null))
    }
  }

  const changeMeasurementId = (param) => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeUserId = (param) => {
    dispatch(setUserId(param))
  }
  const changeApprovedStatus = (event) => {
    dispatch(setStatusId(event.target.value))
  }

  const changeApprovedTransfer = (event) => {
    dispatch(setApprovedTransfer(event.target.value))
  }

  const changeApprovedArshin = (event) => {
    dispatch(setApprovedArshin(event.target.value))
  }
  const changeApprovedFsa = (event) => {
    dispatch(setApprovedFsa(event.target.value))
  }

  useEffect(() => {
    if (approvedTransfer === "no") {
      dispatch(setApprovedArshin(""))
    }

    if (approvedTransfer === "no" || approvedArshin === "not-published") {
      dispatch(setApprovedFsa(""))
    }
  }, [approvedTransfer, approvedArshin, dispatch])

  const handleResetFilter = () => {
    resetActions.forEach((action) => dispatch(action("")))
  }

  const changeViewVariant = (param) => {
    handleResetFilter()
    dispatch(setViewVariantMain(param))
  }

  const filteredProtocolsData = protocolsData.filter((item) => {
    if (viewVariant === 0) {
      return !item.archived_at
    } else if (viewVariant === 1) {
      return item.archived_at
    }
    return true
  })

  const noRowsText =
    viewVariant === 0
      ? "Протоколы не созданы или перемещены в архив"
      : "Архивные протоколы не найдены"

  const changeSearchQuery = (param) => {
    dispatch(setSearchQueryProtocol(param))
  }

  const handleShowClick = (param) => {
    width > 1050 && navigate(`/metriva/protocols/${param.row.id}`)
  }
  const handleEditClick = (protocolId) => {
    navigate(`/metriva/protocols/${protocolId}/edit`)
  }
  const handleCopyClick = (protocolId) => {
    navigate(`/metriva/protocols/${protocolId}/copy`)
  }

  const handleDelete = async (protocolId) => {
    const { status } = await R.deleteProtocol(protocolId)
    if (status === 200) {
      dispatch(refreshProtocols())

      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Протокол удален"))
      dispatch(setOpen(false))
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к API."))
    }
  }

  const handleCheckProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status, data: protocolCheckData } = await R.protocolCheck(data)

    if (status === 200) {
      dispatch(
        setMessage("Протоколы успешно одобрены для передачи в Аршин и ФСА")
      )
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Статус 500. Ошибка обращения к api."))
    }
  }

  const handleClickOnArchiveBtn = () => {
    const filteredBySelected = filteredProtocolsData.filter((item) =>
      selectedProtocols.includes(item.id)
    )

    const allThreeConditionsMet = filteredBySelected.every(
      (item) =>
        item.status === "sent" &&
        item.status_arshin === "published" &&
        item.status_fsa === "published"
    )

    const someConditionsNotMet = filteredBySelected.some(
      (item) =>
        item.status !== "sent" ||
        item.status_arshin !== "published" ||
        item.status_fsa !== "published"
    )

    const validProtocols = filteredBySelected.filter(
      (item) =>
        item.status === "sent" &&
        item.status_arshin === "published" &&
        item.status_fsa === "published"
    )

    const updatedProtocols = validProtocols.map((item) => item.id)

    let title
    let message
    let confirmAction

    if (allThreeConditionsMet) {
      title = "Архивация протокола"
      message =
        "Вы действительно хотите перенести выбранный(е) протокол(ы) в архив?\nАрхивная версия будет доступна только в режиме просмотра."
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else if (someConditionsNotMet && validProtocols.length > 0) {
      title = "Архивация приостановлена"
      message =
        "Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА.\nВы можете продолжить, исключив данные протоколы из списка архивируемых, либо возобновить процесс позднее."
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else {
      title = "Архивация приостановлена"
      message =
        "Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА."
      confirmAction = () => dispatch(setOpen(false))
    }

    dispatch(setOpen(true))
    dispatch(setTitle(title))
    dispatch(setChildren(message))
    dispatch(setBA(validProtocols.length > 0 ? "Архивировать" : "Закрыть"))
    dispatch(setConfirmAction(confirmAction))
    if (validProtocols.length > 0) {
      dispatch(showBD(true))
    } else {
      dispatch(showBD(false))
    }
  }

  const handleArchiveProtocol = async (updatedProtocols) => {
    const data = {
      ids: updatedProtocols,
    }

    const { status, data: protocolArchiveData } = await R.protocolArchive(data)

    if (status === 200) {
      dispatch(setMessage("Протоколы успешно архивированы."))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Статус 500. Ошибка обращения к api."))
    }
  }

  const handleUnArchiveProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status, data: protocolUnArchiveData } = await R.protocolUnArchive(
      data
    )

    if (status === 200) {
      dispatch(setMessage("Протоколы успешно восстановлены из архива."))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Статус 500. Ошибка обращения к api."))
    }
  }

  const handlePdf = async () => {
    const data = {
      ids: selectedProtocols,
    }

    try {
      const { status, data: fileData } = await R.protocolPdf(data)

      if (status === 200) {
        let blob
        let fileName

        if (selectedProtocols.length === 1) {
          blob = new Blob([fileData], { type: "application/pdf" })
          fileName = `protocol_${selectedProtocols[0]}.pdf`
        } else {
          blob = new Blob([fileData], { type: "application/zip" })
          fileName = `protocols_${selectedProtocols.join("-")}.zip`
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement("a")
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage("Протоколы успешно скачаны."))
        dispatch(setType("success"))
        dispatch(setOpenAlert(true))
        dispatch(refreshProtocols())

        dispatch(resetState())
      } else {
        dispatch(setType("error"))
        dispatch(setOpenAlert(true))
        dispatch(setMessage("Ошибка при скачивании архива."))
      }
    } catch (error) {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка при обращении к API."))
    }
  }

  useEffect(() => {
    dispatch(setState({}))
  }, [])
  useEffect(() => {
    dispatch(setName("Созданные протоколы"))
  }, [])

  useEffect(() => {
    false && false && console.log(protocolsData)
  }, [protocolsData])

  return (
    <main className="main">
      {userRole === "super" && (
        <div
          style={{
            width: width > 1050 ? "605px" : "100%",
          }}
        >
          <FilterCompany />
        </div>
      )}

      {(userRole === "super" || userRole === "admin") && (
        <div style={{ margin: "10px 0 12px" }}>
          <BasicTabs
            viewVariant={viewVariant}
            callback={changeViewVariant}
            tabs={[{ label: "Актуальные" }, { label: "Архивные" }]}
          />
        </div>
      )}

      {viewVariant === 0 ? (
        <React.Fragment>
          <RightSideContainer blockTitle={"Фильтры"}>
            <div className="right-side-container" style={{ gap: 0 }}>
              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Область измерения</span>
                <div style={{ width: "100%" }}>
                  <FilterMeasurement
                    measurementId={measurementIdFilter}
                    setMeasurementId={changeMeasurementId}
                  />
                </div>
              </div>

              {userRole !== "metrologist" && (
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Поверители</span>
                  <div style={{ width: "100%" }}>
                    <FilterUsers userId={userId} setUserId={changeUserId} />
                  </div>
                </div>
              )}

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Заключение поверки</span>
                <Approved
                  approved={statusProtocol}
                  changeApproved={changeApprovedStatus}
                  labelTrue={"Пригоден"}
                  labelFalse={"Непригоден"}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Одобрен к передаче</span>
                <Approved
                  approved={approvedTransfer}
                  changeApproved={changeApprovedTransfer}
                  labelTrue={"Да"}
                  labelFalse={"Нет"}
                  valueTrue={"yes"}
                  valueFalse={"no"}
                />
              </div>

              <Collapse in={approvedTransfer === "yes"}>
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Передан в Аршин</span>
                  <Approved
                    approved={approvedArshin}
                    changeApproved={changeApprovedArshin}
                    labelTrue={"Да"}
                    labelFalse={"Нет"}
                    valueTrue={"published"}
                    valueFalse={"not-published"}
                  />
                </div>
              </Collapse>
              <Collapse
                in={
                  approvedTransfer === "yes" && approvedArshin === "published"
                }
              >
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Передан в ФСА</span>
                  <Approved
                    approved={approvedFsa}
                    changeApproved={changeApprovedFsa}
                    labelTrue={"Да"}
                    labelFalse={"Нет"}
                    valueTrue={"published"}
                    valueFalse={"not-published"}
                  />
                </div>
              </Collapse>

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Дата поверки</span>
                <DatePicker
                  selectedDate={dateFrom}
                  dateChange={handleVerifiedAtChange}
                />

                <DatePicker
                  selectedDate={dateTo}
                  dateChange={handleExpiredAtChange}
                />
              </div>
              <Button
                variant="outlined"
                onClick={handleResetFilter}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                }}
              >
                Сбросить все значения
              </Button>
            </div>
          </RightSideContainer>
          <div
            className="header_box"
            style={width > 1050 ? { position: "relative" } : {}}
          >
            <div style={{ width: width > 1050 ? "82%" : "100%" }}>
              <SearchQuery
                searchQuery={protocolSearchQuery}
                setSearchQuery={changeSearchQuery}
              />
            </div>

            {width > 1050 && (
              <React.Fragment>
                {userRole !== "metrologist" && <Csv />}
              </React.Fragment>
            )}

            <div
              style={{
                position: "relative",
                width: width > 1050 ? "220px" : "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterAltIcon />}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  width: "100%",
                  height: "54.4px",
                }}
              >
                Фильтры
              </Button>
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-18px",
                  marginTop: "-4px",
                  transition: "all 300ms",
                  opacity: count > 0 ? 1 : 0,
                }}
              >
                {count}
              </span>
            </div>
          </div>

          <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
            <div
              className="flexContainerWithGap"
              style={
                width > 1050
                  ? { marginTop: "11px", gap: "0px" }
                  : { marginTop: "12px", gap: "0px", marginBottom: "0px" }
              }
            >
              <Button
                variant="text"
                startIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                      fill="#1C274C"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 17.5303C12.3897 17.671 12.1989 17.75 12 17.75C11.8011 17.75 11.6103 17.671 11.4697 17.5303L8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697C9.26256 13.6768 9.73744 13.6768 10.0303 13.9697L11.25 15.1893V11C11.25 10.5858 11.5858 10.25 12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V15.1893L13.9697 13.9697C14.2626 13.6768 14.7374 13.6768 15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303L12.5303 17.5303Z"
                      fill="#4B5962"
                    />
                  </svg>
                }
                onClick={handlePdf}
                style={{
                  width: width > 1050 ? "" : "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  color: "#123532",
                }}
              >
                Скачать PDF
              </Button>
              {userRole !== "metrologist" && (
                <Button
                  variant="text"
                  startIcon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                        fill="#1C274C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 10.4697C12.3897 10.329 12.1989 10.25 12 10.25C11.8011 10.25 11.6103 10.329 11.4697 10.4697L8.96967 12.9697C8.67678 13.2626 8.67678 13.7374 8.96967 14.0303C9.26256 14.3232 9.73744 14.3232 10.0303 14.0303L11.25 12.8107V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.8107L13.9697 14.0303C14.2626 14.3232 14.7374 14.3232 15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697L12.5303 10.4697Z"
                        fill="#4B5962"
                      />
                    </svg>
                  }
                  onClick={handleCheckProtocol}
                  style={{
                    width: width > 1050 ? "" : "100%",
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 20px 11px",
                    borderRadius: "12px",
                    color: "#123532",
                  }}
                >
                  Передать в Аршин и ФСА
                </Button>
              )}
              {(userRole === "super" || userRole === "admin") && (
                <Button
                  variant="text"
                  startIcon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C21.8063 19.2647 21.9744 17.3219 21.9966 13.75H18.8397C17.8659 13.75 17.6113 13.766 17.3975 13.8644C17.1838 13.9627 17.0059 14.1456 16.3722 14.8849L15.6794 15.6933C15.1773 16.2803 14.7796 16.7453 14.2292 16.9984C13.6789 17.2515 13.067 17.2509 12.2945 17.2501H11.7055C10.933 17.2509 10.3211 17.2515 9.77076 16.9984C9.22038 16.7453 8.82271 16.2803 8.32058 15.6933L7.62784 14.8849C6.9941 14.1456 6.81622 13.9627 6.60245 13.8644C6.38869 13.766 6.13407 13.75 5.16026 13.75H2.00339C2.02561 17.3219 2.19367 19.2647 3.46447 20.5355Z"
                        fill="#4B5962"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8C7.58579 7.75 7.25 7.41421 7.25 7Z"
                        fill="#1C274C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12L22 12.25L18.7055 12.2499C17.933 12.2491 17.3211 12.2485 16.7708 12.5016C16.2204 12.7547 15.8227 13.2197 15.3206 13.8067L14.6278 14.6151C13.9941 15.3544 13.8162 15.5373 13.6025 15.6356C13.3887 15.734 13.1341 15.75 12.1603 15.75H11.8397C10.8659 15.75 10.6113 15.734 10.3975 15.6356C10.1838 15.5373 10.0059 15.3544 9.37216 14.6151L8.67941 13.8067C8.17729 13.2197 7.77961 12.7547 7.22924 12.5016C6.67886 12.2485 6.06705 12.2491 5.29454 12.2499L2.00001 12.25L2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2ZM9.25 10.5C9.25 10.0858 9.58579 9.75 10 9.75H14C14.4142 9.75 14.75 10.0858 14.75 10.5C14.75 10.9142 14.4142 11.25 14 11.25H10C9.58579 11.25 9.25 10.9142 9.25 10.5Z"
                        fill="#4B5962"
                      />
                    </svg>
                  }
                  onClick={handleClickOnArchiveBtn}
                  style={{
                    width: width > 1050 ? "" : "100%",
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 20px 11px",
                    borderRadius: "12px",
                    color: "#123532",
                  }}
                >
                  Архивировать
                </Button>
              )}
            </div>
          </Collapse>

          <Table
            css={{
              marginTop: "12px",
              backgroundColor: "#ffffff",
              border: "1px solid #d7ddea",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "1.2",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-cell:hover": {
                cursor: "default",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordWrap: "break-word",

                height: "auto",
                lineHeight: "1.5",
                padding: "8px",
                boxSizing: "border-box",
              },
            }}
            colData={[
              {
                field: "numId",
                headerName: width > 1050 ? "Номер" : "№",
                width: 100,
              },
              {
                field: "verificationDate",
                headerName: "Дата поверки",
                width: 200,
              },
              {
                field: "totalAccepted",
                headerName: "Заключение",
                width: 200,
              },
              userRole === "metrologist"
                ? {
                    field: "measurement",
                    headerName: "Область измерения",
                    width: 300,
                  }
                : {
                    field: "createdBy",
                    headerName: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Поверитель
                        <Tooltip
                          title={
                            "Фактический поверитель - пользователь который создал протокол в системе"
                          }
                          placement="top"
                          style={{ marginLeft: "7px" }}
                        >
                          <i
                            className="fas fa-question-circle question"
                            style={{ marginLeft: "7px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                    ),
                    width: 300,
                  },
              {
                field: "statusArshin",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Передача в Аршин
                    <Tooltip
                      title={
                        isTrusted
                          ? "Для начала передачи: выберите необходимый(е) протокол(ы) в общем списке и нажмите кнопку “Передать в Аршин и ФСА” или предоставьте разрешение в процессе создания/редактирования протокола"
                          : "Дождитесь когда передача протокола(ов) во ФГИС «Аршин» будет одобрена руководителем или администратором"
                      }
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 240,
                renderCell: (params) => {
                  const { status, statusArshin, statusFsa } = params.row

                  if (status !== "sent" && statusArshin === "not-sent") {
                    return <p style={{ color: "#E74953" }}>Не начата</p>
                  } else if (status === "sent" && statusArshin === "not-sent") {
                    return <p style={{ color: "#E57004" }}>В процессе</p>
                  } else if (statusArshin === "published") {
                    return <p style={{ color: "#00A37C" }}>Завершена</p>
                  }
                },
              },
              {
                field: "statusFsa",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Передача в ФСА
                    <Tooltip
                      title="Передача начнётся автоматически после получения данных о номере свидетельства/извещения из ФГИС «Аршин»"
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 200,
                renderCell: (params) => {
                  const { statusArshin, statusFsa } = params.row

                  if (statusArshin === "not-sent" && statusFsa === "not-sent") {
                    return <p style={{ color: "#E74953" }}>Не начата</p>
                  } else if (
                    statusArshin === "published" &&
                    statusFsa === "not-sent"
                  ) {
                    return <p style={{ color: "#E57004" }}>В процессе</p>
                  } else if (statusFsa === "published") {
                    return <p style={{ color: "#00A37C" }}>Завершена</p>
                  }
                },
              },
              {
                field: "arshinId",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    № свидетельства или извещения
                    <Tooltip
                      title={"Приходит из ФГИС «Аршин»"}
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 200,
              },
              {
                field: "actions",
                type: "actions",
                headerName: "Действия",
                width: 200,
                getActions: (item) => {
                  const localItem = item

                  return [
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "space-between",
                        gap: "30px",
                      }}
                    >
                      {localItem.row.originalCase !==
                      "Эталоны комплекта были изменены" ? (
                        <Tooltip
                          title="Копировать протокол"
                          placement="top-end"
                        >
                          <span
                            onClick={() => handleCopyClick(localItem.id)}
                            className="action-container-block"
                          >
                            <span
                              className="fas fa-copy icon"
                              style={{
                                fontSize: "15px",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Копирование протокола недоступно, используемый комплект СП был удалён или изменён"
                          placement="top-start"
                        >
                          <span className="action-container-block-disabled">
                            <span
                              className="fas fa-copy icon"
                              style={{
                                fontSize: "15px",
                                color: "#ccc",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {localItem.row.status !== "sent" ? (
                        <Tooltip
                          title="Редактировать протокол"
                          placement="top-end"
                        >
                          <span
                            onClick={() => handleEditClick(localItem.id)}
                            className="action-container-block"
                          >
                            <span
                              className="fas fa-pencil-alt icon"
                              style={{
                                fontSize: "15px",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Редактирование недоступно, протокол был передан во ФГИС «Аршин»"
                          placement="top-end"
                        >
                          <span className="action-container-block-disabled">
                            <span
                              className="fas fa-pencil-alt icon"
                              style={{
                                fontSize: "15px",
                                color: "#ccc",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {userRole === "super" || userRole === "admin" ? (
                        localItem.row.status !== "sent" ? (
                          <Tooltip title="Удалить протокол" placement="top-end">
                            <span
                              onClick={() => {
                                dispatch(setOpen(true))
                                dispatch(
                                  setTitle(
                                    "Вы действительно хотите удалить протокол?"
                                  )
                                )
                                dispatch(
                                  setChildren(
                                    "Отменить действие будет уже невозможно."
                                  )
                                )
                                dispatch(setBA("Удалить"))
                                dispatch(
                                  setConfirmAction(() =>
                                    handleDelete(localItem.id)
                                  )
                                )
                              }}
                              style={{
                                width: "5%",
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "space-around",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="fas fa-trash icon"
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Удаление недоступно, протокол был передан во ФГИС «Аршин»"
                            placement="top-end"
                          >
                            <span className="action-container-block-disabled">
                              <span
                                className="fas fa-trash icon"
                                style={{
                                  fontSize: "15px",
                                  color: "#ccc",
                                }}
                              />
                            </span>
                          </Tooltip>
                        )
                      ) : null}
                    </div>,
                  ]
                },
              },
            ]}
            rowData={filteredProtocolsData.map((item) => ({
              id: item.id,
              numId: item.id,
              verificationDate: moment(item.verification_date.full).format(
                "DD-MM-YYYY"
              ),
              measurement: item.measurement?.name,
              createdBy: `${item.createdBy?.surname || ""} ${
                item.createdBy?.name || ""
              }`,
              totalAccepted: item.total_accepted ? "Пригоден" : "Непригоден",
              status: item.status,
              arshinId: item.arshin_id ? item.arshin_id : "номера нет",
              statusArshin: item.status_arshin,
              statusFsa: item.status_fsa,
              originalCase: item.originalCase
                ? item.originalCase
                : "Эталоны комплекта были изменены",
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={(array) => {
              dispatch(setSelectedProtocols(array))
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RightSideContainer blockTitle={"Фильтры"}>
            <div className="right-side-container">
              <div className="right-side-container-block">
                <span className="label-filter">Область измерения</span>
                <div style={{ width: "100%" }}>
                  <FilterMeasurement
                    measurementId={measurementIdFilter}
                    setMeasurementId={changeMeasurementId}
                  />
                </div>
              </div>

              {userRole !== "metrologist" && (
                <div className="right-side-container-block">
                  <span className="label-filter">Поверители</span>
                  <div style={{ width: "100%" }}>
                    <FilterUsers userId={userId} setUserId={changeUserId} />
                  </div>
                </div>
              )}

              <div className="right-side-container-block">
                <span className="label-filter">Заключение поверки</span>
                <Approved
                  approved={statusProtocol}
                  changeApproved={changeApprovedStatus}
                  labelTrue={"Пригоден"}
                  labelFalse={"Непригоден"}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>

              <div className="right-side-container-block">
                <span className="label-filter">Дата поверки</span>
                <DatePicker
                  selectedDate={dateFrom}
                  dateChange={handleVerifiedAtChange}
                />

                <DatePicker
                  selectedDate={dateTo}
                  dateChange={handleExpiredAtChange}
                />
              </div>
              <Button
                variant="outlined"
                onClick={handleResetFilter}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                Сбросить все значения
              </Button>
            </div>
          </RightSideContainer>

          <div className="header_box" style={{ position: "relative" }}>
            <div style={{ width: width > 1050 ? "82%" : "100%" }}>
              <SearchQuery
                searchQuery={protocolSearchQuery}
                setSearchQuery={changeSearchQuery}
              />
            </div>

            {width > 1050 && (
              <React.Fragment>
                {userRole !== "metrologist" && <Csv />}
              </React.Fragment>
            )}

            <div
              style={{
                position: "relative",
                width: width > 1050 ? "220px" : "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterAltIcon />}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                Фильтры
              </Button>
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-18px",
                  marginTop: "-4px",
                  transition: "all 300ms",
                  opacity: countArchive > 0 ? 1 : 0,
                }}
              >
                {countArchive}
              </span>
            </div>
          </div>

          <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
            <div
              className="flexContainerWithGap"
              style={{ marginTop: "11px", gap: "0px" }}
            >
              <Button
                variant="text"
                startIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                      fill="#1C274C"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 17.5303C12.3897 17.671 12.1989 17.75 12 17.75C11.8011 17.75 11.6103 17.671 11.4697 17.5303L8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697C9.26256 13.6768 9.73744 13.6768 10.0303 13.9697L11.25 15.1893V11C11.25 10.5858 11.5858 10.25 12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V15.1893L13.9697 13.9697C14.2626 13.6768 14.7374 13.6768 15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303L12.5303 17.5303Z"
                      fill="#4B5962"
                    />
                  </svg>
                }
                onClick={handlePdf}
                style={{
                  width: width > 1050 ? "" : "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  color: "#123532",
                }}
              >
                Скачать PDF
              </Button>

              <React.Fragment>
                {(userRole === "super" || userRole === "admin") && (
                  <Button
                    variant="text"
                    startIcon={
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.7141 22 12C22 7.28598 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM9.25871 7.97395C9.56308 7.693 9.58205 7.21851 9.3011 6.91414C9.02015 6.60978 8.54565 6.5908 8.24129 6.87175L5.99129 8.94867C5.83748 9.09065 5.75 9.29045 5.75 9.49977C5.75 9.7091 5.83748 9.9089 5.99129 10.0509L8.24129 12.1278C8.54565 12.4088 9.02015 12.3898 9.3011 12.0854C9.58205 11.781 9.56308 11.3065 9.25871 11.0256L8.41824 10.2498H14.0385C15.536 10.2498 16.75 11.4638 16.75 12.9613C16.75 14.4589 15.536 15.6729 14.0385 15.6729H9.5C9.08579 15.6729 8.75 16.0086 8.75 16.4229C8.75 16.8371 9.08579 17.1729 9.5 17.1729H14.0385C16.3644 17.1729 18.25 15.2873 18.25 12.9613C18.25 10.6353 16.3644 8.74977 14.0385 8.74977H8.41824L9.25871 7.97395Z"
                          fill="#4B5962"
                        />
                      </svg>
                    }
                    onClick={() => {
                      dispatch(setOpen(true))

                      dispatch(
                        setTitle(
                          "Вы действительно хотите востановить выбранные протоколы?"
                        )
                      )
                      dispatch(
                        setChildren(
                          "После восстановления, выбранные протоколы будут перенесены во вкладку 'Актуальные'."
                        )
                      )
                      dispatch(setBA("Восстановить"))
                      dispatch(
                        setConfirmAction(() => handleUnArchiveProtocol())
                      )
                    }}
                    style={{
                      width: width > 1050 ? "" : "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: "11px 20px 11px",
                      borderRadius: "12px",
                      color: "#123532",
                    }}
                  >
                    Восстановить
                  </Button>
                )}
              </React.Fragment>
            </div>
          </Collapse>

          <Table
            css={{
              marginTop: "12px",
              backgroundColor: "#ffffff",
              border: "1px solid #d7ddea",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "1.2",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-cell:hover": {
                cursor: "default",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordWrap: "break-word",

                height: "auto",
                lineHeight: "1.5",
                padding: "8px",
                boxSizing: "border-box",
              },
            }}
            colData={[
              {
                field: "numId",
                headerName: width > 1050 ? "Номер" : "№",
                width: 100,
              },
              {
                field: "verificationDate",
                headerName: "Дата поверки",
                width: 200,
              },
              {
                field: "totalAccepted",
                headerName: "Заключение",
                width: 300,
              },
              userRole === "metrologist"
                ? {
                    field: "measurement",
                    headerName: "Область измерения",
                    width: 300,
                  }
                : {
                    field: "createdBy",
                    headerName: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Поверитель
                        <Tooltip
                          title={
                            "Фактический поверитель - пользователь который создал протокол в системе"
                          }
                          placement="top"
                          style={{ marginLeft: "7px" }}
                        >
                          <i
                            className="fas fa-question-circle question"
                            style={{ marginLeft: "7px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                    ),
                    width: 300,
                  },

              {
                field: "arshinId",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    № свидетельства или извещения
                    <Tooltip
                      title={"Приходит из ФГИС «Аршин»"}
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 300,
              },
            ]}
            rowData={filteredProtocolsData.map((item) => ({
              id: item.id,
              numId: item.id,
              verificationDate: moment(item.verification_date.full).format(
                "DD-MM-YYYY"
              ),
              measurement: item.measurement?.name,
              createdBy: `${item.createdBy?.surname || ""} ${
                item.createdBy?.name || ""
              }`,
              totalAccepted: item.total_accepted ? "Пригоден" : "Непригоден",
              arshinId: item.arshin_id ? item.arshin_id : "номера нет",
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={(array) => {
              dispatch(setSelectedProtocols(array))
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={width > 1050 ? true : false}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </main>
  )
}

export default AllProtocols
