import axios from './axios-config.service'
import Cookies from 'js-cookie'
class Request {
  #HEADERS_JSON = {
    Authorization: `Bearer ${Cookies.get('accessToken')}`,
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  }

  // ----------------------------------------------------------------
  // #1 авторизация и регистрация
  // ----------------------------------------------------------------

  #authUrl = `${process.env.REACT_APP_API_URL}/api/auth/login`

  // ----------------------------------------------------------------
  // #2 dadata - получение списка адресов
  // ----------------------------------------------------------------

  #addressUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`

  // ----------------------------------------------------------------
  // #3 информация о тарифах
  // ----------------------------------------------------------------

  #tariffsUrl = `${process.env.REACT_APP_API_URL}/api/tariffs`
  #subscriptionUrl = `${process.env.REACT_APP_API_URL}/api/subscriptions`
  #tariffsUrlExtra = `${process.env.REACT_APP_API_URL}/api/tariffs/services_rate`

  // ----------------------------------------------------------------
  // #4 подключение lanbilling
  // ----------------------------------------------------------------

  #billingUrlPostPayment = `${process.env.REACT_APP_API_URL}/api/billing`
  #billingUrlPrePayment = `${process.env.REACT_APP_API_URL}/api/billing/prepay`

  // ----------------------------------------------------------------
  // #5 компании
  // ----------------------------------------------------------------

  #companiesUrl = `${process.env.REACT_APP_API_URL}/api/companies`
  #companiesStatusUrl = companyId => `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/activate`
  #companiesUrlList = `${process.env.REACT_APP_API_URL}/api/companies/list`
  #companiesUrlItem = companyId => `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/edit`
  #companiesUrlEdit = companyId => `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/update`

  // ----------------------------------------------------------------
  // #6 комплекты сп
  // ----------------------------------------------------------------

  #casesUrl = `${process.env.REACT_APP_API_URL}/api/cases`
  #casesUrlItem = `${process.env.REACT_APP_API_URL}/api/cases/list`
  #casesUrlEdit = caseId => `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`
  #casesUrlPut = caseId => `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`
  #mainCaseUrl = caseId => `${process.env.REACT_APP_API_URL}/api/cases/set-main/${caseId}`

  // ----------------------------------------------------------------
  // #7 мастера аршина
  // ----------------------------------------------------------------

  #masterArshinUrl = `${process.env.REACT_APP_API_URL}/api/arshin-masters`
  #masterUrlPut = masterId => `${process.env.REACT_APP_API_URL}/api/arshin-masters/${masterId}`
  #masterUrlItem = masterId => `${process.env.REACT_APP_API_URL}/api/arshin-masters/${masterId}/edit`
  #masterUrl = `${process.env.REACT_APP_API_URL}/api/arshin-masters/list`

  // ----------------------------------------------------------------
  // #8 неклассифицированная группа
  // ----------------------------------------------------------------

  #measurementUrlItem = `${process.env.REACT_APP_API_URL}/api/measurements/list`
  #methodUrlItem = `${process.env.REACT_APP_API_URL}/api/methods/list`
  #typeUrlItem = `${process.env.REACT_APP_API_URL}/api/verification-type/list`
  #pointUrlItem = `${process.env.REACT_APP_API_URL}/api/points/list`

  // ----------------------------------------------------------------
  // #9 база приоров
  // ----------------------------------------------------------------

  #metersUrlList = `${process.env.REACT_APP_API_URL}/api/meters/list`
  #metersUrl = `${process.env.REACT_APP_API_URL}/api/meters`
  #metersUrlWithParams = (measurementId, searchQuery) =>
    `${process.env.REACT_APP_API_URL}/api/meters?measurement_id=${measurementId}&search=${searchQuery}`

  // ----------------------------------------------------------------
  // #10 журналы
  // ----------------------------------------------------------------

  #verificationsUrl = `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule`
  #verificationsUrlEdit = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}`
  #verificationsUrlItem = journalId =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}/edit`

  // ----------------------------------------------------------------
  // #11 журналы
  // ----------------------------------------------------------------

  #ticketsUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets`

  #ticketsArchiveUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/archive`

  #ticketsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/${journalId}`

  #ticketsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/${journalId}/edit`

  #ticketsUrlPdf = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/pdf`

  #ticketsUrlCsv = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/csv`

  #ticketsUrlExcel = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/excel`

  #personalUrl = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}`
  #personalArchiveUrl = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/archive`
  #personalUrlEdit = (journalId, companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/${journalId}`
  #personalUrlItem = (journalId, companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/${journalId}/edit`
  #personalUrlPdf = (companyId, arshinMasterId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${arshinMasterId}/pdf`

  // ----------------------------------------------------------------
  // #12 журналы
  // ----------------------------------------------------------------

  #conditionsUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases`

  #conditionsUrlEdit = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/${journalId}`

  #conditionsUrlItem = (journalId, companyId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/${journalId}/edit`

  #archiveConditionsUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/archive`

  #conditionsCsv = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/csv`

  #conditionsExcel = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/excel`

  #conditionsPdf = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/pdf`

  // ----------------------------------------------------------------
  // #13 журналы
  // ----------------------------------------------------------------

  #complectUrl = `${process.env.REACT_APP_API_URL}/api/journals/issue-cases`
  #complectUrlEdit = journalId => `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}`
  #complectUrlItem = journalId => `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}/edit`

  // ----------------------------------------------------------------
  // #14 журналы
  // ----------------------------------------------------------------

  #receivedUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications`
  #receivedArchiveUrl = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/archive`

  #receivedCsv = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/csv`

  #receivedExcel = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/csv`

  #receivedPdf = companyId =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/accounting-of-verifications/pdf`

  // ----------------------------------------------------------------
  // #15 журналы
  // ----------------------------------------------------------------

  #archiveUrl = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives`

  #archiveEntriesUrl = (companyId, entryId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/${entryId}/entries`

  #archiveCsv = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/csv`

  #archiveExcel = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/excel`

  #archivePdf = companyId => `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/archives/pdf`

  // ----------------------------------------------------------------
  // #16 пользователи
  // ----------------------------------------------------------------

  #usersListUrl = `${process.env.REACT_APP_API_URL}/api/users/list`
  #userRegionsUrl = `${process.env.REACT_APP_API_URL}/api/protocols/create`
  #usersUrl = `${process.env.REACT_APP_API_URL}/api/users`
  #usersUrlItem = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}`
  #usersTrustUrl = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}/trust`
  #usersBanUrl = userId => `${process.env.REACT_APP_API_URL}/api/users/${userId}/ban`

  // ----------------------------------------------------------------
  // #17 наборы инструментов
  // ----------------------------------------------------------------

  #etalonUrl = `${process.env.REACT_APP_API_URL}/api/kits`
  #etalonUrlList = `${process.env.REACT_APP_API_URL}/api/kits/list`
  #etalonUrlEdit = etalonId => `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`
  #etalonUrlItem = etalonId => `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`

  // ----------------------------------------------------------------
  // #18 протоколы
  // ----------------------------------------------------------------

  #protocolsUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
  #protocolsUrlEdit = protocol_id => `${process.env.REACT_APP_API_URL}/api/protocols/${protocol_id}`
  #filesUrl = `${process.env.REACT_APP_API_URL}/api/files`
  #protocolsCheck = `${process.env.REACT_APP_API_URL}/api/protocols/sent`
  #protocolsArchive = `${process.env.REACT_APP_API_URL}/api/protocols/archive`
  #protocolsUnArchive = `${process.env.REACT_APP_API_URL}/api/protocols/unarchive`
  #protocolsPdf = `${process.env.REACT_APP_API_URL}/api/protocols/pdf`

  // ----------------------------------------------------------------
  // #19 обновление информации о текущей сессии
  // ----------------------------------------------------------------

  #headerDataUrl = `${process.env.REACT_APP_API_URL}/api/profile/refresh`

  // ----------------------------------------------------------------
  // #20 настройки в различных разделах
  // ----------------------------------------------------------------

  #journalsSettings = company_id => `${process.env.REACT_APP_API_URL}/api/journals/${company_id}/settings`

  async auth(login, password, callback = () => {}) {
    const response = await axios
      .post(this.#authUrl, {
        login,
        password,
      })
      .catch(error => {
        const { status, data } = error.response
        callback()

        return { status, data }
      })

    const { status, data } = response
    callback()

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async addresses(inputValue, locations) {
    const response = await axios
      .post(
        this.#addressUrl,
        locations
          ? {
              query: inputValue,
              count: 0,
              locations,
            }
          : {
              query: inputValue,
              count: 0,
            },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token 4004411614eac159f915e4ae43989e1565b5ca1b`,
          },
        },
      )
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async subscription() {
    const response = await axios
      .get(this.#subscriptionUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async subscriptionService(route) {
    const response = await axios
      .get(this.#subscriptionUrl + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffs() {
    const response = await axios
      .get(this.#tariffsUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async tariffSingle(route) {
    const response = await axios
      .get(this.#tariffsUrl + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffChange(queryData, companyId) {
    const response = await axios
      .post(this.#tariffsUrl + '/' + companyId, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffChangeActivate(queryData, companyId, param) {
    const response = await axios
      .patch(this.#companiesUrl + '/' + companyId + '/' + param, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getBillingPostPay(route) {
    const response = await axios
      .get(this.#billingUrlPostPayment + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getBillingPrePay(route) {
    const response = await axios
      .get(this.#billingUrlPrePayment + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCompanies(param) {
    const response = await axios
      .get(this.#companiesUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCompaniesList() {
    const response = await axios
      .get(this.#companiesUrlList, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addCompanies(queryData) {
    const response = await axios
      .post(this.#companiesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getCompaniesItem(companyId) {
    const response = await axios
      .get(this.#companiesUrlItem(companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editCompanies(companyId, queryData) {
    const response = await axios
      .post(this.#companiesUrlEdit(companyId), queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async changeStatus(companyId) {
    const response = await axios
      .patch(this.#companiesStatusUrl(companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCases(param) {
    const response = await axios
      .get(this.#casesUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCasesItem(param) {
    const response = await axios
      .get(this.#casesUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMeasurementItem() {
    const response = await axios
      .get(this.#measurementUrlItem, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMethodItem(param) {
    const response = await axios
      .get(this.#methodUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getTypeItem(param) {
    const response = await axios
      .get(this.#typeUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getPointItem(param) {
    const response = await axios
      .get(this.#pointUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getCasesShow(caseId) {
    const response = await axios
      .get(this.#casesUrlEdit(caseId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addCases(queryData) {
    const response = await axios
      .post(this.#casesUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async addCasesPut(caseId, queryData) {
    const response = await axios
      .put(this.#casesUrlPut(caseId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async mainCases(caseId) {
    const response = await axios
      .patch(this.#mainCaseUrl(caseId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteCase(param) {
    const response = await axios
      .delete(this.#casesUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getMasterArshin(param) {
    const response = await axios
      .get(this.#masterArshinUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async postMaster(queryData) {
    const response = await axios
      .post(this.#masterArshinUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async putMaster(masterId, queryData) {
    const response = await axios
      .put(this.#masterUrlPut(masterId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getMaster(masterId) {
    const response = await axios
      .get(this.#masterUrlItem(masterId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteMaster(masterId) {
    const response = await axios
      .delete(this.#masterUrlPut(masterId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async getMasterItem() {
    const response = await axios
      .get(this.#masterUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getVerifications(param) {
    const response = await axios
      .get(this.#verificationsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addVerifications(queryData) {
    const response = await axios
      .post(this.#verificationsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getVerificationsItem(journalId) {
    const response = await axios
      .get(this.#verificationsUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editVerifications(journalId, queryData) {
    const response = await axios
      .put(this.#verificationsUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteVerifications(param) {
    const response = await axios
      .delete(this.#verificationsUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getTickets(company_id, queryData) {
    const response = await axios
      .get(this.#ticketsUrl(company_id) + queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getTicketsPdf(company_id) {
    const response = await axios
      .get(this.#ticketsUrlPdf(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getTicketsCsv(company_id) {
    const response = await axios
      .get(this.#ticketsUrlCsv(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getTicketsExcel(company_id) {
    const response = await axios
      .get(this.#ticketsUrlExcel(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addTickets(company_id, queryData) {
    const response = await axios
      .post(this.#ticketsUrl(company_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getTicketsItem(journalId, companyId) {
    const response = await axios
      .get(this.#ticketsUrlItem(journalId, companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editTickets(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#ticketsUrlEdit(journalId, companyId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteTickets(company_id, queryData) {
    const response = await axios
      .delete(this.#ticketsUrl(company_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveTickets(company_id, queryData) {
    const response = await axios
      .patch(this.#ticketsArchiveUrl(company_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getPersonalMetrolog(company_id, arshinMasterId, param) {
    const response = await axios
      .get(this.#personalUrl(company_id, arshinMasterId) + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addPersonalMetrolog(company_id, arshinMasterId, queryData) {
    const response = await axios
      .post(this.#personalUrl(company_id, arshinMasterId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getPersonalMetrologItem(journalId, companyId, arshinMasterId) {
    const response = await axios
      .get(this.#personalUrlItem(journalId, companyId, arshinMasterId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async putPersonalMetrolog(journalId, companyId, arshinMasterId, queryData) {
    const response = await axios
      .put(this.#personalUrlEdit(journalId, companyId, arshinMasterId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deletePersonalMetrolog(journalId, company_id, arshinMasterId) {
    const response = await axios
      .delete(this.#personalUrlEdit(journalId, company_id, arshinMasterId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async archivePersonalMetrolog(companyId, arshinMasterId, queryData) {
    const response = await axios
      .patch(this.#personalArchiveUrl(companyId, arshinMasterId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getPersonalMetrologPdf(companyId, arshinMasterId) {
    const response = await axios
      .get(this.#personalUrlPdf(companyId, arshinMasterId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getConditions(company_id, queryData) {
    const response = await axios
      .get(this.#conditionsUrl(company_id) + queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getConditionsItem(journalId, companyId) {
    const response = await axios
      .get(this.#conditionsUrlItem(journalId, companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editConditions(journalId, companyId, queryData) {
    const response = await axios
      .put(this.#conditionsUrlEdit(journalId, companyId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveConditionsJournal(companyId, queryData) {
    const response = await axios
      .patch(this.#archiveConditionsUrl(companyId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getConditionsPdf(company_id) {
    const response = await axios
      .get(this.#conditionsPdf(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getConditionsCsv(company_id) {
    const response = await axios
      .get(this.#conditionsCsv(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getConditionsExcel(company_id) {
    const response = await axios
      .get(this.#conditionsExcel(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getComplect(param) {
    const response = await axios
      .get(this.#complectUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addComplect(queryData) {
    const response = await axios
      .post(this.#complectUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getComplectItem(journalId) {
    const response = await axios
      .get(this.#complectUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async editComplect(journalId, queryData) {
    const response = await axios
      .put(this.#complectUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteComplect(param) {
    const response = await axios
      .delete(this.#complectUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getReceivedJournals(company_id, queryData) {
    const response = await axios
      .get(this.#receivedUrl(company_id) + queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addReceivedJournalRow(company_id, queryData) {
    const response = await axios
      .post(this.#receivedUrl(company_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async archiveReceivedJournal(company_id, queryData) {
    const response = await axios
      .patch(this.#receivedArchiveUrl(company_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getReceivedPdf(company_id) {
    const response = await axios
      .get(this.#receivedPdf(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getReceivedCsv(company_id) {
    const response = await axios
      .get(this.#receivedCsv(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getReceivedExcel(company_id) {
    const response = await axios
      .get(this.#receivedExcel(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getArchiveJournals(company_id, queryData) {
    const response = await axios
      .get(this.#archiveUrl(company_id) + queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveEntriesJournals(company_id, entry_id, queryData) {
    const response = await axios
      .get(this.#archiveEntriesUrl(company_id, entry_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchivePdf(company_id) {
    const response = await axios
      .get(this.#archivePdf(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveCsv(company_id) {
    const response = await axios
      .get(this.#archiveCsv(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getArchiveExcel(company_id) {
    const response = await axios
      .get(this.#archiveExcel(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getMetersList(param) {
    const response = await axios
      .get(this.#metersUrlList + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMetersListWithParams(measurementId, searchQuery) {
    const response = await axios
      .get(this.#metersUrlWithParams(measurementId, searchQuery), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getMeterItem(param, callback = () => {}) {
    const response = await axios
      .get(this.#metersUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    response && callback()

    return { status, data }
  }

  async addMeter(measurementId, queryData) {
    const response = await axios
      .post(this.#metersUrl + '/' + measurementId, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async editMeter(measurementId, queryData, meterId) {
    const response = await axios
      .put(this.#metersUrl + '/' + measurementId + '/' + Number(meterId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteMeters(measurementId, queryData) {
    const response = await axios
      .delete(this.#metersUrl + '/' + measurementId, { data: queryData }, { headers: this.#HEADERS_JSON })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // возможно метод будет перенесен в статус deprecated
  // ----------------------------------------------------------------

  async getUserRegions() {
    const response = await axios
      .get(this.#userRegionsUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getUsers(param) {
    const response = await axios
      .get(this.#usersUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getUserList(param) {
    const response = await axios
      .get(this.#usersListUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async addUser(queryData) {
    const response = await axios
      .post(this.#usersUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async addUserPut(userId, queryData) {
    const response = await axios
      .put(this.#usersUrlItem(userId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getUsersItem(userId) {
    const response = await axios
      .get(this.#usersUrlItem(userId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async changeUserTrust(userId, queryData) {
    const response = await axios
      .patch(this.#usersTrustUrl(userId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async changeUserBan(userId) {
    const response = await axios
      .patch(this.#usersBanUrl(userId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteUser(userId) {
    const response = await axios
      .delete(this.#usersUrlItem(userId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getEtalon(param) {
    const response = await axios
      .get(this.#etalonUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEtalonList(param) {
    const response = await axios
      .get(this.#etalonUrlList + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async getEtalonItem(etalonId) {
    const response = await axios
      .get(this.#etalonUrlItem(etalonId), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async addEtalon(queryData) {
    const response = await axios
      .post(this.#etalonUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteEtalon(param) {
    const response = await axios
      .delete(this.#etalonUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async editEtalon(etalonId, queryData) {
    const response = await axios
      .put(this.#etalonUrlEdit(etalonId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getProtocol(param, callback = () => {}) {
    const response = await axios
      .get(this.#protocolsUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    if (status !== 'ошибка обращения к api') callback()

    return { status, data }
  }

  async editProtocol(protocol_id, queryData) {
    const response = await axios
      .put(this.#protocolsUrlEdit(protocol_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async postProtocol(queryData) {
    const response = await axios
      .post(this.#protocolsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getProtocolsList(param) {
    const response = await axios
      .get(this.#protocolsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  async deleteProtocol(param) {
    const response = await axios
      .delete(this.#protocolsUrl + '/' + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async addFiles(queryData) {
    const response = await axios
      .post(this.#filesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolCheck(queryData) {
    const response = await axios
      .patch(this.#protocolsCheck, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolArchive(queryData) {
    const response = await axios
      .patch(this.#protocolsArchive, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolUnArchive(queryData) {
    const response = await axios
      .patch(this.#protocolsUnArchive, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolPdf(queryData) {
    try {
      const response = await axios.patch(this.#protocolsPdf, queryData, {
        headers: this.#HEADERS_JSON,
        responseType: 'arraybuffer',
      })

      const { status, data } = response
      return { status, data }
    } catch (error) {
      console.log(error)
      const { status, data } = error.response || {
        status: 500,
        data: 'Ошибка сервера',
      }
      return { status, data }
    }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getHeaderData() {
    const response = await axios
      .get(this.#headerDataUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getJournalSettings(company_id) {
    const response = await axios
      .get(this.#journalsSettings(company_id), {
        headers: this.#HEADERS_JSON,
      })
      .catch(error => {
        console.error(error)
      })

    const { status, data } = response ? response : { status: 'ошибка обращения к api', data: { data: null } }

    return { status, data }
  }
}

const R = new Request()
export default R
