// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import D from "../../../store/localStorage/dispatcher";
import R from "../../../services/app/request.service";

const CompanyShow = () => {
  const navigate = useNavigate();
  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const companyId = D.get("companyId");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const { data: companiesItemData } = await R.getCompaniesItem(companyId);
    const data = companiesItemData.data;

    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <React.Fragment>
        <div
          className="path_to_edit"
          style={{ justifyContent: "space-between" }}
        >
          <h4>Компания: "{data?.name}"</h4>
          <i
            className="fas fa-pencil-alt icon_back"
            onClick={() => navigate(`/metriva/company/${companyId}/edit`)}
          />
        </div>

        <div className="box">
          <div className="flexContainer">
            <div className="company-name-logo">
              {data?.logo?.path ? (
                <img
                  src={data?.logo?.path}
                  alt="logo"
                  className="company-name-logo-img"
                />
              ) : (
                <p>Нет логотипа</p>
              )}
            </div>
            <div className="company-name-desc">
              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "10px" }}
              >
                <p>Генеральный директор:</p>
                <h4>{data?.director_full_name}</h4>
              </div>
              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "10px" }}
              >
                <p>Регион:</p>
                <h4>{data?.region?.name}</h4>
              </div>
              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "10px" }}
              >
                <p>Юридический адрес компании:</p>
                <h4>{data?.address}</h4>
              </div>
              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "10px" }}
              >
                <p>Номер телефона:</p>
                <h4>{data?.phone}</h4>
              </div>
              <div className="flexContainerWithGap">
                <p>Корпоративная почта:</p>
                <h4>{data?.email}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="box">
          <h2 style={{ marginBottom: "20px" }}>Дополнительная информация</h2>

          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>Расчетный счет:</p>
            <h4>{data?.checking_account}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>Корреспондентский счет:</p>
            <h4>{data?.correspondent_account}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>Наименование банка:</p>
            <h4>{data?.bank_name}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>Адрес филиала банка:</p>
            <h4>{data?.bank_address}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>БИК банка:</p>
            <h4>{data?.bic}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>ИНН:</p>
            <h4>{data?.inn}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>КПП:</p>
            <h4>{data?.kpp}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>ОГРН:</p>
            <h4>{data?.bin}</h4>
          </div>
          <div
            className="flexContainerWithGap"
            style={{ marginBottom: "10px" }}
          >
            <p>Шифр поверителя:</p>
            <h4>{data?.sign_cipher}</h4>
          </div>
          <div className="flexContainerWithGap">
            <p>Номер аккредитации:</p>
            <h4>{data?.accreditation_unique_id}</h4>
          </div>
        </div>
      </React.Fragment>
    </main>
  );
};

export default CompanyShow;
