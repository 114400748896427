// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from '@mui/material'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import Table from '../../LocalElements/Table/Table'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import FilterMeasurement from '../../LocalElements/Filter/FilterMeasurement'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import useExcelCsv from '../../../hooks/useExcelCsv'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import { refreshReceiveds, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import { setOpen as setOpenSnackbar, setMessage, setType } from '../../../../store/slices/app/comps/snackbar'
import {
  setSearchQuery,
  setComplete,
  setDateFrom,
  setDateUntil,
  setCreateDateFrom,
  setCreateDateUntil,
  setCreator,
  setTotalAccepted,
  setArchiveFrom,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import { setMeasurementIdFilter } from '../../../../store/slices/app/views/protocols'

import R from '../../../../services/app/request.service'

const TableReceived = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { handleDownload } = useExcelCsv()

  const isLoading = useSelector(state => state.journalReducer.isLoadingReceived)
  const data = useSelector(state => state.journalReducer.dataReceived)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const companyId = useSelector(state => state.companyIdReducer.companyId)

  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)
  const createDateFrom = useSelector(state => state.journalReducer.createDateFrom)
  const createDateUntil = useSelector(state => state.journalReducer.createDateUntil)
  const creator = useSelector(state => state.journalReducer.creator)
  const totalAccepted = useSelector(state => state.journalReducer.totalAccepted)

  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)
  const entryId = useSelector(state => state.journalReducer.entryId)

  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (dateFrom) filtersCount++
    if (dateUntil) filtersCount++
    if (createDateFrom) filtersCount++
    if (createDateUntil) filtersCount++
    if (creator) filtersCount++
    if (totalAccepted) filtersCount++
    if (measurementIdFilter) filtersCount++

    if (
      !complete &&
      !dateFrom &&
      !dateUntil &&
      !createDateFrom &&
      !createDateUntil &&
      !creator &&
      !totalAccepted &&
      !measurementIdFilter
    )
      filtersCount = 0
    return filtersCount
  }, [complete, createDateFrom, createDateUntil, creator, dateFrom, dateUntil, measurementIdFilter, totalAccepted])

  const resetActions = [
    setComplete,
    setDateFrom,
    setDateUntil,
    setCreateDateFrom,
    setCreateDateUntil,
    setCreator,
    setTotalAccepted,
    setMeasurementIdFilter,
  ]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeMeasurementId = param => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeComplete = event => {
    dispatch(setComplete(event.target.value))
  }

  const changeTotalAccepted = event => {
    dispatch(setTotalAccepted(event.target.value))
  }

  const handleDateFromChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(''))
    }
  }

  const handleDateUntilChange = date => {
    if (date) {
      dispatch(setDateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateUntil(''))
    }
  }

  const handleCreateDateFromChange = date => {
    if (date) {
      dispatch(setCreateDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setCreateDateFrom(''))
    }
  }

  const handleCreateDateUntilChange = date => {
    if (date) {
      dispatch(setCreateDateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setCreateDateUntil(''))
    }
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleEditClick = id => {
    false && navigate(`/metriva/journals/${id}/edit_conditions-of-cases`)
  }

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_received')
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getReceivedPdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'received.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getReceivedCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'received.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getReceivedExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'received.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в некоторых записях документа
      </span>
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, найдите подобные записи с помощью фильтра{' '}
        <span style={{ fontWeight: 'bold' }}>"Заполнение записи"</span>, дождитесь появления/внесите недостающие данные
        и повторите процесс архивации.
      </span>
    </section>
  )

  const archiveTitle = <span style={{ color: 'red' }}>Архивация приостановлена</span>

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveReceivedJournal(companyId, {
      date_from: archiveDateFrom,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(false))
      dispatch(refreshReceiveds())
      dispatch(refreshArchives())
      dispatch(resetState())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpenModal(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    }
  }

  useEffect(() => {
    console.log(measurementIdFilter)
  }, [measurementIdFilter])
  useEffect(() => {
    if (archiveDateFrom && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFrom, archiveDateTo])

  let colData = [
    { field: 'Num', headerName: '№ п/п', width: 120 },
    {
      field: 'IncomingDate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Дата поступления СИ
          <Tooltip
            title={'Данные добавляются в запись только при проведении поверки в лаборатории'}
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 230,
    },
    { field: 'Owner', headerName: 'Наименование владельца СИ', width: 230 },
    {
      field: 'WhoGet',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО принявшего СИ
          <Tooltip
            title={
              'Необходимо указать ФИО принимающего СИ во вкладке Настройки. Данные добавляются в запись только при проведении поверки в лаборатории'
            }
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 230,
    },
    { field: 'Num SI', headerName: 'Номер СИ в госреестре', width: 230 },
    { field: 'Name SI', headerName: 'Наименование и тип СИ', width: 430 },
    { field: 'Num customer', headerName: 'Заводской номер', width: 230 },
    { field: 'Check date', headerName: 'Дата поверки', width: 230 },
    { field: 'Num protocol', headerName: '№ протокола поверки', width: 230 },
    {
      field: 'Name executor',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО провод. поверку
          <Tooltip
            title={'Владелец комплекта СП, чьи данные передаются во ФГИС «Аршин» и ФСА'}
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 230,
    },
    { field: 'Conclusion', headerName: 'Заключение поверки', width: 230 },
    { field: 'Num certificate', headerName: '№ свидетельства', width: 230 },
    {
      field: 'Final date',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Дата выдачи СИ
          <Tooltip
            title={'Данные добавляются в запись только при проведении поверки в лаборатории'}
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 230,
    },
    {
      field: 'WhoPass',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО выдавшего СИ
          <Tooltip
            title={
              'Необходимо указать ФИО выдающего СИ во вкладке Настройки Данные добавляются в запись только при проведении поверки в лаборатории'
            }
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 230,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 200,
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              gap: '30px',
            }}
          >
            <Tooltip title='Редактировать запись' placement='top-end'>
              <span onClick={() => handleEditClick(params.row.id)} className='action-container-block'>
                <span
                  className='fas fa-pencil-alt icon'
                  style={{
                    fontSize: '15px',
                    marginTop: '0px',
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title='Удалить запись' placement='top-end'>
              <span
                onClick={() => {
                  dispatch(setOpenModal(true))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                }}
                style={{
                  width: '5%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <span
                  className='fas fa-trash icon'
                  style={{
                    fontSize: '15px',
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    },
  ]

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа будет доступна только в
        режиме просмотра.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>С какого</span>
          <DatePicker
            selectedDate={archiveDateFrom}
            dateChange={date => {
              date ? dispatch(setArchiveFrom(date.format('YYYY-MM-DD'))) : dispatch(setArchiveFrom(null))
            }}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>По какое (включительно)</span>
          <DatePicker
            selectedDate={archiveDateTo}
            dateChange={date => {
              date ? dispatch(setArchiveTo(date.format('YYYY-MM-DD'))) : dispatch(setArchiveTo(null))
            }}
          />
        </div>
      </div>
    </section>
  )

  return (
    <main>
      <RightSideContainer blockTitle={'Фильтры'} containerWidth={700}>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Область измерения</span>
            <div style={{ width: '100%' }}>
              <FilterMeasurement measurementId={measurementIdFilter} setMeasurementId={changeMeasurementId} />
            </div>
          </div>
          {false && (
            <div className='right-side-container-block'>
              <span className='label-filter'>ФИО проводившего поверку</span>
              <div style={{ width: '100%' }}>
                <Selector
                  placeholder={'Выберите нужного мастера'}
                  options={[]}
                  onChange={() => {}}
                  isClearable={true}
                  isSearchable={true}
                  isDisabled={true}
                  value={''}
                  noOptionsMessage={() => 'Нет доступных областей измерения'}
                />
              </div>
            </div>
          )}
          <div className='right-side-container-block'>
            <span className='label-filter'>Заключение поверки</span>
            <div style={{ width: '100%' }}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={totalAccepted}
                onChange={changeTotalAccepted}
              >
                <FormControlLabel
                  value={'accepted'}
                  control={<Radio />}
                  label={'Пригоден'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={'non-accepted'}
                  control={<Radio />}
                  label={'Непригоден'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Способ поверки</span>
            <div style={{ width: '100%' }}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={null}
                onChange={() => {}}
              >
                <FormControlLabel
                  value={''}
                  control={<Radio />}
                  label={'На выезде'}
                  disabled={true}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={''}
                  control={<Radio />}
                  label={'В лаборатории'}
                  disabled={true}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Заполение записи</span>
            <div style={{ width: '100%' }}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={complete}
                onChange={changeComplete}
              >
                <FormControlLabel
                  value={'non-complete'}
                  control={<Radio />}
                  label={'Частичное'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={'complete'}
                  control={<Radio />}
                  label={'Полное'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Создатель записи</span>
            <div style={{ width: '100%' }}>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={creator}
                onChange={event => dispatch(setCreator(event.target.value))}
              >
                <FormControlLabel
                  value={'system'}
                  control={<Radio />}
                  label={'Система'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={'user'}
                  control={<Radio />}
                  disabled={true}
                  label={'Пользователь'}
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата поверки</span>
            <DatePicker selectedDate={dateFrom} dateChange={handleDateFromChange} />

            <DatePicker selectedDate={dateUntil} dateChange={handleDateUntilChange} />
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата создания записи</span>
            <DatePicker selectedDate={createDateFrom} dateChange={handleCreateDateFromChange} />

            <DatePicker selectedDate={createDateUntil} dateChange={handleCreateDateUntilChange} />
          </div>
        </div>
        <Button
          variant='outlined'
          onClick={handleResetFilter}
          style={{
            width: '100%',
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            borderRadius: '12px',
          }}
        >
          Сбросить все значения
        </Button>
      </RightSideContainer>

      <div
        className='header_box'
        style={{ marginTop: '12px', position: 'relative', paddingRight: count > 0 ? '50px' : '0px' }}
      >
        <div
          style={{
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : true}
            variant='contained'
            onClick={handleAddClick}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Добавить запись
          </Button>
        </div>
        <div style={{ width: width > 1050 ? '70%' : '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} disabled={false} />
        </div>

        <div className='flexContainerWithGap' style={{ gap: '12px' }}>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                <Pdf />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Csv' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
                <Csv />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Excel' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
                <Excel />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <React.Fragment>
              {count > 0 ? (
                <Tooltip title='Для архивации документа отключите фильтры' placement='top'>
                  <div style={{ cursor: 'default', opacity: '40%' }}>
                    <ArchiveIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Архивировать документ' placement='top'>
                  <div style={{ cursor: 'pointer' }}>
                    <ArchiveIcon
                      onClick={() => {
                        dispatch(setTitle('Архивация документа'))
                        dispatch(setChildren(modalContent))
                        dispatch(setBA('Архивировать'))
                        dispatch(setConfirmAction(handleArchiveClick))
                        dispatch(setOpenModal(true))
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          style={{
            position: 'relative',
            width: width > 1050 ? '220px' : '100%',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterAltIcon />}
            disabled={!!!entryId ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '22px',
              height: '22px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: '11px',
              lineHeight: '21px',
              textAlign: 'center',
              top: 0,
              left: '100%',
              marginLeft: '-18px',
              marginTop: '-4px',
              transition: 'all 300ms',
              opacity: count > 0 ? 1 : 0,
            }}
          >
            {count}
          </span>
        </div>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </div>

      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '1.2',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',

              height: 'auto',
              lineHeight: '1.5',
              padding: '8px',
              boxSizing: 'border-box',
            },
          }}
          colData={colData}
          rowData={data.map(item => ({
            id: item.id,
            Num: item.number,
            IncomingDate: item.received_date,
            Owner: item.customer_arshin_name,
            WhoGet: item.received_by_name,

            'Num SI': item.fif_number,
            'Name SI': item.name_type_si,
            'Num customer': item.meter_factory_number,
            'Check date': item.verification_date,
            'Num protocol': item.protocol_number,
            'Name executor': item.issued_by_name,
            Conclusion: item.total_accepted === true ? 'Пригоден' : 'Не пригоден',
            'Num certificate': item.arshin_id,
            'Final date': item.received_date,
            WhoPass: item.received_by_name,
          }))}
          rowCallback={handleEditClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default TableReceived
