// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Button, Tooltip } from '@mui/material'
import R from '../../../../services/app/request.service'
import Table from '../../LocalElements/Table/Table'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import { refreshTickets, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import { setOpen as setOpenSnackbar, setMessage, setType } from '../../../../store/slices/app/comps/snackbar'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Users from '../../LocalElements/Filter/FilterUsers'
import Approved from '../../LocalElements/Filter/Approved'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import useExcelCsv from '../../../hooks/useExcelCsv'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setSearchQuery,
  changeTickets,
  resetTickets,
  setArchiveFrom,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'

const TabTickets = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { handleDownload } = useExcelCsv()
  const { width } = useWindowDimensions()

  const data = useSelector(state => state.journalReducer.dataTickets)
  const isLoading = useSelector(state => state.journalReducer.isLoadingTickets)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const measurementId = useSelector(state => state.journalReducer.tickets.measurementId)
  const arshinName = useSelector(state => state.journalReducer.tickets.arshinName)
  const createdAtFrom = useSelector(state => state.journalReducer.tickets.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.tickets.createdAtTo)

  const isChecked = useSelector(state => state.journalReducer.tickets.isChecked)
  const verificationDateFrom = useSelector(state => state.journalReducer.tickets.verificationDateFrom)
  const verificationDateTo = useSelector(state => state.journalReducer.tickets.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.tickets.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.tickets.createdByRole)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const entryId = useSelector(state => state.journalReducer.entryId)
  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (arshinName) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (isChecked) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !arshinName &&
      !createdAtFrom &&
      !createdAtTo &&
      !isChecked &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    arshinName,
    createdAtFrom,
    createdAtTo,
    isChecked,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  const handleResetFilter = () => {
    dispatch(resetTickets())
  }
  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }
  const changeMeasurementId = param => {
    dispatch(changeTickets({ value: 'measurementId', label: param }))
  }
  const changeUserId = param => {
    dispatch(changeTickets({ value: 'arshinName', label: param }))
  }
  const changeIsChecked = event => {
    dispatch(changeTickets({ value: 'isChecked', label: event.target.value }))
  }
  const changeCompletenessStatus = event => {
    dispatch(changeTickets({ value: 'completenessStatus', label: event.target.value }))
  }
  const changeCreatedByRole = event => {
    dispatch(changeTickets({ value: 'createdByRole', label: event.target.value }))
  }
  const handleCreatedAtFromChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'createdAtFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'createdAtFrom', label: '' }))
    }
  }
  const handleCreatedAtToChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'createdAtTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'createdAtTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteTickets(journalId, companyId)
    if (status === 200) {
      dispatch(refreshTickets())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка обращения к API.'))
      dispatch(resetState())
    }
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getTicketsPdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'tickets.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getTicketsCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'tickets.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getTicketsExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'tickets.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
      width: 120,
    },
    {
      field: 'contactDate',
      headerName: 'Дата заявки',
      width: 160,
    },
    {
      field: 'customerArshinName',
      headerName: 'Владелец СИ',
      width: 250,
    },
    {
      field: 'customerFullName',
      headerName: 'Ф.И.О. заказчика',
      width: 300,
    },
    {
      field: 'phone',
      headerName: 'Контактный телефон',
      width: 180,
    },
    {
      field: 'customerAddress',
      headerName: 'Адрес поверки',
      width: 350,
    },
    {
      field: 'verificationDate',
      headerName: 'Плановая дата поверки',
      width: 160,
    },
    {
      field: 'isChecked',
      headerName: 'Отметка о поверке',
      width: 150,
      renderCell: params => {
        return params.value ? (
          <CheckCircleIcon fontSize='small' sx={{ color: '#00963C' }} />
        ) : (
          <CancelIcon fontSize='small' sx={{ color: '#DB1E2A' }} />
        )
      },
    },
    {
      field: 'createdBy',
      headerName: 'Ф.И.О инженера по метрологии',
      width: 300,
    },
    {
      field: 'agreementNumber',
      headerName: 'Номер договора',
      width: 180,
    },
    {
      field: 'comment',
      headerName: 'Примечание',
      width: 250,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 130,
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              gap: '30px',
            }}
          >
            <Tooltip title='Редактировать запись' placement='top-end'>
              <span onClick={() => handleEditClick(params.row.id)} className='action-container-block'>
                <span
                  className='fas fa-pencil-alt icon'
                  style={{
                    fontSize: '15px',
                    marginTop: '0px',
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title='Удалить запись' placement='top-end'>
              <span
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
                style={{
                  width: '5%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-around',
                  cursor: 'pointer',
                }}
              >
                <span
                  className='fas fa-trash icon'
                  style={{
                    fontSize: '15px',
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    },
  ]

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_tickets')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_tickets`)
  }

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в некоторых записях документа
      </span>
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, найдите подобные записи с помощью фильтра{' '}
        <span style={{ fontWeight: 'bold' }}>"Заполнение записи"</span>, дождитесь появления/внесите недостающие данные
        и повторите процесс архивации.
      </span>
    </section>
  )

  const archiveTitle = <span style={{ color: 'red' }}>Архивация приостановлена</span>

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveTickets(companyId, {
      date_from: archiveDateFrom,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshTickets())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  useEffect(() => {
    if (archiveDateFrom && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFrom, archiveDateTo])

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа будет доступна только в
        режиме просмотра.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>С какого</span>
          <DatePicker
            selectedDate={archiveDateFrom}
            dateChange={date => {
              date ? dispatch(setArchiveFrom(date.format('YYYY-MM-DD'))) : dispatch(setArchiveFrom(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>По какое (включительно)</span>
          <DatePicker
            selectedDate={archiveDateTo}
            dateChange={date => {
              date ? dispatch(setArchiveTo(date.format('YYYY-MM-DD'))) : dispatch(setArchiveTo(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
      </div>
    </section>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'} containerWidth={700}>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Область измерения </span>
            <div style={{ width: '100%' }}>
              <Measurement measurementId={measurementId} setMeasurementId={changeMeasurementId} />
            </div>
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Ф.И.О инженера по метрологии</span>
            <div style={{ width: '100%' }}>
              <Users userId={arshinName} setUserId={changeUserId} />
            </div>
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Отметка о поверке</span>
            <Approved
              approved={isChecked}
              changeApproved={changeIsChecked}
              labelTrue={'Да'}
              labelFalse={'Нет'}
              valueTrue={'checked'}
              valueFalse={'not-checked'}
            />
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Заполнение записи</span>
            <Approved
              approved={completenessStatus}
              changeApproved={changeCompletenessStatus}
              labelTrue={'Частичное'}
              labelFalse={'Полное'}
              valueTrue={'complete'}
              valueFalse={'non-complete'}
            />
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Создатель записи</span>
            <Approved
              approved={createdByRole}
              changeApproved={changeCreatedByRole}
              labelTrue={'Система'}
              labelFalse={'Пользователь'}
              valueTrue={'system'}
              valueFalse={'user'}
            />
          </div>
        </div>
        <div className='right-side-container' style={{ width: 'calc(50% - 8px)' }}>
          <div className='right-side-container-block'>
            <span className='label-filter'>Плановая дата поверки</span>
            <DatePicker selectedDate={createdAtFrom} dateChange={handleCreatedAtFromChange} />

            <DatePicker selectedDate={createdAtTo} dateChange={handleCreatedAtToChange} />
          </div>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата создания</span>
            <DatePicker selectedDate={verificationDateFrom} dateChange={handleVerificationDateFromChange} />

            <DatePicker selectedDate={verificationDateTo} dateChange={handleVerificationDateToChange} />
          </div>
        </div>
        <Button
          variant='outlined'
          onClick={handleResetFilter}
          style={{
            width: '100%',
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            borderRadius: '12px',
          }}
        >
          Сбросить все значения
        </Button>
      </RightSideContainer>

      <div
        className='header_box'
        style={{
          marginTop: '12px',
          position: 'relative',
          paddingRight: count > 0 ? '50px' : '0px',
        }}
      >
        <div
          style={{
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : true}
            variant='contained'
            onClick={handleAddClick}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Добавить запись
          </Button>
        </div>

        <div style={{ width: width > 1050 ? '70%' : '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
        </div>

        <div className='flexContainerWithGap' style={{ gap: '12px' }}>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                <Pdf />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Csv' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
                <Csv />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Excel' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
                <Excel />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <React.Fragment>
              {count > 0 ? (
                <Tooltip title='Для архивации документа отключите фильтры' placement='top'>
                  <div style={{ cursor: 'default', opacity: '40%' }}>
                    <ArchiveIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Архивировать документ' placement='top'>
                  <div style={{ cursor: 'pointer' }}>
                    <ArchiveIcon
                      onClick={() => {
                        dispatch(setTitle('Архивация документа'))
                        dispatch(setChildren(modalContent))
                        dispatch(setBA('Архивировать'))
                        dispatch(setConfirmAction(handleArchiveClick))
                        dispatch(setOpen(true))
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          style={{
            position: 'relative',
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : true}
            variant='outlined'
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterAltIcon />}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '22px',
              height: '22px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: '11px',
              lineHeight: '21px',
              textAlign: 'center',
              top: 0,
              left: '100%',
              marginLeft: '-18px',
              marginTop: '-4px',
              transition: 'all 300ms',
              opacity: count > 0 ? 1 : 0,
            }}
          >
            {count}
          </span>
        </div>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </div>

      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '1.2',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',

              height: 'auto',
              lineHeight: '1.5',
              padding: '8px',
              boxSizing: 'border-box',
            },
          }}
          colData={colData}
          rowData={data.map(item => ({
            id: item.id,
            number: item?.number,
            contactDate: moment(item?.contact_date).format('DD-MM-YYYY'),
            customerFullName: item?.customer_fullname,
            phone: item?.customer_phone,
            customerAddress: item?.customer_address,
            verificationDate: moment(item?.planned_date).format('DD-MM-YYYY'),
            isChecked: item?.is_checked,
            customerArshinName: item?.customer_arshin_name,
            createdBy: item?.arshin_master_name,
            agreementNumber: item?.agreement_number,
            comment: item?.comment || '',
          }))}
          rowCallback={handleEditClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </React.Fragment>
  )
}

export default TabTickets
