// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useMemo, useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "@mui/material"
import Input from "../../../components/LocalElements/Input/InputMUI"
import { changeMeterWater, 
  clearMeterWaterMods, 
  changeMeterWaterOptions,
  clearMeterWaterOptions,
  changeMeterWaterMod
} from "../../../../store/slices/app/views/meters"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../../store/slices/app/comps/snackbar"
import { setOpen as setOpenNewMod } from "../../../../store/slices/app/comps/bottomContainer"
import { refreshMeters } from "../../../../store/slices/app/controlers/updater"
import Selector from "../../../components/LocalElements/Selector/reactSelect/Selector"

import FileUploadIcon from '@mui/icons-material/FileUpload'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ImageUpload from "../../../components/Methodologies/views/ImagesUploading/ImagesUploading"
import addMod from "../../../../img/addMod.svg"

import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import AccordionComponent from "../../../components/LocalElements/Accordion/Accordion"
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'

import BottomSideContainer from "../../../components/LocalElements/BottomSideContainer/BottomSideContainer"
import AddMod from "../../../components/Meters/MeterWater/AddMod"
import FilterMetersBase from "../../../components/Meters/FilterMetersBase"

import R from "../../../../services/app/request.service"

const AddMeters = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const mpiBaseSlide = useRef()
  
  const measurement_id = useSelector((state) => state.meterReducer.measurement_id)
  const isClearable = true
  const isSearchable = true

  const data = [
    {
      value: 1,
      label: "МИ 1592-2015",
    },
  ]

  const [ preValidation, setPreValidation ] = useState(false)
  const [ additionalOptions, setAdditionalOptions ] = useState(false)
  const [ editMode, setEditMode ] = useState(false)

  const [ optOne, setOptOne ] = useState("")
  const [ coldOne, setColdOne ] = useState("")
  const [ hotOne, setHotOne ] = useState("")

  const [ optTwo, setOptTwo ] = useState("")
  const [ coldTwo, setColdTwo ] = useState("")
  const [ hotTwo, setHotTwo ] = useState("")

  const [ mpiSlideBack, setMpiSlideBack ] = useState('#21E1B3')
  const [ mpiSlideIcon, setMpiSlideIcon ] = useState('white')
  const [ mpiSlideText, setMpiSlideText ] = useState('white')
  const [ mpiSlideLayout, setMpiSlideLayout ] = useState(0)

  // ----------------------------------------------------------------
  // const [ showColdFault, setShowColdFault ] = useState("long")
  // const [ showHotFault, setShowHotFault ] = useState("short")
  // ----------------------------------------------------------------

  const checkMethod = useSelector((state) => state.meterReducer.createMeterWater.checkMethod)
  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const options = data
    ? data.map((item) => ({
        value: item.value,
        label: item.label,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeMeterWater({
        value: "checkMethod",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const hot = useSelector(
    (state) => state.meterReducer.createMeterWater.hot
  )

  const changeHot = param => 
    dispatch(changeMeterWater({ value: "hot", label: param }))

  const cold = useSelector(
    (state) => state.meterReducer.createMeterWater.cold
  )

  const changeCold = param => 
    dispatch(changeMeterWater({ value: "cold", label: param }))

  const numberRegistry = useSelector(
    (state) => state.meterReducer.createMeterWater.numberRegistry
  )

  const handleNumberRegistryChange = (param) => {
    dispatch(changeMeterWater({ value: "numberRegistry", label: param }))
  }

  const nameSi = useSelector(
    (state) => state.meterReducer.createMeterWater.nameSi
  )

  const handleNameSiChange = (param) => {
    dispatch(changeMeterWater({ value: "nameSi", label: param }))
  }

  const typeSi = useSelector(
    (state) => state.meterReducer.createMeterWater.typeSi
  )

  const handleTypeSiChange = (param) => {
    dispatch(changeMeterWater({ value: "typeSi", label: param }))
  }

  const country = useSelector(
    (state) => state.meterReducer.createMeterWater.country
  )

  const handleCountryChange = (param) => {
    dispatch(changeMeterWater({ value: "country", label: param }))
  }

  const cityFactory = useSelector(
    (state) => state.meterReducer.createMeterWater.cityFactory
  )

  const handleCityFactoryChange = (param) => {
    dispatch(changeMeterWater({ value: "cityFactory", label: param }))
  }

  const manufacturerFactory = useSelector(
    (state) => state.meterReducer.createMeterWater.manufacturerFactory
  )

  const handleManufactureFactoryChange = (param) => {
    dispatch(changeMeterWater({ value: "manufacturerFactory", label: param }))
  }

  const fgisLink = useSelector(
    (state) => state.meterReducer.createMeterWater.fgisLink
  )

  const handleFgisLinkChange = (param) => {
    dispatch(changeMeterWater({ value: "fgisLink", label: param }))
  }

  const qLimitMinCold = useSelector(
    (state) => state.meterReducer.createMeterWater.qLimitMinCold
  )

  const handleQLimitMinColdChange = (param) => {
    dispatch(changeMeterWater({ value: "qLimitMinCold", label: param }))
  }

  const qLimitMaxCold = useSelector(
    (state) => state.meterReducer.createMeterWater.qLimitMaxCold
  )

  const handleQLimitMaxColdChange = (param) => {
    dispatch(changeMeterWater({ value: "qLimitMaxCold", label: param }))
  }

  const qLimitMinHot = useSelector(
    (state) => state.meterReducer.createMeterWater.qLimitMinHot
  )

  const handleQLimitMinHotChange = (param) => {
    dispatch(changeMeterWater({ value: "qLimitMinHot", label: param }))
  }

  const qLimitMaxHot = useSelector(
    (state) => state.meterReducer.createMeterWater.qLimitMaxHot
  )

  const mods = useSelector((state) => state.meterReducer.createMeterWater.mods)
  const imgs = useSelector((state) => state.meterReducer.createMeterWater.imgs)
  const pdf = useSelector((state) => state.meterReducer.createMeterWater.pdf)

  const meterID = useSelector((state) => state.meterReducer.createMeterWater.meterIndex)

  const handleQLimitMaxHotChange = (param) => {
    dispatch(changeMeterWater({ value: "qLimitMaxHot", label: param }))
  }

  const handleOptOne = param => setOptOne(param)
  const handleColdOne = param => setColdOne(param)
  const handleHotOne = param => setHotOne(param)

  const handleOptTwo = param => setOptTwo(param)
  const handleColdTwo = param => setColdTwo(param)
  const handleHotTwo = param => setHotTwo(param)

  const changeImagesList = (list) => {
    dispatch(changeMeterWater({ 
      value: "imgs", 
      label: list
    }))
  }

  const changePDF = (event) => {
    false && console.log(event.target.files)

    dispatch(changeMeterWater({ 
      value: "pdf", 
      label: event.target.files[0]
    }))
  }

  const handleSaveMeters = async () => {
    const queryData = (( qLimitMinHot && qLimitMaxHot ) && ( !!!qLimitMinCold && !!!qLimitMaxCold )) ? {
      method_ids: [1],
      name_si: nameSi,
      type_si: typeSi,
      fif_number: numberRegistry,
      manufacturer_name: manufacturerFactory,
      manufacturer_countries: country,
      q_min_limit_hot: +qLimitMinHot,
      q_max_limit_hot: +qLimitMaxHot,
      mpi_hot: hot,
      mpi_cold: cold,
      type_link: fgisLink,
      modifications: mods,
      pdf: pdf,
      images: imgs,
    } : (( qLimitMinCold && qLimitMaxCold ) && ( !!!qLimitMinHot && !!!qLimitMaxHot )) ? {
      method_ids: [1],
      name_si: nameSi,
      type_si: typeSi,
      fif_number: numberRegistry,
      manufacturer_name: manufacturerFactory,
      manufacturer_countries: country,
      q_min_limit_cold: +qLimitMinCold,
      q_max_limit_cold: +qLimitMaxCold,
      mpi_hot: hot,
      mpi_cold: cold,
      type_link: fgisLink,
      modifications: mods,
      pdf: pdf,
      images: imgs,
    } : (( qLimitMinCold && qLimitMaxCold ) && ( qLimitMinHot && qLimitMaxHot )) ? {
      method_ids: [1],
      name_si: nameSi,
      type_si: typeSi,
      fif_number: numberRegistry,
      manufacturer_name: manufacturerFactory,
      manufacturer_countries: country,
      q_min_limit_cold: +qLimitMinCold,
      q_max_limit_cold: +qLimitMaxCold,
      q_min_limit_hot: +qLimitMinHot,
      q_max_limit_hot: +qLimitMaxHot,
      mpi_hot: hot,
      mpi_cold: cold,
      type_link: fgisLink,
      modifications: mods,
      pdf: pdf,
      images: imgs,
    } : null

    false && console.log(queryData)

    const { data } = await R.editMeter(measurement_id, queryData, meterID)

    if ( data && !!!data.errors ) {
      dispatch(setMessage(data.message))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))

      dispatch(refreshMeters())

      navigate("/metriva/meters")
    } else if ( data && data.errors ) {
      dispatch(setMessage(data.message))
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
    }
  }

  const navigateToProtocols = () => {
    navigate("/metriva/meters")
  }

  const propagationMod = (params) => {
    dispatch(changeMeterWaterMod({
      value: 'title',
      label: params.title
    }))
    dispatch(changeMeterWaterMod({
      value: 'diameter',
      label: params.diameter
    }))
    dispatch(changeMeterWaterMod({
      value: 'koeff',
      label: params.koeff
    }))
    dispatch(changeMeterWaterMod({
      value: 'water',
      label: params.water
    }))

    dispatch(changeMeterWaterMod({
      value: 'vPositionQmin',
      label: params.vPosQmin
    }))
    dispatch(changeMeterWaterMod({
      value: 'vPositionQt',
      label: params.vPosQt
    }))
    dispatch(changeMeterWaterMod({
      value: 'vPositionQmax',
      label: params.vPosQmax
    }))
    dispatch(changeMeterWaterMod({
      value: 'hPositionQmin',
      label: params.hPosQmin
    }))
    dispatch(changeMeterWaterMod({
      value: 'hPositionQt',
      label: params.hPosQt
    }))
    dispatch(changeMeterWaterMod({
      value: 'hPositionQmax',
      label: params.hPosQmax
    }))
    dispatch(changeMeterWaterMod({
      value: 'additionals',
      label: params.additionals
    }))
  }

  const checkValidation = useMemo(() => {
    let result = false
    if (
      nameSi               &&
      typeSi               &&
      numberRegistry       &&
      manufacturerFactory  &&
      country              &&
      ( cold || !!!cold )  &&
      ( hot || !!!hot )    &&
      fgisLink             &&

      ((( qLimitMinCold && qLimitMaxCold ) || ( qLimitMinHot && qLimitMaxHot )) || ( 
        !!!qLimitMinCold  && 
        !!!qLimitMaxCold  && 
        !!!qLimitMinHot   && 
        !!!qLimitMaxHot   &&
        !!!additionalOptions ))
    ) { result = true }

    return result
  }, [
    nameSi,
    typeSi,
    numberRegistry,
    manufacturerFactory,
    country,
    cold,
    hot,
    qLimitMinCold,
    qLimitMaxCold,
    qLimitMinHot,
    qLimitMaxHot,
    fgisLink,
    additionalOptions
  ])

  useEffect(() => {
    setPreValidation(checkValidation)
  }, [
    nameSi,
    typeSi,
    numberRegistry,
    manufacturerFactory,
    additionalOptions,
    country,
    cold,
    hot,
    qLimitMinCold,
    qLimitMaxCold,
    qLimitMinHot,
    qLimitMaxHot,
    fgisLink
  ])

  useEffect(() => {
    if ( optOne && coldOne && hotOne ) {
      dispatch(clearMeterWaterOptions())
      dispatch(changeMeterWaterOptions({
        "text": optOne,
        "mpi_hot": hotOne,
        "mpi_cold": coldOne
    }))
    } else {
      dispatch(clearMeterWaterOptions())
    }
  }, [
    optOne,
    optTwo,
    coldOne,
    coldTwo,
    hotOne,
    hotTwo
  ])

  useEffect(() => {
    additionalOptions === false && dispatch(clearMeterWaterOptions())

    additionalOptions === true 
      ? mpiBaseSlide.current.style.marginLeft = 0
      : mpiBaseSlide.current.style.marginLeft = '-100%'

    if ( additionalOptions === true ) {
      setMpiSlideLayout(2)
      setTimeout(() => {
        setMpiSlideBack('white')
        setMpiSlideIcon('#21E1B3')
        setMpiSlideText('inherit')

        changeCold(null)
        changeHot(null)
      }, 1000)
    } else {
      setTimeout(() => {
        setMpiSlideBack('#21E1B3')
        setMpiSlideIcon('white')
        setMpiSlideText('white')
        setMpiSlideLayout(0)
      }, 600)
    }
  }, [
    additionalOptions
  ])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <BottomSideContainer blockTitle={"Новая модификация"}>
        <AddMod 
          widthAdditionals={additionalOptions}
          isEdit={editMode} 
        />
      </BottomSideContainer>
      <div className="cases__form_add">
        <div className="path_to_back" onClick={navigateToProtocols}>
          <span
            style={{ width: "30px", height: "30px", marginRight: "6px" }}
            className="fas fa-arrow-left icon_back"
          />
          <p>Назад к базе приборов</p>
        </div>
        <div className="flexContainerWithGap">
          <div style={{ width: "50%" }}>
            <FilterMetersBase isDisabled={true} />
          </div>
          <div style={{ width: "50%" }}>
            <Selector
              placeholder={"База приборов"}
              options={options}
              onChange={getValue}
              isClearable={isClearable}
              isSearchable={isSearchable}
              value={options.find((option) => option.value === measurement_id)}
              noOptionsMessage={() => "Нет доступных приборов"}
            />
          </div>
        </div>

        <div className="box">
          <h2 style={{ marginBottom: "24px" }}>Общие данные о приборе</h2>
          <div
            className="mechanical_characteristics"
            style={{ marginBottom: "30px", display: "none" }}
          >
            <Selector
              placeholder={"Выберите подходящие методики поверки"}
              options={options}
              onChange={getValue}
              isClearable={isClearable}
              isSearchable={isSearchable}
              noOptionsMessage={() => "Нет доступных методик поверки"}
              isMulti={true}
            />
          </div>
          <div className="flexContainerWithGap" style={{ marginBottom: "24px" }}>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"Введите название прибора"}
                label={"Наименование прибора"}
                value={nameSi || ""}
                actions={{
                  change: handleNameSiChange,
                }}
              />
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"XX-XX"}
                label={"Тип СИ"}
                value={typeSi || ""}
                actions={{
                  change: handleTypeSiChange,
                }}
              />
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"XXXXX-XX"}
                label={"№ Госреестра (ФИФ)"}
                value={numberRegistry || ""}
                actions={{
                  change: handleNumberRegistryChange,
                }}
              />
            </div>
          </div>

          <div className="flexContainerWithGap" style={{ marginBottom: "24px" }}>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"Введите название завода"}
                label={"Завод производитель"}
                value={manufacturerFactory || ""}
                actions={{
                  change: handleManufactureFactoryChange,
                }}
              />
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"Россия"}
                label={"Страна производства"}
                value={country || ""}
                actions={{
                  change: handleCountryChange,
                }}
              />
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"Введите название города"}
                label={"Город производства ( необязательно )"}
                value={cityFactory || ""}
                noRequired={true}
                actions={{
                  change: handleCityFactoryChange,
                }}
              />
            </div>
          </div>
          <div className="flexContainerWithGap" style={{ marginBottom: "17px" }}>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                position: "absolute",
                width: "calc(66.66666% - 3px)",
                height: "70px",
                marginTop: "-7.5px",
                borderRadius: "5px",
                zIndex: mpiSlideLayout,
                overflow: "hidden"
              }}
            >
              <div
                ref={mpiBaseSlide}
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: mpiSlideBack,
                  border: "3px solid #21E1B3",
                  borderRadius: "10px",
                  transition: "all 600ms",
                  marginLeft: "-100%",
                  padding: "0 16px",
                  gap: "16px"
                }}
              >
                <CheckCircleOutlineIcon 
                  style={{
                    color: mpiSlideIcon,
                    fontSize: "33px",
                    display: "block",
                  }}
                />
                <span style={{ color: mpiSlideText }}>
                  { mods.length === 0 
                    ? 'Создайте модификации и внесите данные о МПИ в них'
                    : 'Созданы модификации. Чтобы добавить общие допусловия, уберите модификации' 
                  }
                </span>
              </div>
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"1"}
                label={"МПИ холодное, лет"}
                value={cold || ""}
                actions={{
                  change: changeCold,
                }}
              />
            </div>
            <div style={{ width: "33.33333%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"1"}
                label={"МПИ горячее, лет"}
                value={hot || ""}
                actions={{
                  change: changeHot,
                }}
              />
            </div>
            <div style={{ width: "33.33333%", paddingLeft: "10px" }}>
              <span style={{ display: "block", marginBottom: "-8px" }}>Дополнительные условия</span>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={ additionalOptions ? "Есть" : "Нет" }
                onChange={() => { 
                  if ( additionalOptions ) {
                    if ( mods.length === 0 ) {
                      setAdditionalOptions(false)
                    }
                  }
                  if ( !!!additionalOptions ) {
                    setAdditionalOptions(true)
                  }
                }}
                style={{
                  flexDirection: "row",
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: "#123532",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "8px",
                  paddingLeft: "5px",
                }}
              >
                <FormControlLabel
                  value="Есть"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="Есть"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
                <FormControlLabel
                  value="Нет"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "22px !important",
                        },
                      }}
                    />
                  }
                  label="Нет"
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          { false && <React.Fragment>
            <h3 style={{ marginBottom: "18px" }}>Информация о дополнительных условиях</h3>
            <div className="flexContainerWithGap" style={{ marginBottom: "24px" }}>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"Описание доп. условия"}
                  label={"Описание доп. условия"}
                  value={optOne || ""}
                  actions={{
                    change: handleOptOne,
                  }}
                />
              </div>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"МПИ холодное, лет"}
                  value={coldOne || ""}
                  actions={{
                    change: handleColdOne,
                  }}
                />
              </div>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"МПИ горячее, лет"}
                  value={hotOne || ""}
                  actions={{
                    change: handleHotOne,
                  }}
                />
              </div>
            </div>
            <div className="flexContainerWithGap" style={{ marginBottom: "23px" }}>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"Описание доп. условия"}
                  label={"Описание доп. условия"}
                  value={optTwo || ""}
                  isDisabled={
                    ( optOne && coldOne && hotOne ) ? true : true
                  }
                  actions={{
                    change: handleOptTwo,
                  }}
                />
              </div>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"МПИ холодное, лет"}
                  value={coldTwo || ""}
                  isDisabled={
                    ( optOne && coldOne && hotOne ) ? true : true
                  }
                  actions={{
                    change: handleColdTwo,
                  }}
                />
              </div>
              <div style={{ width: "33.33333%" }}>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"МПИ горячее, лет"}
                  value={hotTwo || ""}
                  isDisabled={
                    ( optOne && coldOne && hotOne ) ? false : true
                  }
                  actions={{
                    change: handleHotTwo,
                  }}
                />
              </div>
            </div>
          </React.Fragment> }
          { true && <React.Fragment>
            <h3 style={{ marginBottom: "16px" }}>Диапазон допустимой погрешности, %</h3>
            <div className="flexContainerWithGap" style={{ marginBottom: "22px" }}>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  backgroundColor: 
                    ( qLimitMinCold && qLimitMaxCold )
                      ? "#E5F3FC"
                      : "#eaedf375",
                  padding: "20px",
                  boxSizing: "border-box",
                  width: "50%",
                  borderRadius: "12px",
                  gap: "14px",
                  transition: "all 600ms"
                }}
              >
                <h3>ХВ</h3>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"Погрешность от Qmin до Qt, %"}
                  value={qLimitMinCold || ""}
                  actions={{
                    change: handleQLimitMinColdChange,
                  }}
                />
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"Погрешность от Qt до Qmax, %"}
                  value={qLimitMaxCold || ""}
                  actions={{
                    change: handleQLimitMaxColdChange,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  backgroundColor: 
                    ( qLimitMinHot && qLimitMaxHot )
                      ? "#E5F3FC"
                      : "#eaedf375",
                  padding: "20px",
                  boxSizing: "border-box",
                  width: "50%",
                  borderRadius: "12px",
                  gap: "14px",
                  transition: "all 600ms"
                }}
              >
                <h3>ГВ</h3>
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"Погрешность от Qmin до Qt, %"}
                  value={qLimitMinHot || ""}
                  actions={{
                    change: handleQLimitMinHotChange,
                  }}
                />
                <Input
                  className={"input"}
                  type={"text"}
                  placeholder={"1"}
                  label={"Погрешность от Qt до Qmax, %"}
                  value={qLimitMaxHot || ""}
                  actions={{
                    change: handleQLimitMaxHotChange,
                  }}
                />
              </div>
            </div>
          </React.Fragment> }
          <h3 style={{ marginBottom: "18px" }}>Ссылка на ФГИС Аршин и PDF с описанием типа</h3>
          <div className="flexContainerWithGap" style={{ marginBottom: "20px" }}>
            <div style={{ width: "50%" }}>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"https://fgis.gost.ru/fundmetrology/registry"}
                label={"Ссылка на утвержденные типы СИ"}
                value={fgisLink || ""}
                actions={{
                  change: handleFgisLinkChange,
                }}
              />
            </div>
            <div style={{ width: "50%" }}>
              { pdf === null ? <label 
                className="custom-file-input"
                style={{
                  height: '54.4px'
                }}
              >
                <input type="file" accept=".pdf" onChange={changePDF} />
                <span style={{ margin: 0, width: "100%", height: '100%', borderRadius: "12px" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0084E2"
                      d="M8.45417 11.6973C8.33759 11.8248 8.17277 11.8974 8 11.8974C7.82723 11.8974 7.66241 11.8248 7.54583 11.6973L4.26378 8.10755C4.03445 7.85672 4.05187 7.46747 4.30271 7.23814C4.55354 7.0088 4.94279 7.02623 5.17212 7.27707L7.38462 9.69698V0.615385C7.38462 0.275517 7.66013 0 8 0C8.33987 0 8.61539 0.275517 8.61539 0.615385V9.69698L10.8279 7.27707C11.0572 7.02623 11.4465 7.0088 11.6973 7.23814C11.9481 7.46747 11.9656 7.85672 11.7362 8.10755L8.45417 11.6973Z"
                    />
                    <path
                      fill="#0084E2"
                      d="M1.23077 10.4615C1.23077 10.1217 0.955253 9.84615 0.615386 9.84615C0.275518 9.84615 4.34887e-07 10.1217 4.34887e-07 10.4615V10.5066C-1.61444e-05 11.6287 -2.95089e-05 12.5332 0.0956097 13.2445C0.194905 13.9831 0.407331 14.6049 0.901211 15.0988C1.39509 15.5927 2.01694 15.8051 2.75549 15.9044C3.46685 16 4.37131 16 5.49344 16H10.5066C11.6287 16 12.5332 16 13.2445 15.9044C13.9831 15.8051 14.6049 15.5927 15.0988 15.0988C15.5927 14.6049 15.8051 13.9831 15.9044 13.2445C16 12.5332 16 11.6287 16 10.5066V10.4615C16 10.1217 15.7245 9.84615 15.3846 9.84615C15.0447 9.84615 14.7692 10.1217 14.7692 10.4615C14.7692 11.6393 14.7679 12.4607 14.6846 13.0805C14.6036 13.6826 14.4556 14.0014 14.2285 14.2285C14.0014 14.4556 13.6826 14.6037 13.0805 14.6846C12.4607 14.7679 11.6393 14.7692 10.4615 14.7692H5.53846C4.36068 14.7692 3.53926 14.7679 2.91948 14.6846C2.31741 14.6037 1.99858 14.4556 1.7715 14.2285C1.54441 14.0014 1.39635 13.6826 1.3154 13.0805C1.23208 12.4607 1.23077 11.6393 1.23077 10.4615Z"
                    />
                  </svg>
                  <span style={{ fontSize: "15px", color: "#0084e2", marginLeft: '12px' }}>
                    Загрузить PDF с описанием типа
                  </span>
                </span>
              </label> : <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  gap: '20px',
                }}
              > 
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'flex-end',
                    gap: '3px',
                    width: '200px'
                  }}
                >
                  <span style={{ fontWeight: 500 }}>{ pdf.name }</span>
                  <span style={{ color: 'gray' }}>{( pdf.size / 1024 / 1024 ).toFixed(2)}MB</span>
                </div>
                <span
                  style={{
                    display: 'block',
                    position: 'relative',
                    backgroundColor: 'rgba(234, 237, 243, 0.46)',
                    height: '54.4px',
                    borderRadius: '10px',
                    lineHeight: '52px',
                    padding: '0 28px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    dispatch(changeMeterWater({ 
                      value: "pdf", 
                      label: null
                    }))
                  }}
                >
                  Загрузить новый PDF
                </span>
              </div> }
            </div>
          </div>
          <h3>Фото прибора</h3>
          <div 
            className="flexContainerWithGap"
            style={{ display: "block" }}
          >
            <ImageUpload 
              showTitle={false}
              showInput={false} 
              count={2}
              callback={changeImagesList}
            />
          </div>
        </div>
        <div 
          className="box"
          style={{
            display: "flex",
            flexFlow: "column",
          }}
        >
          <div className="flexContainerWithGap" style={{ marginBottom: "8px" }}>
            <div style={{ display: "flex" }}>
              <h2>Модификации</h2>
              <img
                alt={""}
                src={addMod}
                style={{
                  display: "block",
                  width: "33px",
                  marginLeft: "20px",
                  marginRight: "8px",
                  marginTop: "-2px",
                  cursor: "pointer"
                }}
              />
              <span
                style={{
                  display: "block",
                  position: "relative",
                  color: "#0084E2",
                  fontWeight: 700,
                  marginTop: "4px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setEditMode(false)
                  dispatch(setOpenNewMod(true))
                }}
              >
                Новая модификация
              </span>
            </div>
          </div>
          { mods.length > 0 && <React.Fragment>
              { mods.map((mod, index) => 
                <AccordionComponent 
                  mt={16}
                  title={
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      <h5 style={{ fontWeight: 700 }}>{ mod.name }</h5>
                      <CreateIcon 
                        style={{ 
                          color: "gray",
                          marginLeft: "30px",
                          transform: "scale(0.88)"
                        }} 
                        onClick={event => {
                          event.stopPropagation()

                          propagationMod({
                            title: mod.name,
                            diameter: mod.diameter,
                            koeff: mod.koeff || '',
                            water: mod.type,
                            vPosQmin: mod.q_min_vertical,
                            vPosQt: mod.q_t_vertical,
                            vPosQmax: mod.q_max_vertical,
                            hPosQmin: mod.q_min_horizontal,
                            hPosQt: mod.q_t_horizontal,
                            hPosQmax: mod.q_max_horizontal,
                            additionals: mod.additionals
                          })

                          setEditMode(true)
                          dispatch(setOpenNewMod(true))
                          dispatch(changeMeterWater({ 
                            value: "editIndex", 
                            label: index
                          }))
                          false && dispatch(changeMeterWater({ 
                            value: "mods", 
                            label: mods.filter(item => item.name !== mod.name)
                          }))
                        }}
                      />
                      <DeleteIcon 
                        style={{ 
                          color: "gray",
                          marginLeft: "8px",
                          transform: "scale(0.94)"
                        }} 
                        onClick={event => {
                          event.stopPropagation()

                          dispatch(changeMeterWater({ 
                            value: "mods", 
                            label: mods.filter(item => item.name !== mod.name)
                          }))
                        }}
                      />
                    </div>
                  }
                  dataType={"custom"}
                  data={
                    <React.Fragment>
                      <div className="flexContainerWithGap" style={{ flexFlow: "column", marginBottom: "30px" }}>
                        <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                          <span style={{ width: "280px" }}>Диаметр условного прохода, мм</span>
                          <span style={{ fontWeight: 600 }}>{ mod.diameter }</span>
                        </div>
                        <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                          <span style={{ width: "280px" }}>Коэффициент преобразования</span>
                          <span style={{ fontWeight: 600 }}>{"Нет информации"}</span>
                        </div>
                        <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                          <span style={{ width: "280px" }}>Измеряемая вода</span>
                          <span style={{ fontWeight: 600 }}>{ mod.type === 'cold' ? "Холодная" : "Горячая" }</span>
                        </div>
                      </div>
                      <div className="flexContainerWithGap" style={{ gap: "50px", marginBottom: "14px" }}>
                        <h5 style={{ fontWeight: 600 }}>{"Информация о qmin, qt и qmax"}</h5>
                      </div>
                      <div className="flexContainerWithGap" style={{ gap: "0px" }}>
                        <div style={{ display: "flex", flexFlow: "column", gap: "14px", borderRight: "1px solid #8080802e", }}>
                          <div 
                            style={{ 
                              display: "flex", 
                              flexFlow: "row", 
                              gap: "30px", 
                              borderBottom: "1px solid #8080802e",
                              paddingRight: "25px",
                              paddingBottom: "12px" 
                            }}
                          >
                            <span style={{ width: "100%", fontWeight: 500 }}>горизонтальное расположение</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "100px" }}>Qmin</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_min_horizontal }</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "100px" }}>Qt</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_t_horizontal }</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "100px" }}>Qmax</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_max_horizontal }</span>
                          </div>
                        </div>
                        <div style={{ display: "flex", flexFlow: "column", gap: "14px" }}>
                          <div 
                            style={{ 
                              display: "flex", 
                              flexFlow: "row", 
                              gap: "30px", 
                              borderBottom: "1px solid #8080802e",
                              paddingLeft: "25px",
                              paddingBottom: "12px"
                            }}
                          >
                            <span style={{ width: "100%", fontWeight: 500 }}>вертикальное расположение</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px", paddingLeft: "25px", }}>
                            <span style={{ width: "100px" }}>Qmin</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_min_vertical }</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px", paddingLeft: "25px", }}>
                            <span style={{ width: "100px" }}>Qt</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_t_vertical }</span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px", paddingLeft: "25px", }}>
                            <span style={{ width: "100px" }}>Qmax</span>
                            <span style={{ fontWeight: 600 }}>{ mod.q_max_vertical }</span>
                          </div>
                        </div>
                      </div>
                      { mod.additionals.length > 0 && <React.Fragment>
                        <div className="flexContainerWithGap" style={{ gap: "50px", marginTop: "20px", marginBottom: "15px" }}>
                          <h5 style={{ fontWeight: 600 }}>{"Информация о дополнительных условиях"}</h5>
                        </div>
                        <div className="flexContainerWithGap" style={{ flexFlow: "column" }}>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "280px" }}>Описание дополнительного условия</span>
                            <span style={{ fontWeight: 600 }}>
                              { mod.additionals.length > 0 
                                ? mod.additionals[0].text
                                : '' }
                            </span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "280px" }}>МПИ горячая вода</span>
                            <span style={{ fontWeight: 600 }}>
                              { mod.additionals.length > 0 
                                ? mod.additionals[0].mpi_hot
                                : '' }
                            </span>
                          </div>
                          <div style={{ display: "flex", flexFlow: "row", gap: "30px" }}>
                            <span style={{ width: "280px" }}>МПИ холодная вода</span>
                            <span style={{ fontWeight: 600 }}>
                              { mod.additionals.length > 0 
                                ? mod.additionals[0].mpi_cold
                                : '' }
                            </span>
                          </div>
                        </div>
                      </React.Fragment> }
                    </React.Fragment>
                  }
                />
              )}

            </React.Fragment> }
          { mods.length === 0 && <div className="flexContainerWithGap" style={{ marginBottom: "24px" }}>
            <span 
              style={{ 
                width: "100%", 
                textAlign: "center", 
                opacity: 0.8, 
                color: "gray",
                margin: "22px 0px" 
              }}
            >
              Для данного прибора модификации еще не добавлены
            </span>
          </div> }
          { mods.length > 0 && false && <div className="flexContainerWithGap" style={{ marginBottom: "0px" }}>
            <span 
              style={{ 
                width: "100%", 
                textAlign: "center", 
                opacity: 0.4, 
                color: "gray",
                margin: "18px 0px 10px",
                cursor: "pointer"
              }}
              onClick={() => dispatch(clearMeterWaterMods())}
            >
              Нажмите чтобы стереть все модификации
            </span>
          </div> }
        </div>

        <Button
          variant="contained"
          onClick={handleSaveMeters}
          disabled={!preValidation}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            marginTop: "14px",
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Редактировать прибор
        </Button>
      </div>
    </main>
  )
}

export default AddMeters
