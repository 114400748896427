import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { styled } from "@mui/material"

const CustomTabs = styled(props => (
  <Tabs { ...props } />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
})

export default function BasicTabs({
  tabs = [{ label: "Кнопки не заданы" }],
  containnerCSS = {},
  callback = () => {},
  viewVariant = 0,
}) {
  const handleChange = (event, newValue) => {
    false && false && console.log(newValue)
    false && false && console.log(event)

    callback(newValue)
  }

  return (
      <Box
        sx={{ display: 'flex', height: '100%' }}
      >
        <CustomTabs
          TabIndicatorProps={{ 
            children: (
              <span  
                style={{
                  display: 'block',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: '#1976d2',
                  marginTop: '2px',
                  marginLeft: '2px',
                  opacity: 0.88
                }}
              />
            ) 
          }}
          orientation="vertical"
          variant="scrollable"
          value={viewVariant}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          style={{
            borderRight: "1px solid",
            borderImage: 'linear-gradient(to bottom, transparent, #d7ddea, transparent) 1'
          }}
        >
          { tabs &&
            tabs.map((map, index) => {
              return (
                <Tab
                  key={map.label + "*00" + index}
                  label={map.label}
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    lineHeight: "23px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                  }}
                />
              )
            })}
        </CustomTabs>
      </Box>
  )
}
