// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Button from "@mui/material/Button"
import dayjs from "dayjs"

import R from "../../../../../services/app/request.service"

import Cases from "../../views/ProtocolCases/ProtocolCases"
import MeterData from "../../views/MeterData/MeterWater/MeterData"
import DescriptionType from "./ProtocolComponents/DescriptionType"
import Customer from "../../views/Customer/Customer"
import Accepted from "../../views/Accepted/Accepted"
import Conditions from "./ProtocolComponents/ConditionsEdit"
import Indications from "./ProtocolComponents/IndicationsEdit"
import OpticalSensor from "./ProtocolComponents/OpticalSensorEdit"
import TotalAccepted from "../../views/TotalAccepted/TotalAccepted"
import ProtocolDate from "../../views/ProtocolDate/ProtocolDate"
import ImageUpload from "../../views/ImagesUploading/ImagesUploading"
import DataTransfer from "../../views/DataTransfer/DataTransfer"

import Stepper from "../../../LocalElements/MobileStepper/Stepper"
import useWindowDimensions from "../../../../hooks/useWindowDimensions"

import { setShow } from "../../../../../store/slices/app/comps/feedback.js"
import SkeletonProtocol from "../../../LocalElements/Skeleton/SkeletonProtocol.jsx"

import { changeProperty, setDefaultState } from "./state"
import {
  setMeasurementId,
  changeCase,
  resetCase,
  resetMeasurementId,
} from "../../../../../store/slices/app/views/protocols"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../../../store/slices/app/comps/snackbar"

import { refreshProtocols } from "../../../../../store/slices/app/controlers/updater"

import css from "../../styles.css"
const { PageContainer, BoxContainer } = css

const Edit = () => {
  const { protocol_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const feedback = useSelector((state) => state.feedbackReducer.show)

  const { width } = useWindowDimensions()

  const [data, setData] = useState([])
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [, setValidationErrors] = useState({})
  const [details, setDetails] = useState("")

  const isTrusted = useSelector((state) => state.headerReducer.isTrusted)
  const role = useSelector((state) => state.headerReducer.role)

  const verificationType = useSelector((state) => state.protocolReducer.case.verificationType)
  const pointId = useSelector((state) => state.protocolReducer.case.pointId)
  const opticalSensor = useSelector((state) => state.protocolReducer.case.opticalSensor)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterId = useSelector((state) => state.mi15922015Reducer.meterId)
  const modifications = useSelector((state) => state.mi15922015Reducer.modifications)
  const selectedMod = useSelector((state) => state.mi15922015Reducer.selectedMod)
  const selectedAdditionals = useSelector((state) => state.mi15922015Reducer.selectedAdditionals)
  const defaultData = useSelector((state) => state.mi15922015Reducer.defaultData)
  const mpiColdAdditionals = useSelector((state) => state.mi15922015Reducer.mpiColdAdditionals)
  const mpiHotAdditionals = useSelector((state) => state.mi15922015Reducer.mpiHotAdditionals)
  const valueMod = useSelector((state) => state.mi15922015Reducer.valueMod)
  const valueAdditional = useSelector((state) => state.mi15922015Reducer.valueAdditional)
  const valueAnotherMod = useSelector((state) => state.mi15922015Reducer.valueAnotherMod)
  const is_custom_modification = useSelector((state) => state.mi15922015Reducer.is_custom_modification)
  const meterFactoryNumber = useSelector((state) => state.mi15922015Reducer.meterFactoryNumber)
  const meterYear = useSelector((state) => state.mi15922015Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector((state) => state.mi15922015Reducer.customerName)
  const customerNameType = useSelector((state) => state.mi15922015Reducer.customerNameType)
  const customerAddress = useSelector((state) => state.mi15922015Reducer.customerAddress)
  const customerFullName = useSelector((state) => state.mi15922015Reducer.customerFullName)
  const customerPhoneNumber = useSelector((state) => state.mi15922015Reducer.customerPhoneNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const diameter = useSelector((state) => state.mi15922015Reducer.diameter)
  const meterLocation = useSelector((state) => state.mi15922015Reducer.meterLocation)

  const meterType = useSelector((state) => state.mi15922015Reducer.meterType)
  const q_min = useSelector((state) => state.mi15922015Reducer.q_min)
  const q_t = useSelector((state) => state.mi15922015Reducer.q_t)
  const q_max = useSelector((state) => state.mi15922015Reducer.q_max)
  const q_actual = useSelector((state) => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector((state) => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector((state) => state.mi15922015Reducer.q_max_limit)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector((state) => state.mi15922015Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector((state) => state.mi15922015Reducer.isTightnessAccepted)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector((state) => state.mi15922015Reducer.isMarkPassport)
  const isMarkSi = useSelector((state) => state.mi15922015Reducer.isMarkSi)
  const verificationDate = useSelector((state) => state.mi15922015Reducer.verificationDate)
  const nextVerificationDate = useSelector((state) => state.mi15922015Reducer.nextVerificationDate)
  const nextVerificationDateAutoGenerate = useSelector((state) => state.mi15922015Reducer.nextVerificationDateAutoGenerate)
  const agreementNumber = useSelector((state) => state.mi15922015Reducer.agreementNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const comment = useSelector((state) => state.mi15922015Reducer.comment)
  const file = useSelector((state) => state.mi15922015Reducer.file)
  const fileBase64 = useSelector((state) => state.mi15922015Reducer.fileBase64)
  const combinedArray = fileBase64.concat(file)
  const resultArray = combinedArray.map((item, index) => {
    return { ...item, serial: index }
  })
  const doChecked = useSelector((state) => state.mi15922015Reducer.doChecked)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector((state) => state.mi15922015Reducer.isTotalAccepted)
  const indicationPass = useSelector((state) => state.mi15922015Reducer.indicationsPass)
  const indications = useSelector((state) => state.mi15922015Reducer.indications)
  const conditions = useSelector((state) => state.mi15922015Reducer.conditions)

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const changeName = (param) => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: "customerName", label: newValue }))
  }

  const changeNameType = (event) => {
    dispatch(
      changeProperty({ value: "customerNameType", label: event.target.value })
    )
    dispatch(
      changeProperty({ value: "customerName", label: event.target.value })
    )
  }

  const changeAddress = (selectedOption) => {
    dispatch(
      changeProperty({
        value: "customerAddress",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const changeFullName = (param) => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: "customerFullName", label: newValue }))
  }

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, "")
    let formattedPhoneNumber = ""

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    dispatch(
      changeProperty({
        value: "customerPhoneNumber",
        label: formattedPhoneNumber,
      })
    )
  }
  const changePhone = (param) => {
    formatPhoneNumber(param)
  }

  const changeMechanical = (event) => {
    const newValue = event.target.value === "true"
    dispatch(
      changeProperty({
        value: "isMechanicalAccepted",
        label: newValue,
      })
    )
  }

  const changeTightness = (event) => {
    const newValue = event.target.value === "true"
    dispatch(
      changeProperty({
        value: "isTightnessAccepted",
        label: newValue,
      })
    )
  }

  const handleMarkPassport = (event) => {
    dispatch(
      changeProperty({
        value: "isMarkPassport",
        label: event.target.checked,
      })
    )
  }

  const handleMarkSi = (event) => {
    dispatch(
      changeProperty({
        value: "isMarkSi",
        label: event.target.checked,
      })
    )
  }

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeProperty({
          value: "verificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const handleNextDateChange = (date) => {
    if (date) {
      dispatch(
        changeProperty({
          value: "nextVerificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const handleDoChecked = (event) => {
    dispatch(
      changeProperty({
        value: "doChecked",
        label: event.target.checked,
      })
    )
  }

  useEffect(() => {
    if (
      defaultData &&
      meterType &&
      verificationDate &&
      dayjs(verificationDate).isValid()
    ) {
      let nextVerificationYears

      if (meterType === "hot") {
        nextVerificationYears = mpiHotAdditionals
          ? parseFloat(mpiHotAdditionals)
          : parseFloat(defaultData.mpi_hot)
      } else if (meterType === "cold") {
        nextVerificationYears = mpiColdAdditionals
          ? parseFloat(mpiColdAdditionals)
          : parseFloat(defaultData.mpi_cold)
      }

      if (nextVerificationYears) {
        const nextVerificationDateCalculated = dayjs(verificationDate)
          .add(nextVerificationYears, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD HH:mm:ss")

        dispatch(
          changeProperty({
            value: "nextVerificationDateAutoGenerate",
            label: nextVerificationDateCalculated,
          })
        )
      }
    } else {
      dispatch(
        changeProperty({
          value: "nextVerificationDateAutoGenerate",
          label: "",
        })
      )
    }
  }, [
    defaultData,
    meterType,
    verificationDate,
    mpiColdAdditionals,
    mpiHotAdditionals,
    dispatch,
  ])

  const handleAgreementChange = (param) => {
    dispatch(changeProperty({ value: "agreementNumber", label: param }))
  }

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted &&
      isTightnessAccepted &&
      !indicationPass.includes(false)

    dispatch(
      changeProperty({ value: "isTotalAccepted", label: totalAccepted })
    )
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    ( async () => {
      dispatch(setShow(true))

      const { data } = await R.getProtocol(protocol_id, () =>
        dispatch(setShow(false))
      )

      setData(data.data)
      dispatch(setMeasurementId(data.data?.measurement?.id))

      dispatch(
        changeCase({
          value: "verificationType",
          label: data.data?.verificationType?.id,
        })
      )
      dispatch(
        changeCase({
          value: "opticalSensor",
          label: data.data?.point?.contains_optical_sensor,
        })
      )
      dispatch(
        changeProperty({
          value: "customerName",
          label: data.data?.customer_arshin_name,
        })
      )
      dispatch(
        changeProperty({
          value: "customerAddress",
          label: data.data?.customer_address,
        })
      )
      dispatch(
        changeProperty({
          value: "customerFullName",
          label: data.data?.customer_fullname || null,
        })
      )
      dispatch(
        changeProperty({
          value: "customerPhoneNumber",
          label: data.data?.customer_phone || null,
        })
      )
      dispatch(
        changeProperty({
          value: "isMechanicalAccepted",
          label: data.data?.mechanical_accepted,
        })
      )
      dispatch(
        changeProperty({
          value: "isTightnessAccepted",
          label: data.data?.tightness_accepted,
        })
      )
      dispatch(
        changeProperty({
          value: "isMarkPassport",
          label: data.data?.mark_passport,
        })
      )
      dispatch(
        changeProperty({
          value: "isMarkSi",
          label: data.data?.mark_si,
        })
      )
      setDetails(
        data.data?.details?.id
      )
      dispatch(
        changeProperty({
          value: "meterId",
          label: data.data?.meter?.id,
        })
      )
      dispatch(
        changeProperty({
          value: "meterLocation",
          label: data.data.details?.orientation,
        })
      )
      dispatch(
        changeProperty({
          value: "meterType",
          label: data.data.details?.meter_type,
        })
      )
      dispatch(
        changeProperty({
          value: "diameter",
          label: data.data.details?.diameter,
        })
      )
      dispatch(
        changeProperty({
          value: "q_min",
          label: data.data.details?.q_min,
        })
      )
      dispatch(
        changeProperty({
          value: "q_t",
          label: data.data.details?.q_t,
        })
      )
      dispatch(
        changeProperty({
          value: "q_max",
          label: data.data.details?.q_max,
        })
      )
      dispatch(
        changeProperty({
          value: "q_min_limit",
          label: data.data.details?.q_min_limit,
        })
      )
      dispatch(
        changeProperty({
          value: "q_max_limit",
          label: data.data.details?.q_max_limit,
        })
      )

      dispatch(
        changeProperty({
          value: "additionalOption",
          label: data.data?.details?.additional_option,
        })
      )
      dispatch(
        changeProperty({
          value: "meterYear",
          label: String(data.data.details.meter_factory_year),
        })
      )
      dispatch(
        changeProperty({
          value: "meterFactoryNumber",
          label: data.data.details.meter_factory_number,
        })
      )
      dispatch(
        changeProperty({
          value: "q_actual",
          label: data.data.details.q_actual,
        })
      )
      dispatch(
        changeProperty({
          value: "agreementNumber",
          label: data.data.agreement_number,
        })
      )

      const initialModifications = data.data.meter.modifications.map((item) => item)
      const initialAdditional = data.data.meter.modifications ?
        data.data.meter.modifications
          .filter(mod => mod.name === data.data?.details?.modification)[0].additionals : []

      const selectedMod = initialModifications.find(
        (item) => item.name === data.data?.details?.modification
      )

      dispatch(
        changeProperty({
          value: "defaultData",
          label: {
            q_max_limit_cold: selectedMod.q_max_limit_cold || "",
            q_max_limit_hot: selectedMod.q_max_limit_hot || "",
            q_min_limit_cold: selectedMod.q_min_limit_cold || "",
            q_min_limit_hot: selectedMod.q_min_limit_hot || "",
            mpi_cold: data.data?.meter.mpi_cold,
            mpi_hot: data.data?.meter.mpi_hot,
          },
        })
      )

      dispatch(
        changeProperty({
          value: "selectedMod",
          label: selectedMod,
        })
      )
      dispatch(
        changeProperty({
          value: "modifications",
          label: initialModifications,
        })
      )
      dispatch(
        changeProperty({
          value: "selectedAdditionals",
          label: initialAdditional,
        })
      )
      dispatch(
        changeProperty({
          value: "valueMod",
          label: data.data?.details?.modification,
        })
      )
      dispatch(
        changeProperty({
          value: "valueAdditional",
          label: data.data?.details?.additional_option,
        })
      )
      dispatch(
        changeProperty({
          value: "valueAnotherMod",
          label: data.data?.details?.modification,
        })
      )
      dispatch(
        changeProperty({
          value: "is_custom_modification",
          label: data.data?.details?.is_custom_modification,
        })
      )
      dispatch(
        changeProperty({
          value: "conditionsValuesEdit",
          label: data.data.protocolCondition.map((item) => ({
            id: item.id,
            atmospheric_pressure: item.atmospheric_pressure,
            relative_humidity: item.relative_humidity,
            temperature_environment: item.temperature_environment,
            water_temp: item.water_temp,
          })),
        })
      )
      dispatch(
        changeProperty({
          value: "opticalSensorValuesEdit",
          label: data.data.protocolIndication.map((item) => ({
            id: item.id,
            conversion_factor: item.conversion_factor,
            q_i: item.q_i,
            impulses: item.impulses,
            v_y_i: item.v_y_i,
          })),
        })
      )
      dispatch(
        changeProperty({
          value: "indicationsValuesEdit",
          label: data.data.protocolIndication.map((item) => ({
            id: item.id,
            start_1: item.start_1,
            start_2: item.start_2,
            start_3: item.start_3,
            finish_1: item.finish_1,
            finish_2: item.finish_2,
            finish_3: item.finish_3,
            q_i_1: item.q_i_1,
            q_i_2: item.q_i_2,
            q_i_3: item.q_i_3,
            v_y_i_1: item.v_y_i_1,
            v_y_i_2: item.v_y_i_2,
            v_y_i_3: item.v_y_i_3,
          })),
        })
      )
      dispatch(
        changeProperty({
          value: "verificationDate",
          label: data.data.verification_date.full,
        })
      )
      dispatch(
        changeProperty({
          value: "nextVerificationDate",
          label: data.data.next_verification_date.full,
        })
      )
      dispatch(
        changeProperty({
          value: "comment",
          label: data.data?.comment_files,
        })
      )
      dispatch(
        changeProperty({
          value: "fileBase64",
          label: data.data?.files.map((item, index) => ({
            id: item.id,
            serial: index,
          })),
        })
      )
      dispatch(
        changeProperty({
          value: "imgBase64",
          label: data.data?.files.map((item) => ({
            path: item.path,
          })),
        })
      )

      dispatch(setShow(false))
    })()
  }, [protocol_id, dispatch])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleSaveProtocol = async () => {
    setIsDisabledBtn(true)

    const data = {
      verification_type_id: verificationType,
      point_id: pointId,

      customer_arshin_name: customerName,
      customer_address: customerAddress,
      customer_fullname: customerFullName,
      customer_phone: customerPhoneNumber,

      mechanical_accepted: isMechanicalAccepted,
      tightness_accepted: isTightnessAccepted,
      mark_passport: isMarkPassport,
      mark_si: isMarkSi,
      total_accepted: isTotalAccepted,

      indications: indications,
      conditions: conditions,

      meterable_id: meterId,
      details: {
        id: details,
        modification: valueMod === "another" ? valueAnotherMod : valueMod,
        additional_option: valueAdditional ? valueAdditional : null,
        is_custom_modification: is_custom_modification,
        meter_factory_number: meterFactoryNumber,
        meter_factory_year: meterYear,
        diameter: parseFloat(diameter),
        orientation: meterLocation,
        meter_type: meterType,
        q_min: parseFloat(q_min),
        q_t: parseFloat(q_t),
        q_max: parseFloat(q_max),
        q_actual: parseFloat(q_actual),
        q_min_limit: parseFloat(q_min_limit),
        q_max_limit: parseFloat(q_max_limit),
      },

      verification_date: verificationDate,
      next_verification_date: nextVerificationDateAutoGenerate
        ? nextVerificationDateAutoGenerate
        : nextVerificationDate,
      agreement_number: agreementNumber,

      comment_files: comment,
      files: resultArray,
      is_send: doChecked,
    }

    const { status, data: protocolEditData } = await R.editProtocol(
      protocol_id,
      data
    )

    if (status === 200) {
      dispatch(setMessage("Протокол успешно отредактирован"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      navigate("/metriva/protocols")
    } else if (status === 422) {
      if (protocolEditData.errors) {
        const errors = protocolEditData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Протокол не отредактирован - ошибка валидации"))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Статус 500. Ошибка обращения к api."))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(setDefaultState())
      dispatch(resetMeasurementId())
    }
  }, [])

  return (
    <PageContainer>
      { feedback ? (
        <SkeletonProtocol />
      ) : (
        <React.Fragment>
          { width <= 1050 && (
            <Stepper
              steps={[
                {
                  description: (
                    <BoxContainer margin={"0px 0px 14px"}>
                      <Customer
                        valueName={customerName}
                        changeName={changeName}
                        valueNameType={customerNameType}
                        changeNameType={changeNameType}
                        valueAddress={customerAddress}
                        changeAddress={changeAddress}
                        valueFullName={customerFullName}
                        changeFullName={changeFullName}
                        valuePhone={customerPhoneNumber}
                        changePhone={changePhone}
                      />
                    </BoxContainer>
                  ),
                },
                {
                  description: (
                    <BoxContainer margin={"0px 0px 14px"}>
                      <MeterData
                        meterFactoryNumber={meterFactoryNumber}
                        meterYear={meterYear}
                        meterId={meterId}
                        modifications={modifications}
                        selectedMod={selectedMod}
                        selectedAdditionals={selectedAdditionals}
                        valueMod={valueMod}
                        valueAdditional={valueAdditional}
                        valueAnotherMod={valueAnotherMod}
                        is_custom_modification={is_custom_modification}
                        changeMeter={changeProperty}
                      />
                    </BoxContainer>
                  ),
                },
                {
                  description: (
                    <BoxContainer margin={"0px 0px 14px"}>
                      <DescriptionType />
                    </BoxContainer>
                  ),
                },

                {
                  description: (
                    <React.Fragment>
                      <BoxContainer margin={"0px 0px 14px"}>
                        <Accepted
                          title={"Внешний осмотр"}
                          description={
                            "Раздел 2 «Методика поверки «Pr»: есть возможность визуально считывать показания счётчика, не нарушена целостность счётного механизм счетчика, отсутствуют механические повреждения на корпусе счётчика, комплектность счётчика соответствует описанию типа, знак утверждения типа нанесён на счётчик в установленном виде"
                          }
                          value={isMechanicalAccepted}
                          onChange={changeMechanical}
                          label={
                            "Соответствие пункту 2.7.1 Методики поверки МИ 1592-2015"
                          }
                        />
                      </BoxContainer>
                    </React.Fragment>
                  ),
                },
                {
                  description: (
                    <React.Fragment>
                      <BoxContainer margin={"0px 0px 14px"}>
                        <Accepted
                          title={"Опробование"}
                          description={
                            "Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в местах соединений и на корпусах счётчиков и эталона не наблюдается каплевыделений и течи воды"
                          }
                          value={isTightnessAccepted}
                          onChange={changeTightness}
                          label={
                            "Соответствие пункту 2.7.2 Методики поверки МИ 1592-2015"
                          }
                        />
                      </BoxContainer>
                    </React.Fragment>
                  ),
                },
                {
                  description: (
                    <BoxContainer margin={"0px 0px 14px"}>
                      <Conditions />
                    </BoxContainer>
                  ),
                },
                {
                  description: (
                    <BoxContainer margin={"0px 0px 14px"}>
                      {opticalSensor ? <OpticalSensor /> : <Indications />}
                    </BoxContainer>
                  ),
                },

                {
                  description: (
                    <React.Fragment>
                      <BoxContainer
                        margin={"0 0 16px"}
                        style={{
                          padding: 0,
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <TotalAccepted value={isTotalAccepted} />
                      </BoxContainer>
                      <BoxContainer margin={"0px 0px 14px"}>
                        <ProtocolDate
                          isTotalAccepted={isTotalAccepted}
                          valuePassport={isMarkPassport}
                          onChangePassport={handleMarkPassport}
                          valueSi={isMarkSi}
                          onChangeSi={handleMarkSi}
                          currentDate={verificationDate}
                          changeCurrentDate={handleDateChange}
                          nextDate={nextVerificationDate}
                          nextDateAutoGenerate={
                            nextVerificationDateAutoGenerate
                          }
                          changeNextDate={handleNextDateChange}
                          valueAgreement={agreementNumber}
                          changeAgreement={handleAgreementChange}
                        />
                      </BoxContainer>
                    </React.Fragment>
                  ),
                },
                ...(isTrusted
                  ? [
                      {
                        description: (
                          <BoxContainer margin={"0px 0px 14px"}>
                            <DataTransfer
                              doChecked={doChecked}
                              handleDoChecked={handleDoChecked}
                            />
                          </BoxContainer>
                        ),
                      },
                    ]
                  : []),
                ...(!isTrusted || role !== "metrologist"
                  ? [
                      {
                        description: (
                          <BoxContainer margin={"0px 0px 14px"}>
                            <ImageUpload />
                          </BoxContainer>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </React.Fragment>
      )}

      { feedback ? (
        <SkeletonProtocol />
      ) : (
        <React.Fragment>
          { width > 1050 && (
            <React.Fragment>
              { data && data.id && <Cases data={data} /> }

              <BoxContainer margin={"4px 0 14px"}>
                <Customer
                  valueName={customerName}
                  changeName={changeName}
                  valueNameType={customerNameType}
                  changeNameType={changeNameType}
                  valueAddress={customerAddress}
                  changeAddress={changeAddress}
                  valueFullName={customerFullName}
                  changeFullName={changeFullName}
                  valuePhone={customerPhoneNumber}
                  changePhone={changePhone}
                />
              </BoxContainer>

              <BoxContainer>
                <MeterData
                  meterFactoryNumber={meterFactoryNumber}
                  meterYear={meterYear}
                  meterId={meterId}
                  modifications={modifications}
                  selectedMod={selectedMod}
                  selectedAdditionals={selectedAdditionals}
                  valueMod={valueMod}
                  valueAdditional={valueAdditional}
                  valueAnotherMod={valueAnotherMod}
                  is_custom_modification={is_custom_modification}
                  changeMeter={changeProperty}
                />
              </BoxContainer>

              <BoxContainer>
                <DescriptionType />
              </BoxContainer>

              <BoxContainer>
                <Accepted
                  title={"Внешний осмотр"}
                  description={
                    "Раздел 2 «Методика поверки «Pr»: есть возможность визуально считывать показания счётчика, не нарушена целостность счётного механизма счетчика, отсутствуют механические повреждения на корпусе счётчика, комплектность счётчика соответствует описанию типа, знак утверждения типа нанесён на счётчик в установленном виде"
                  }
                  label={
                    "Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015"
                  }
                  value={isMechanicalAccepted}
                  onChange={changeMechanical}
                />
              </BoxContainer>

              <BoxContainer>
                <Accepted
                  title={"Опробование"}
                  description={
                    "Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в местах соединений и на корпусах счётчиков и эталона не наблюдается каплевыделений и течи воды"
                  }
                  label={
                    "Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015"
                  }
                  value={isTightnessAccepted}
                  onChange={changeTightness}
                />
              </BoxContainer>

              <BoxContainer>
                <Conditions />
              </BoxContainer>

              <BoxContainer>
                { opticalSensor ? <OpticalSensor /> : <Indications /> }
              </BoxContainer>

              <BoxContainer
                style={{
                  backgroundColor: "transparent",
                  padding: "0px",
                  border: "none",
                }}
              >
                <TotalAccepted value={isTotalAccepted} />
              </BoxContainer>

              <BoxContainer>
                <ProtocolDate
                  isTotalAccepted={isTotalAccepted}
                  valuePassport={isMarkPassport}
                  onChangePassport={handleMarkPassport}
                  valueSi={isMarkSi}
                  onChangeSi={handleMarkSi}
                  currentDate={verificationDate}
                  changeCurrentDate={handleDateChange}
                  nextDate={nextVerificationDate}
                  nextDateAutoGenerate={nextVerificationDateAutoGenerate}
                  changeNextDate={handleNextDateChange}
                  valueAgreement={agreementNumber}
                  changeAgreement={handleAgreementChange}
                />
              </BoxContainer>

              {(!isTrusted || role !== "metrologist") && (
                <BoxContainer>
                  <ImageUpload />
                </BoxContainer>
              )}

              { isTrusted && (
                <BoxContainer>
                  <DataTransfer
                    doChecked={doChecked}
                    handleDoChecked={handleDoChecked}
                  />
                </BoxContainer>
              )}
            </React.Fragment>
          )}
          <Button
            variant="contained"
            onClick={handleSaveProtocol}
            disabled={isDisabledBtn}
            sx={{ mt: 1, mr: 1 }}
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "11px 20px 11px",
              margin: 0,
              borderRadius: "12px",
              width: "100%",
            }}
          >
            Редактировать протокол
          </Button>
        </React.Fragment>
      )}
    </PageContainer>
  )
}

export default Edit
