// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Button from "@mui/material/Button"

import {
  changeMainData,
  changeOverData,
  resetCreateData,
} from "../../../store/slices/app/views/companies"
import { refreshCompanies, refreshHeader } from "../../../store/slices/app/controlers/updater"
import useWindowDimensions from "../../hooks/useWindowDimensions"

import NameCompany from "../../components/Companies/NameCompany"
import AddressCompany from "../../components/Companies/AddressCompany"
import LogoCompany from "../../components/Companies/EditLogoCompany"
import CompanyBank from "../../components/Companies/CompanyBank"
import RegionCompany from "../../components/Companies/RegionCompany"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"

import R from "../../../services/app/request.service"

const EditShowCompany = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { width } = useWindowDimensions()
  const { company_id } = useParams()

  // ----------------------------------------------------------------

  const name = useSelector(
    (state) => state.companiesReducer.createData.main.name
  )
  const ceo = useSelector(
    (state) => state.companiesReducer.createData.main.ceo
  )
  const number = useSelector(
    (state) => state.companiesReducer.createData.main.number
  )

  const email = useSelector(
    (state) => state.companiesReducer.createData.main.email
  )

  const regionId = useSelector(
    (state) => state.companiesReducer.createData.main.regionId
  )

  const address = useSelector(
    (state) => state.companiesReducer.createData.main.address
  )
  const logo = useSelector(
    (state) => state.companiesReducer.createData.main.logo
  )

  const [imageId, setImageId] = useState("")
  const [imageLogoPath, setImageLogoPath] = useState("")

  const statusCompany = useSelector(
    (state) => state.companiesReducer.createData.main.status
  )

  const rs = useSelector((state) => state.companiesReducer.createData.over.rs)
  const ks = useSelector((state) => state.companiesReducer.createData.over.ks)
  const bank = useSelector(
    (state) => state.companiesReducer.createData.over.bank
  )
  const inn = useSelector(
    (state) => state.companiesReducer.createData.over.inn
  )
  const kpp = useSelector(
    (state) => state.companiesReducer.createData.over.kpp
  )
  const ogrn = useSelector(
    (state) => state.companiesReducer.createData.over.ogrn
  )
  const bik = useSelector(
    (state) => state.companiesReducer.createData.over.bik
  )
  const bankAddress = useSelector(
    (state) => state.companiesReducer.createData.over.bankAddress
  )
  const code = useSelector(
    (state) => state.companiesReducer.createData.over.code
  )
  const num = useSelector(
    (state) => state.companiesReducer.createData.over.num
  )
  const orderNum = useSelector(
    (state) => state.companiesReducer.createData.over.orderNum
  )
  const orderDate = useSelector(
    (state) => state.companiesReducer.createData.over.orderDate
  )

  // ----------------------------------------------------------------
  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, "")
    let formattedPhoneNumber = ""

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    return formattedPhoneNumber
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(company_id)
      const data = companiesItemData.data

      false && console.log(data)

      dispatch(
        changeMainData({
          value: "name",
          label: data.name,
        })
      )

      dispatch(
        changeMainData({
          value: "ceo",
          label: data.director_full_name,
        })
      )

      dispatch(
        changeMainData({
          value: "number",
          label: formatPhoneNumber(data.phone),
        })
      )

      dispatch(
        changeMainData({
          value: "email",
          label: data?.email,
        })
      )

      dispatch(
        changeMainData({
          value: "regionId",
          label: data.region.id,
        })
      )

      dispatch(
        changeMainData({
          value: "address",
          label: data.address,
        })
      )

      dispatch(
        changeMainData({
          value: "status",
          label: data.status,
        })
      )

      dispatch(
        changeOverData({
          value: "rs",
          label: data.checking_account,
        })
      )

      dispatch(
        changeOverData({
          value: "ks",
          label: data.correspondent_account,
        })
      )

      dispatch(
        changeOverData({
          value: "bank",
          label: data.bank_name,
        })
      )

      dispatch(
        changeOverData({
          value: "inn",
          label: data.inn,
        })
      )

      dispatch(
        changeOverData({
          value: "kpp",
          label: data.kpp,
        })
      )

      dispatch(
        changeOverData({
          value: "ogrn",
          label: data.bin,
        })
      )

      dispatch(
        changeOverData({
          value: "bik",
          label: data.bic,
        })
      )

      dispatch(
        changeOverData({
          value: "bankAddress",
          label: data.bank_address,
        })
      )

      dispatch(
        changeOverData({
          value: "code",
          label: data.sign_cipher,
        })
      )

      dispatch(
        changeOverData({
          value: "num",
          label: data.accreditation_unique_id,
        })
      )

      dispatch(
        changeOverData({
          value: "orderDate",
          label: data.agreement_date,
        })
      )

      dispatch(
        changeOverData({
          value: "orderNum",
          label: data.agreement_number,
        })
      )

      if ( data.logo && data.logo.id ) { setImageId(data.logo.id) } 
      else { setImageId("") }

      if ( data.logo && ( data.logo.path && data.logo.path !== "data:image/jpeg;base64," )) { setImageLogoPath(data.logo.path) } 
      else { setImageLogoPath("") }
    }

    fetchData()
  }, [ company_id, dispatch ])

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      address: address,
      director_full_name: ceo,
      phone: number.replace(/\D/g, ""),
      email: email,
      region_id: regionId,
      inn: inn,
      kpp: kpp,
      bin: ogrn,
      checking_account: rs,
      bank_name: bank,
      bank_address: bankAddress,
      bic: bik,
      correspondent_account: ks,
      accreditation_unique_id: num,
      sign_cipher: code,
      status: statusCompany,
      agreement_number: orderNum,
      agreement_date: orderDate,
      case_issuer_id: 2,
      journalsResponsibles: [
        {
          name: "first_changed",
          journal_id: "2",
        },
      ],
    }

    if ( logo ) {
      data.image = logo
    } else {
      data.image_id = imageId
    }

    const { status, data: companiesAddData } = await R.editCompanies(
      company_id,
      data
    )

    if ( status === 200 ) {
      dispatch(setMessage("Компания успешно отредактирована"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshCompanies())
      dispatch(refreshHeader())
      navigate("/metriva/companies")
    } else if (status === 422) {
      if ( companiesAddData.errors ) {
        const errors = companiesAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Компания не отредактирована - ошибка валидации"))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к api"))
      setIsDisabledBtn(false)
    }
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="box">
          <h2 style={{ marginBottom: "25px" }}>Основные данные компании</h2>

          <NameCompany validationErrors={validationErrors} />

          <div 
            className="flexContainer" 
            style={ 
              width > 1050 
                ? { marginBottom: "15px" }
                : { marginBottom: "0px", gap: "15px" }
            }
          >
            <RegionCompany validationErrors={validationErrors} />

            <AddressCompany validationErrors={validationErrors} />
          </div>

          <LogoCompany
            imageLogoPath={imageLogoPath}
            setImageLogoPath={setImageLogoPath}
            setImageId={setImageId}
          />
        </div>

        <CompanyBank validationErrors={validationErrors} />

        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isDisabledBtn}
            sx={{ mt: 1, mr: 1 }}
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "11px 20px 11px",
              margin: 0,
              borderRadius: "12px",
              width: "100%",
            }}
          >
            Редактировать компанию
          </Button>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default EditShowCompany
