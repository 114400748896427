// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/rules-of-hooks */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../styles.css.ts'

import DatePicker from '../../app/components/LocalElements/DatePicker/DatePicker.jsx'
import InputElement from '../../app/components/LocalElements/Input/InputMUI.jsx'
import Switch from '../../app/components/LocalElements/Switch/Switch.jsx'

import { IJCR } from '../types.ts'

const { ContentRow } = css

const JCR: React.FC<IJCR> = (props: IJCR): ReactElement => {
  const journalSettingsData =
    props.context === 'main'
      ? useSelector((state: any) => state.adminReducer.journalSettings.mainData)
      : props.context === 'person'
        ? useSelector((state: any) => state.adminReducer.journalSettings.personalData)
        : null

  const changeRowData = (value: string, label: any): void => {
    journalSettingsData[props.contextIndex][value] = label
  }

  return (
    <React.Fragment>
      <ContentRow gap={props.gap ? props.gap : 0} mb={props.mb ? props.mb : 0}>
        <div style={{ display: 'block', width: '25%' }}>
          <span>{props.name}</span>
        </div>
        <div style={{ display: 'block', width: '15%' }}>
          <DatePicker selectedDate={props.date.value} dateChange={props.date.action} />
        </div>
        <div style={{ display: 'block', width: '15%' }}>
          <InputElement
            type={'text'}
            placeholder={'00-00'}
            value={props.number}
            noRequired={true}
            actions={{
              change: () => {},
            }}
            pattern={undefined}
            className={undefined}
            label={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
        <div style={{ display: 'block', width: '30%' }}>
          {props.executorControllable !== false ? (
            <InputElement
              type={'text'}
              placeholder={'Введите ответственного'}
              value={props.executor}
              noRequired={true}
              actions={{
                change: () => {},
              }}
              pattern={undefined}
              className={undefined}
              label={undefined}
              length={undefined}
              style={undefined}
              multiline={undefined}
              minRows={undefined}
              maxRows={undefined}
              sx={undefined}
            />
          ) : (
            <span>{props.executor}</span>
          )}
        </div>
        <div style={{ display: 'block', width: '15%', paddingLeft: '14px' }}>
          {props.actions !== false && <Switch disabled={true} />}
        </div>
      </ContentRow>
    </React.Fragment>
  )
}

export default JCR
