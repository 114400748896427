import React from "react"
import crashGif from "../../img/crash.gif"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: "",
      errorInfo: "",
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: `${error.name}: ${error.message}`})
    this.setState({errorInfo: JSON.stringify(errorInfo)})
    this.setState({ hasError: !this.state.hasError })

    false && console.log(errorInfo)
  }

  returnErrorInfo(errorInfo) {
    return errorInfo
  }

  render() {
    const { error, errorInfo } = this.state

    if ( error ) {
      return (
        <section
          style={{
            paddingLeft: '250px',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            width: '100%',
            paddingTop: '120px'
          }}
        >
          <h1 style={{ marginBottom: '18px' }}>приложение крашнулось</h1>
          <span style={{ marginBottom: '18px' }}>сообщите об этом команде разработки - исправлять ошибки это их любимое занятие</span>
          <img 
            alt={"crash"}
            src={crashGif}
            style={{ marginBottom: '18px' }}
          />
          <span style={{ marginBottom: '13px' }}>
            { error }
          </span>
          <span style={{ marginBottom: '50px', width: '70%', lineHeight: '24px' }}>
            { this.returnErrorInfo(errorInfo) }
          </span>
        </section>
      )
    } else {
      return <React.Fragment>{ this.props.children }</React.Fragment>;
    }
  }
}

export default ErrorBoundary