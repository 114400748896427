// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import api from "../../../services/app/axios-config.service"

import { setName } from "../../../store/slices/app/controlers/pageName"
import { setEntryId } from "../../../store/slices/app/views/journals"
import { refreshConditions, refreshReceiveds, refreshTickets } from "../../../store/slices/app/controlers/updater"

import TableConditions from "../../components/Journals/JournalConditionsCases/Table"
import TableReceived from "../../components/Journals/JournalReceivedForVerification/Table"
import TableTickets from "../../components/Journals/JournalTickets/Table"

const MagazinesIndex = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState([])

  const token = useSelector((state) => state.authReducer.authData.token)

  const userRole = useSelector((state) => state.headerReducer.role)
  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const journalId = useSelector((state) => state.journalReducer.journalId)
  const companyId = useSelector((state) => state.companyIdReducer.companyId)

  useEffect(() => {
    dispatch(setName("История журналов"))
  }, [])
  useEffect(() => {
    console.log(journalId)
  }, [ journalId ])

  // ----------------------------------------------------------------
  // техническиий долг - убрать прямое обращение к апи из компонента
  // ----------------------------------------------------------------

  useEffect(() => {
    const fetchMagazine = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/journal-types/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
        const response = await api.get(apiUrl, { headers })
        const magazines = response.data.data

        console.log(magazines)

        setData(magazines)
      } catch (error) {
        console.error(error)
      }
    }

    fetchMagazine()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setEntryId(""))

      dispatch(refreshConditions())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
    }
  }, [])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" style={{ marginBottom: 0 }} onClick={() => navigate("/metriva/journals/archive")}>
        <i
          className="fas fa-arrow-left icon_back"
          style={{ width: "30px", height: "30px", marginRight: "6px" }}
        />
        <p>Все архивные документы</p>
      </div>
      <div className="header_box">
        <div className="selector" style={{ width: "100%" }}>
          <h4
            style={{ display: "block", position: "relative", height: "55px", lineHeight: "52px" }}
          >
            { data.filter(item => item.id === +journalId)[0] ? data.filter(item => item.id === +journalId)[0].name : '' }
          </h4>
        </div>
      </div>

      {( !!!journalId || !!!companyId ) && (
        <div className="box" style={{ marginTop: "12px" }}>
          <div className="page_null">
            <h4 className="context-null-table">
              Для просмотра записей выберите компанию и документ
            </h4>
          </div>
        </div>
      )}

      { companyId && (
        <React.Fragment>
          { journalId === 2 && <TableConditions /> }
          { journalId === 3 && <TableReceived /> }
          { journalId === 4 && <TableTickets /> }
          { journalId === 5 && (
            <div className="box" style={{ marginTop: "12px" }}>
              <div className="page_null">
                <h4 className="context-null-table">
                  Данный функционал находится в разработке
                </h4>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </main>
  )
}

export default MagazinesIndex
