// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DatePicker from "../../LocalElements/DatePicker/DatePicker"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Measurement from "../../LocalElements/Filter/FilterMeasurement"
import Input from "../../LocalElements/Input/InputMUI"
import Feedback from "../../LocalElements/Feedback/Feedback"
import R from "../../../../services/app/request.service"
import {
  changeReceived,
  resetReceived
} from "../../../../store/slices/app/views/journals"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../../store/slices/app/comps/snackbar"
import { refreshReceiveds } from "../../../../store/slices/app/controlers/updater"

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const [ localArshinName, setLocalArshinName ] = useState("") 
  
  const userRole = useSelector((state) => state.headerReducer.role)
  const show = useSelector((state) => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector((state) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state) => state.headerReducer.companyId)
  const companyId = userRole === "super" ? companyIdSuperAdmin : companyIdUser

  const measurementId = useSelector((state) => state.journalReducer.received.measurementId)
  const receivedDate = useSelector((state) => state.journalReducer.received.receivedDate)
  const arshinName = useSelector((state) => state.journalReducer.received.customerArshinName)
  const receivedByName = useSelector((state) => state.journalReducer.received.receivedByName)
  const fifNumber = useSelector((state) => state.journalReducer.received.fifNumber)
  const nameTypeSi = useSelector((state) => state.journalReducer.received.nameTypeSi)
  const factoryNumber = useSelector((state) => state.journalReducer.received.meterFactoryNumber)
  const verDate = useSelector((state) => state.journalReducer.received.verificationDate)
  const protocolNumber = useSelector((state) => state.journalReducer.received.protocolNumber)
  const arshinMasterName = useSelector((state) => state.journalReducer.received.arshinMasterName)
  const issuedDate = useSelector((state) => state.journalReducer.received.issuedDate)
  const issuedName = useSelector((state) => state.journalReducer.received.issuedByName)
  const totalAccepted = useSelector((state) => state.journalReducer.received.totalAccepted)
  const arshinId = useSelector((state) => state.journalReducer.received.arshinId)

  const changeMeasurement = (param) => {
    dispatch(changeReceived({ value: "measurementId", label: param }))
  }
  const changeArshinName = (param) => {
    setLocalArshinName(null)
    dispatch(changeReceived({ value: "customerArshinName", label: param }))
  }
  const changeReceivedByName = (param) => {
    dispatch(changeReceived({ value: "receivedByName", label: param }))
  }
  const changeFifNumber = (param) => {
    dispatch(changeReceived({ value: "fifNumber", label: param }))
  }
  const changeNameTypeSi = (param) => {
    dispatch(changeReceived({ value: "nameTypeSi", label: param }))
  }
  const changeFactoryNumber = (param) => {
    dispatch(changeReceived({ value: "meterFactoryNumber", label: param }))
  }
  const changeProtocolNumber = (param) => {
    dispatch(changeReceived({ value: "protocolNumber", label: param }))
  }
  const changeMasterName = (param) => {
    dispatch(changeReceived({ value: "arshinMasterName", label: param }))
  }
  const changeIssuedNamed = (param) => {
    dispatch(changeReceived({ value: "issuedByName", label: param }))
  }
  const changeArshinId = (param) => {
    dispatch(changeReceived({ value: "arshinId", label: param }))
  }

  useEffect(() => {
    ( async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map((regionId) => regionId.id)
      setRegionValue(region)

      const tariffIds = data.tariffs.map((tariff) => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  const handleSave = async () => {
    console.log(companyId)

    // 'received_date'         => 'Дата поступления СИ',
    // 'customer_arshin_name'  => 'Наименование владельца СИ',
    // 'received_by_name'      => 'ФИО принявшего СИ',
    // 'work_content'          => 'Содержание работ',
    // 'fif_number'            => 'Номер СИ в госреестре',
    // 'name_type_si'          => 'Наименование и тип СИ',
    // 'meter_factory_number'  => 'Заводской номер',
    // 'verification_date'     => 'Дата поверки',
    // 'protocol_number'       => '№ протокола поверки',
    // 'arshin_master_name'    => 'ФИО проводившего поверку',
    // 'total_accepted'        => 'Заключение',
    // 'arshin_id'             => 'Номер свидетельства/извещения',
    // 'issued_date'           => 'Дата выдачи СИ заказчику',
    // 'issued_by_name'        => 'ФИО выдавшего',
    // 'measurement_id'        => 'Область измерения'

    const data = {
      "measurement_id": measurementId,
      "customer_arshin_name": arshinName,
      "received_date": receivedDate,
      "received_by_name": receivedByName,
      "work_content": "no-content",
      "fif_number": fifNumber,
      "name_type_si": nameTypeSi,
      "meter_factory_number": factoryNumber,
      "verification_date": verDate,
      "protocol_number": protocolNumber,
      "arshin_master_name": arshinMasterName,
      "total_accepted": totalAccepted === "true" ? true : false,
      "arshin_id": arshinId,
      "issued_date": issuedDate,
      "issued_by_name": issuedName
    }

    console.log(data)

    const { status, data: receivedAddData } = await R.addReceivedJournalRow(companyId, data)

    if (status === 201) {
      dispatch(setMessage("Запись успешно добавлена"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshReceiveds())
      dispatch(resetReceived())
      navigate("/metriva/journals")
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Запись не добавлена - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate("/metriva/journals")
  }

  useEffect(() => {

    if ( 
      measurementId &&
      arshinName &&
      fifNumber &&
      nameTypeSi &&
      factoryNumber &&
      verDate &&
      protocolNumber &&
      arshinMasterName &&
      arshinId &&
      resetReceived &&
      receivedByName && 
      issuedDate && 
      issuedName
     ) { setIsDisabledBtn(false) }
     else { setIsDisabledBtn(true) }

  }, [
    measurementId,
    arshinName,
    fifNumber,
    nameTypeSi,
    factoryNumber,
    verDate,
    protocolNumber,
    arshinMasterName,
    arshinId,
    resetReceived,
    receivedByName,
    issuedDate,
    issuedName
  ])

  useEffect(() => {
    localArshinName && dispatch(changeReceived({ value: "customerArshinName", label: localArshinName }))
  }, [ localArshinName ])

  return (
    <main className="main">
      { show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все документы</p>
          </div>

          <div className="path_to_edit">
            <h4>Журнал учета поступивших на поверку СИ и проведения поверочных работ</h4>
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "14px" }}>Информация о журнале</h2>
            <div className="width50Percent" style={{ margin: "0px 0 20px", width: "100%" }}>
              <p
                style={{
                  marginBottom: "9px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Область измерения{" "}
                {!!measurementId ? (
                  <CheckCircleIcon
                    sx={{
                      color: "#63E6BE",
                      marginLeft: "8px",
                      marginTop: "-2px",
                    }}
                  />
                ) : (
                  <ErrorIcon
                    sx={{
                      color: "#FFD43B",
                      marginLeft: "8px",
                      marginTop: "-1px",
                    }}
                  />
                )}
              </p>
              <div style={{ width: "100%" }}>
                <Measurement
                  measurementId={measurementId}
                  setMeasurementId={changeMeasurement}
                />
              </div>
            </div>
            <div className="flexContainerWithGap" style={{ marginBottom: "24px", alignItems: "center" }}>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Дата поступления СИ
                </p>
                <DatePicker
                  selectedDate={receivedDate}
                  dateChange={date => 
                    dispatch(
                      changeReceived({ 
                        value: "receivedDate", 
                        label: date.format("YYYY-MM-DD") 
                      })
                    )
                  }
                />
              </div>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Владелец СИ в системе Аршин
                </p>
                <Input
                  type={"text"}
                  placeholder={"Выберите тип лица или введите ФИО"}
                  value={localArshinName ? localArshinName : arshinName}
                  actions={{
                    change: changeArshinName,
                  }}
                />
              </div>
              <div className="width32Percent" style={{ paddingLeft: "14px", marginTop: "28px" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={localArshinName}
                  onChange={event => setLocalArshinName(event.target.value)}
                  style={{
                    flexDirection: "row",
                    fontFamily: "'Wix Madefor Display', sans-serif",
                    color: "#123532",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <FormControlLabel
                    value="Физическое лицо"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "22px !important",
                          },
                        }}
                      />
                    }
                    label="Физ. лицо"
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: "15px !important",
                        fontWeight: 400,
                        color: "#132532",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Юридическое лицо"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "22px !important",
                          },
                        }}
                      />
                    }
                    label="Юр. лицо"
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: "15px !important",
                        fontWeight: 400,
                        color: "#132532",
                      },
                    }}
                  />
                </RadioGroup>
              </div>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "24px" }}
            >
              <div className="width50Percent">
                <Input
                  label={"ФИО принявшего СИ"}
                  placeholder={"Напишите полное ФИО"}
                  noRequired={true}
                  type={"text"}
                  value={receivedByName}
                  actions={{
                    change: changeReceivedByName,
                  }}
                />
              </div>

              <div className="width50Percent">
                <Input
                  label={"Номер СИ в госреестре"}
                  placeholder={"Напишите номер средства измерения"}
                  type={"text"}
                  value={fifNumber}
                  actions={{
                    change: changeFifNumber,
                  }}
                />
              </div>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "20px" }}
            >
              <div className="width50Percent">
                <Input
                  label={"Наименование и тип СИ"}
                  placeholder={"Напишите полное наименование средства измерения"}
                  type={"text"}
                  value={nameTypeSi}
                  actions={{
                    change: changeNameTypeSi,
                  }}
                />
              </div>
              <div className="width50Percent">
                <Input
                  label={"Заводской номер СИ"}
                  placeholder={"Напишите заводской номер средства измерения"}
                  type={"text"}
                  value={factoryNumber}
                  actions={{
                    change: changeFactoryNumber,
                  }}
                />
              </div>
            </div>

            <div className="flexContainerWithGap" style={{ marginBottom: "20px", alignItems: "center" }}>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Дата поверки
                  { verDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: "#63E6BE",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: "#FFD43B",
                        marginLeft: "8px",
                        marginTop: "-1px",
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={verDate}
                  dateChange={date => 
                    date ? dispatch(
                      changeReceived({ 
                        value: "verificationDate", 
                        label: date.format("YYYY-MM-DD") 
                      })
                    ) : dispatch(
                      changeReceived({ 
                        value: "verificationDate", 
                        label: null 
                      })
                    )
                  }
                />
              </div>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Номер протокола поверки
                </p>
                <Input
                  type={"text"}
                  placeholder={"Напишите номер протокола"}
                  value={protocolNumber}
                  actions={{
                    change: changeProtocolNumber,
                  }}
                />
              </div>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  ФИО проводившего поверку
                </p>
                <Input
                  type={"text"}
                  placeholder={"Напишите полное ФИО"}
                  value={arshinMasterName}
                  actions={{
                    change: changeMasterName,
                  }}
                />
              </div>
            </div>

            <div className="flexContainerWithGap" style={{ marginBottom: "24px" }}>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Дата выдачи СИ заказчику
                </p>
                <DatePicker
                  selectedDate={issuedDate}
                  dateChange={date => 
                    dispatch(
                      changeReceived({ 
                        value: "issuedDate", 
                        label: date.format("YYYY-MM-DD") 
                      })
                    )
                  }
                />
              </div>
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  ФИО выдавшего СИ
                </p>
                <Input
                  type={"text"}
                  placeholder={"Напишите полное ФИО"}
                  noRequired={true}
                  value={issuedName}
                  actions={{
                    change: changeIssuedNamed,
                  }}
                />
              </div>
              <div className="width32Percent" style={{ paddingLeft: "14px" }}>
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Заключение поверки
                  { true ? (
                    <CheckCircleIcon
                      sx={{
                        color: "#63E6BE",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: "#FFD43B",
                        marginLeft: "8px",
                        marginTop: "-1px",
                      }}
                    />
                  )}
                </p>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={totalAccepted}
                  onChange={event => dispatch(changeReceived({ value: "totalAccepted", label: event.target.value }))}
                  style={{
                    flexDirection: "row",
                    fontFamily: "'Wix Madefor Display', sans-serif",
                    color: "#123532",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "22px !important",
                          },
                        }}
                      />
                    }
                    label="Пригоден"
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: "15px !important",
                        fontWeight: 400,
                        color: "#132532",
                      },
                    }}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "22px !important",
                          },
                        }}
                      />
                    }
                    label="Непригоден"
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: "15px !important",
                        fontWeight: 400,
                        color: "#132532",
                      },
                    }}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="flexContainerWithGap">
              <Input
                label={"Номер свидетельства / извещения"}
                placeholder={"Напишите корректный номер"}
                type={"text"}
                value={arshinId}
                actions={{
                  change: changeArshinId,
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: "14px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Добавить запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Add
