// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import SearchQuery from "../../LocalElements/Search/SearchQuery"
import { Button, Tooltip } from "@mui/material"
import { RadioGroup, FormControlLabel, Radio } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import Skeleton from "../../LocalElements/Skeleton/Skeleton"
import Table from "../../LocalElements/Table/Table"
import RightSideContainer from "../../LocalElements/RightSideContainer/RightSideContainer"
import SelectorJournals from "../Select/SelectJournal"
import Selector from "../../LocalElements/Selector/reactSelect/Selector"
import DatePicker from "../../LocalElements/DatePicker/DatePicker"

import Pdf from "../../../../img/icon/PdfIcon"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Csv from "../../../../img/icon/CsvIcon"
import Excel from "../../../../img/icon/Excel"

import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { setSearchQuery, 
  setArchiveFromDate, 
  setArchiveToDate, 
  setComplete, 
  setJournalIdFilter, 
  setEntryId, 
  setJournalId 
} from "../../../../store/slices/app/views/journals"
import { setOpen as setOpenRightContainer } from "../../../../store/slices/app/comps/rightContainer"
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from "../../../../store/slices/app/comps/snackbar"

import R from "../../../../services/app/request.service"

const TableArchive = () => {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const userRole = useSelector((state) => state.headerReducer.role)
  const isLoading = useSelector((state) => state.journalReducer.isLoadingArchive)

  const data = useSelector((state) => state.journalReducer.dataArchive)
  const journalIdFilter = useSelector((state) => state.journalReducer.journalIdFilter)
  const searchQuery = useSelector((state) => state.journalReducer.searchQuery)
  const archiveFromDate = useSelector((state) => state.journalReducer.archiveFromDate)
  const archiveToDate = useSelector((state) => state.journalReducer.archiveToDate)
  const complete = useSelector((state) => state.journalReducer.complete)

  const companyIdSuperAdmin = useSelector((state) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state) => state.headerReducer.companyId)
  const companyId = userRole === "super" ? companyIdSuperAdmin : companyIdUser

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (journalIdFilter) filtersCount++
    if (archiveFromDate) filtersCount++
    if (archiveToDate) filtersCount++

    if (
      !complete &&
      !journalIdFilter &&
      !archiveFromDate &&
      !archiveToDate 
    ) filtersCount = 0
    return filtersCount
  }, [archiveFromDate, archiveToDate, complete, journalIdFilter])

  const resetActions = [
    setComplete,
    setArchiveFromDate,
    setArchiveToDate,
    setJournalIdFilter
  ]

  const handleResetFilter = () => {
    resetActions.forEach((action) => dispatch(action("")))
  }

  const handleShowClick = (param) => {
    dispatch(setEntryId(+param.id))
    dispatch(setJournalId(+param.row.Title.split(' * ')[1]))
    navigate("/metriva/journals/archive_entries")
  }

  const changeSearchQuery = (param) => {
    dispatch(setSearchQuery(param))
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getArchivePdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "archive.pdf")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType("error"))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage("Ошибка при загрузке PDF."))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getArchiveCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "archive.csv")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType("error"))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage("Ошибка при загрузке Csv."))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getArchiveExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "archive.xlsx")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType("error"))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage("Ошибка при загрузке Excel."))
    }
  }

  let colData = [
    { field: "Num", headerName: "№ п/п", width: 120 },
    { field: "IncomingDate", 
      headerName: ( 
        <div style={{ display: "flex", alignItems: "center" }}>
          Дата переноса в архив
        </div>
      ), 
      width: 230 
    },
    { field: "Title", headerName: ( 
        <div style={{ display: "flex", alignItems: "center" }}>
          Наименование документа
        </div>
      ), 
      width: 300 },
    { field: "RegNum", 
      headerName: "Учетный номер", 
      width: 230 
    },
    { field: "CountList", headerName: "Количество листов", width: 230 },
    { field: "WhoName", headerName: "ФИО передавшего документ в архив", width: 430 },
    { field: "FromArchiveDate", headerName: "Дата выдачи из архива", width: 230 },
    { field: "ToArchiveDate", headerName: "Дата возврата в архив", width: 230 },
    { field: "DestrDate", headerName: "Дата уничтожения", width: 230 },
  ]

  let rowData = data.map((item) => ({
    id: item.id,
    Num: item.number,
    IncomingDate: item.entry_date,
    Title: item.journalType.name + ' * ' + item.journalType.id,
    RegNum: item.account_number,
    CountList: item.pages,
    WhoName: item.created_by_name,
    FromArchiveDate: 'нет данных',
    ToArchiveDate: 'нет данных',
    DestrDate: 'нет данных',
  }))

  return (
    <main>
      <RightSideContainer 
        blockTitle={"Фильтры"}
      >
        <div className="right-side-container">
          <div className="right-side-container-block">
            <span className="label-filter">Наименование документа</span>
            <div style={{ width: "100%" }}>
              <SelectorJournals isLabel={false} variant="filter" />
            </div>
          </div>
          <div className="right-side-container-block">
            <span className="label-filter" style={{ opacity: 0.6 }}>Передавший в архив</span>
            <div style={{ width: "100%" }}>
              <Selector
                placeholder={"Выберите нужного человека"}
                options={[]}
                onChange={() => {}}
                isClearable={true}
                isSearchable={true}
                isDisabled={true}
                value={""}
                noOptionsMessage={() => "Нет доступных вариантов"}
              />
            </div>
          </div>
          <div className="right-side-container-block">
            <span className="label-filter">Дата переноса в архив</span>
            <DatePicker
              selectedDate={archiveFromDate}
              dateChange={date => dispatch(setArchiveFromDate(date.format("YYYY-MM-DD")))}
            />

            <DatePicker
              selectedDate={archiveToDate}
              dateChange={date => dispatch(setArchiveToDate(date.format("YYYY-MM-DD")))}
            />
          </div>
          <div className="right-side-container-block">
            <span className="label-filter">Заполение записи</span>
            <div style={{ width: "100%" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={complete}
                onChange={event => dispatch(setComplete(event.target.value))}
              >
                <FormControlLabel
                  value={"non-complete"}
                  control={<Radio />}
                  label={"Частичное"}
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
                <FormControlLabel
                  value={"complete"}
                  control={<Radio />}
                  label={"Полное"}
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
              </RadioGroup>
            </div>
          </div>
          <Button
            variant="outlined"
            onClick={handleResetFilter}
            style={{
              width: "100%",
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "11px 20px 11px",
              borderRadius: "12px",
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </RightSideContainer>
      <div
        className="header_box"
        style={{ marginTop: "12px", position: "relative", paddingRight: count > 0 ? "50px" : "0px" }}
      >
        <div style={{ width: width > 1050 ? "80%" : "100%" }}>
          <SearchQuery
            searchQuery={searchQuery}
            setSearchQuery={changeSearchQuery}
            disabled={false}
          />
        </div>

        <div className="flexContainerWithGap" style={{ gap: 15 }}>
          { count > 0 ? (
            <Tooltip
              title="Для скачивания PDF отключите фильтры"
              placement="top"
            >
              <div style={{ cursor: "default", opacity: "40%" }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Скачать в формате PDF" placement="top">
              <div style={{ cursor: "pointer" }} onClick={handleDownloadPdf}>
                <Pdf />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Скачать документ в Csv" placement="top">
            <div
              style={{ cursor: "pointer" }}
              onClick={handleDownloadCsv}
            >
              <Csv />
            </div>
          </Tooltip>
          <Tooltip title="Скачать документ в Excel" placement="top">
            <div
              style={{ cursor: "pointer" }}
              onClick={handleDownloadExcel}
            >
              <Excel />
            </div>
          </Tooltip>
        </div>

        <div
          style={{
            position: "relative",
            width: width > 1050 ? "220px" : "100%",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterAltIcon />}
            disabled={false}
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "11px 20px 11px",
              borderRadius: "12px",
              width: "100%",
              height: "54.4px",
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: "block",
              position: "absolute",
              width: "22px",
              height: "22px",
              borderRadius: "50%",
              backgroundColor: "#1976d2",
              color: "white",
              fontSize: "11px",
              lineHeight: "21px",
              textAlign: "center",
              top: 0,
              left: "100%",
              marginLeft: "-18px",
              marginTop: "-4px",
              transition: "all 300ms",
              opacity: count > 0 ? 1 : 0,
            }}
          >
            { count }
          </span>
        </div>
        { count > 0 && <Fab 
          onClick={handleResetFilter}
          size="small"
          style={{
            position: "absolute",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            backgroundColor: "#E74953",
            left: "100%",
            marginLeft: "-48px",
            transform: "scale(0.7)"
          }}
        >
          <CloseIcon style={{ color: '#f8f9fc' }} />
        </Fab> }
      </div>

      { isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "1.2",
              wordWrap: "break-word",
            },
            "& .MuiDataGrid-cell:hover": {
              cursor: "default",
            },
            "& .MuiDataGrid-cell": {
              whiteSpace: "normal",
              wordWrap: "break-word",

              height: "auto",
              lineHeight: "1.5",
              padding: "8px",
              boxSizing: "border-box",
            },
          }}
          colData={colData}
          rowData={rowData}
          rowCallback={handleShowClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default TableArchive