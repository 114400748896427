// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import Selector from "../../../LocalElements/Selector/reactSelect/Selector"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import { useDispatch, useSelector } from "react-redux"

import { changeCase, resetCase } from "../../../../../store/slices/app/views/protocols"

import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const TypeMethod = () => {
  const dispatch = useDispatch()

  const verificationType = useSelector((state) => state.protocolReducer.case.verificationType)
  const verificationTypeId = useSelector((state) => state.protocolReducer.case.verificationTypeId)
  const pointMethod = useSelector((state) => state.protocolReducer.case.pointMethod)
  const pointId = useSelector((state) => state.protocolReducer.case.pointId)

  const handleVerificationTypeChange = (event) => {
    const value = Number(event.target.value)
    dispatch(changeCase({ value: "verificationTypeId", label: value }))
  }

  const options = pointMethod
    ? pointMethod.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : []

  const getValue = (selectedOption) => {
    if (selectedOption) {
      dispatch(
        changeCase({
          value: "pointId",
          label: selectedOption ? selectedOption.value : null,
        })
      )
      const selectedPoint = pointMethod.find(
        (item) => item.id === selectedOption.value
      )

      if (selectedPoint && selectedPoint.isOpticalSensor !== undefined) {
        dispatch(
          changeCase({
            value: "opticalSensor",
            label: !!selectedPoint.isOpticalSensor,
          })
        )
      } else {
        dispatch(changeCase({ value: "opticalSensor", label: null }))
      }
    }
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "14px" }}>Рамки применения методики</h2>
      <div
        className="flexContainer M__TypeMethod__container"
        style={{
          display: "flex",
          flexFlow: "row",
        }}
      >
        <div
          className="width32Percent M__TypeMethod__row"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            width: "30%",
          }}
        >
          <p style={{ display: "flex", flexFlow: "row" }}>
            Выберите тип поверки
            {( verificationTypeId || verificationType ) ? (
              <CheckCircleIcon
                sx={{
                  color: "#63E6BE",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            )}
          </p>

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={verificationTypeId ? verificationTypeId : verificationType}
            onChange={handleVerificationTypeChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "13.6px",
            }}
          >
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{ "& .MuiSvgIcon-root": { fontSize: "22px !important" } }}
                />
              }
              label="Первичная"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#132532",
                },
              }}
              disabled={verificationType === 1}
            />
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{ "& .MuiSvgIcon-root": { fontSize: "22px !important" } }}
                />
              }
              label="Периодическая"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#132532",
                },
              }}
              disabled={verificationType === 2}
            />
          </RadioGroup>
        </div>
        <div
          className="width67Percent M__TypeMethod__row"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            width: "70%",
          }}
        >
          <p style={{ marginBottom: "9px", display: "flex", flexFlow: "row" }}>
            Конкретные разделы методики, применяемые в ходе поверки
            { pointId ? (
              <CheckCircleIcon
                sx={{
                  color: "#63E6BE",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            )}
          </p>
          <div style={{ width: "100%" }}>
            { options.length === 1 ? (
              <React.Fragment>
                { getValue(options[0]) }
                <Selector
                  placeholder={"Выберете раздел методики из списка"}
                  onChange={getValue}
                  options={options}
                  isClearable={false}
                  isSearchable={false}
                  value={options[0]}
                />
              </React.Fragment>
            ) : (
              <Selector
                placeholder={"Выберете раздел методики из списка"}
                onChange={getValue}
                options={options}
                isClearable={false}
                isSearchable={true}
                noOptionsMessage={() =>
                  "Для этой методики нет доступных пунктов поверки"
                }
                value={
                  pointId
                    ? options.find((option) => option.value === pointId)
                    : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TypeMethod
