import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/app/controlers/auth";
import companies from "./slices/app/views/companies";
import cases from "./slices/app/views/cases";
import masterArshin from "./slices/app/views/masterArshin";
import companyId from "./slices/app/controlers/companyId";
import users from "./slices/app/views/users";
import meters from "./slices/app/views/meters";
import protocolReducer from "./slices/app/views/protocols";
import snackbarReducer from "./slices/app/comps/snackbar";
import rightContainer from "./slices/app/comps/rightContainer";
import bottomContainer from "./slices/app/comps/bottomContainer";
import modalReducer from "./slices/app/comps/modal";
import journals from "./slices/app/views/journals";
import etalon from "./slices/app/views/etalon";
import feedback from "./slices/app/comps/feedback";
import updater from "./slices/app/controlers/updater";
import headerData from "./slices/app/controlers/headerData";
import toggle from "./slices/app/controlers/toggle";
import pageName from "./slices/app/controlers/pageName";

// ----------------------------------------------------------------
// стейт для новых методик ниже
// ----------------------------------------------------------------

import exampleMethodologyReducer from "../app/components/Methodologies/Water/ExampleComponent/state";
import gost83242002Reducer from "../app/components/Methodologies/Gas/Gost8324-2002/state";
import gost815683Reducer from "../app/components/Methodologies/Water/Gost8-156-83/state";
import mi15922015Reducer from "../app/components/Methodologies/Water/Mi1592-2015/state";

import showProtocolReducer from "../app/components/Methodologies/UniversalShow/state";

// ----------------------------------------------------------------
// стейт для раздела настроек ниже
// ----------------------------------------------------------------

import admin from "./slices/admin/admin";

export const store = configureStore({
  reducer: {
    authReducer: auth,
    companiesReducer: companies,
    casesReducer: cases,
    masterArshinReducer: masterArshin,
    companyIdReducer: companyId,
    usersReducer: users,
    protocolReducer: protocolReducer,
    snackbarReducer: snackbarReducer,
    rightContainerReducer: rightContainer,
    bottomContainerReducer: bottomContainer,
    modalReducer: modalReducer,
    meterReducer: meters,
    journalReducer: journals,
    etalonReducer: etalon,
    feedbackReducer: feedback,
    updaterReducer: updater,
    headerReducer: headerData,
    toggleReducer: toggle,
    pageNameReducer: pageName,

    exampleMethodologyReducer: exampleMethodologyReducer,
    gost83242002Reducer: gost83242002Reducer,
    gost815683Reducer: gost815683Reducer,
    mi15922015Reducer: mi15922015Reducer,

    showProtocolReducer: showProtocolReducer,

    adminReducer: admin,
  },
});
