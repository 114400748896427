// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import useUpdate from "../hooks/useDataUpdater"
import { useSelector } from "react-redux"

const Updater = () => {
  const [
    updateCompanies,
    updateCases,
    updateMasterArhin,
    updateVerifications,
    updateTickets,
    updateConditions,
    updateComplect,
    updateReceived,
    updateArchive,
    updateEntriesArchive,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ] = useUpdate()

  const userRole = useSelector((state) => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector((state) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state) => state.headerReducer.companyId)
  const companyId = userRole === "super" ? companyIdSuperAdmin : companyIdUser
  const token = useSelector((state) => state.authReducer.authData.token)
  const measurement_id = useSelector((state) => state.meterReducer.measurement_id)

  const search_query = useSelector((state) => state.meterReducer.searchQuery)
  const search_query_users = useSelector((state) => state.usersReducer.searchQuery)
  const search_query_etalon = useSelector((state) => state.etalonReducer.searchQuery)
  const search_query_cases = useSelector((state) => state.casesReducer.searchQuery)
  const search_query_companies = useSelector((state) => state.companiesReducer.searchQuery)

  const companiesPing = useSelector((state) => state.updaterReducer.companiesPing)
  const casesPing = useSelector((state) => state.updaterReducer.casesPing)
  const masterArshinPing = useSelector((state) => state.updaterReducer.masterArshinPing)
  const verificationsPing = useSelector((state) => state.updaterReducer.verificationsPing)
  const ticketsPing = useSelector((state) => state.updaterReducer.ticketsPing)
  const conditionsPing = useSelector((state) => state.updaterReducer.conditionsPing)
  const complectsPing = useSelector((state) => state.updaterReducer.complectsPing)
  const receivedsPing = useSelector((state) => state.updaterReducer.receivedsPing)
  const archivesPing = useSelector((state) => state.updaterReducer.archivesPing)
  const entriesArchivePing = useSelector((state) => state.updaterReducer.entriesArchivePing)
  const etalonsPing = useSelector((state) => state.updaterReducer.etalonsPing)
  const metersPing = useSelector((state) => state.updaterReducer.metersPing)
  const usersPing = useSelector((state) => state.updaterReducer.usersPing)
  const protocolsPing = useSelector((state) => state.updaterReducer.protocolsPing)
  const headerPing = useSelector((state) => state.updaterReducer.headerPing)

  const protocolSearchQuery = useSelector((state) => state.protocolReducer.searchQuery)

  const measurementIdFilter = useSelector((state) => state.protocolReducer.measurementIdFilter)
  const userId = useSelector((state) => state.protocolReducer.userId)
  const statusId = useSelector((state) => state.protocolReducer.statusId)

  const approvedTransfer = useSelector((state) => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector((state) => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector((state) => state.protocolReducer.approvedFsa)

  const dateFrom = useSelector((state) => state.protocolReducer.dateFrom)
  const dateTo = useSelector((state) => state.protocolReducer.dateTo)

  const journalId = useSelector((state) => state.journalReducer.journalId)
  const entryId = useSelector((state) => state.journalReducer.entryId)
  const searchQueryJournal = useSelector((state) => state.journalReducer.searchQuery)
  const completeJournal = useSelector((state) => state.journalReducer.complete)
  const dateFromJournal = useSelector((state) => state.journalReducer.dateFrom)
  const dateUntilJournal = useSelector((state) => state.journalReducer.dateUntil)
  const createDateFromJournal = useSelector((state) => state.journalReducer.createDateFrom)
  const createDateUntilJournal = useSelector((state) => state.journalReducer.createDateUntil)
  const creatorJournal = useSelector((state) => state.journalReducer.creator)
  const totalAcceptedJournal = useSelector((state) => state.journalReducer.totalAccepted)
  const journalIdFilter = useSelector((state) => state.journalReducer.journalIdFilter)
  const archiveFromDate = useSelector((state) => state.journalReducer.archiveFromDate)
  const archiveToDate = useSelector((state) => state.journalReducer.archiveToDate)
  const complete = useSelector((state) => state.journalReducer.complete)

  const measurementIdTickets = useSelector((state) => state.journalReducer.tickets.measurementId)
  const arshinNameTickets = useSelector((state) => state.journalReducer.tickets.arshinName)
  const isCheckedTickets = useSelector((state) => state.journalReducer.tickets.isChecked)
  const completenessStatusTickets = useSelector((state) => state.journalReducer.tickets.completenessStatus)
  const createdByRoleTickets = useSelector((state) => state.journalReducer.tickets.createdByRole)
  const createdAtFromTickets = useSelector((state) => state.journalReducer.tickets.createdAtFrom)
  const createdAtToTickets = useSelector((state) => state.journalReducer.tickets.createdAtTo)
  const verificationDateFrom = useSelector((state) => state.journalReducer.tickets.verificationDateFrom)
  const verificationDateTo = useSelector((state) => state.journalReducer.tickets.verificationDateTo)

  useEffect(() => {
    token && updateCompanies(search_query_companies)
  }, [companiesPing, search_query_companies])
  useEffect(() => {
    token && updateCases(search_query_cases, companyId)
  }, [companyId, casesPing, search_query_cases])
  useEffect(() => {
    token && updateMasterArhin(companyId);
  }, [companyId, masterArshinPing]);
  useEffect(() => {
    token && updateEtalon(search_query_etalon, companyId);
  }, [companyId, etalonsPing, search_query_etalon]);
  useEffect(() => {
    token && updateMeters(measurement_id, search_query)
  }, [metersPing, measurement_id, search_query])
  useEffect(() => {
    token && updateUsers(search_query_users, companyId)
  }, [usersPing, companyId, search_query_users])
  useEffect(() => {
    token &&
      updateProtocols(
        companyId,
        protocolSearchQuery,
        measurementIdFilter,
        userId,
        statusId,
        approvedTransfer,
        approvedArshin,
        approvedFsa,
        dateFrom,
        dateTo
      )
  }, [
    protocolsPing,
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  useEffect(() => {
    token && updateHeaderData()
  }, [headerPing])
  useEffect(() => {
    token &&
      false &&
      updateComplect(companyId, dateFromJournal, dateUntilJournal)
  }, [companyId, complectsPing, dateFromJournal, dateUntilJournal])
  useEffect(() => {
    token &&
      companyId &&
      updateTickets(
        companyId,
        searchQueryJournal,
        measurementIdTickets,
        arshinNameTickets,
        isCheckedTickets,
        completenessStatusTickets,
        createdByRoleTickets,
        createdAtFromTickets,
        createdAtToTickets,
        verificationDateFrom,
        verificationDateTo
      )
  }, [
    companyId,
    searchQueryJournal,
    measurementIdTickets,
    arshinNameTickets,
    isCheckedTickets,
    completenessStatusTickets,
    createdByRoleTickets,
    createdAtFromTickets,
    createdAtToTickets,
    verificationDateFrom,
    verificationDateTo,
    ticketsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateConditions(
        companyId,
        searchQueryJournal,
        completeJournal,
        dateFromJournal,
        dateUntilJournal
      )
  }, [
    companyId,
    searchQueryJournal,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    conditionsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateReceived(
        companyId,
        searchQueryJournal,
        measurementIdFilter,
        completeJournal,
        dateFromJournal,
        dateUntilJournal,
        creatorJournal,
        totalAcceptedJournal,
        createDateFromJournal,
        createDateUntilJournal
      )
  }, [
    companyId,
    searchQueryJournal,
    measurementIdFilter,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    creatorJournal,
    totalAcceptedJournal,
    createDateFromJournal,
    createDateUntilJournal,
    receivedsPing
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateArchive(
        companyId,
        searchQueryJournal,
        archiveFromDate,
        archiveToDate,
        journalIdFilter,
        "",
        complete
      )
  }, [
    companyId,
    archivesPing,
    searchQueryJournal,
    archiveFromDate,
    archiveToDate,
    journalIdFilter,
    complete
  ])
  useEffect(() => {
    ( token && companyId && entryId ) &&
      updateEntriesArchive(
        companyId,
        journalId,
        entryId,

        searchQueryJournal,
        measurementIdFilter,
        completeJournal,
        dateFromJournal,
        dateUntilJournal,
        creatorJournal,
        totalAcceptedJournal,
        createDateFromJournal,
        createDateUntilJournal
      )
  }, [
    companyId,
    journalId,
    entryId,
    entriesArchivePing,

    searchQueryJournal,
    measurementIdFilter,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    creatorJournal,
    totalAcceptedJournal,
    createDateFromJournal,
    createDateUntilJournal,
    receivedsPing
  ])

  useEffect(() => {
    false && console.log("updater is ready")
  }, [])
  return <React.Fragment></React.Fragment>
}

export default Updater
