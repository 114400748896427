import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  dataVerifications: [],
  dataTickets: [],
  dataPersonalMetrolog: [],
  dataConditions: [],
  dataComplect: [],
  dataReceived: [],
  dataArchive: [],

  isLoadingVerifications: true,
  isLoadingTickets: true,
  isLoadingPersonalMetrolog: true,
  isLoadingConditions: true,
  isLoadingComplect: true,
  isLoadingReceived: true,
  isLoadingArchive: true,

  selectedVerifications: null,

  metaVerifications: null | undefined,
  metaTickets: null | undefined,
  metaPersonalMetrolog: null | undefined,
  metaConditions: null | undefined,
  metaComplect: null | undefined,
  metaReceived: null | undefined,
  metaArchive: null | undefined,

  journalId: "",
  entryId: "",
  masterArshine: "",
  journalIdFilter: "",
  dateFrom: "",
  dateUntil: "",
  createDateFrom: "",
  createDateUntil: "",
  expiredAt: "",
  searchQuery: "",
  complete: "",
  creator: "",
  totalAccepted: "",
  documents: [],
  archiveFromPerson: "",
  archiveFromDate: "",
  archiveToDate: "",
  viewVariantMain: 0,

  archiveFrom: null,
  archiveTo: null,

  received: {
    measurementId: "",
    receivedDate: "",
    receivedByName: "",
    issuedDate: "",
    customerArshinName: "",
    workContent: "",
    fifNumber: "",
    nameTypeSi: "",
    meterFactoryNumber: "",
    verificationDate: "",
    protocolNumber: "",
    arshinMasterName: "",
    totalAccepted: true,
    arshinId: "",
    issuedByName: "",
  },

  tickets: {
    searchQuery: "",
    measurementId: "",
    arshinName: "",
    createdAtFrom: "",
    createdAtTo: "",
    isChecked: "",
    verificationDateFrom: "",
    verificationDateTo: "",
    completenessStatus: "",
    createdByRole: "",

    measurement: "",
    createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    createdBy: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    isCheckedMark: "",
    arshinFullName: "",
    numberAgreement: "",
    comment: "",
  },

  personal: {
    searchQuery: "",
    measurementId: "",
    createdAtFrom: "",
    createdAtTo: "",
    verificationDateFrom: "",
    verificationDateTo: "",
    completenessStatus: "",
    createdByRole: "",

    measurement: "",
    numSi: "",
    nameSi: "",
    typeSi: "",
    numFactory: "",
    arshinFullName: "",
    verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    numNotification: "",
    numProtocol: "",
  },

  createConditions: {
    temp: "",
    humidity: "",
    pressure: "",
  },

  // ----------------------------------------------------------------
  // для прежних версий журналов - уточнить актуальность
  // ----------------------------------------------------------------

  // ----------------------------------------------------------------
  // createComplect: {
  //   casesId: null,
  //   issuer: null,
  //   receiver: null,
  //   isSueMark: false,
  //   isReturnMark: false,
  //   dateJournal: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  // },
  // createKits: {
  //   meterNameSi: null,
  //   periodicity: "",
  //   verificationAddress: null,
  //   verificationMark: false,
  //   verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  //   nextVerificationDate: "",
  // },
  // ----------------------------------------------------------------
};

export const journalReducer = createSlice({
  name: "journalReducer",
  initialState,
  reducers: {
    setDataVerifications: (state, action) => {
      state.dataVerifications = action.payload;
    },
    setMetaVerifications: (state, action) => {
      state.metaVerifications = action.payload;
    },

    setDataTickets: (state, action) => {
      state.dataTickets = action.payload;
    },
    setMetaTickets: (state, action) => {
      state.metaTickets = action.payload;
    },

    setDataPersonalMetrolog: (state, action) => {
      state.dataPersonalMetrolog = action.payload;
    },
    setMetaPersonalMetrolog: (state, action) => {
      state.metaPersonalMetrolog = action.payload;
    },

    setDataConditions: (state, action) => {
      state.dataConditions = action.payload;
    },
    setMetaConditions: (state, action) => {
      state.metaConditions = action.payload;
    },

    setDataComplect: (state, action) => {
      state.dataComplect = action.payload;
    },
    setMetaComplect: (state, action) => {
      state.metaComplect = action.payload;
    },

    setDataReceived: (state, action) => {
      state.dataReceived = action.payload;
    },
    setMetaReceived: (state, action) => {
      state.metaReceived = action.payload;
    },

    setDataArchive: (state, action) => {
      state.dataArchive = action.payload;
    },
    setMetaArchive: (state, action) => {
      state.metaArchive = action.payload;
    },

    changeReceived: (state, action) => {
      state.received[action.payload.value] = action.payload.label;
    },
    resetReceived: (state) => {
      state.received = initialState.received;
    },

    changeTickets: (state, action) => {
      state.tickets[action.payload.value] = action.payload.label;
    },
    resetTickets: (state) => {
      state.tickets = initialState.tickets;
    },

    changePersonal: (state, action) => {
      state.personal[action.payload.value] = action.payload.label;
    },
    resetPersonal: (state) => {
      state.personal = initialState.personal;
    },

    changeComplect: (state, action) => {
      state.createComplect[action.payload.value] = action.payload.label;
    },
    resetCreateComplect: (state) => {
      state.createComplect = initialState.createComplect;
    },

    changeConditions: (state, action) => {
      state.createConditions[action.payload.value] = action.payload.label;
    },
    resetCreateConditions: (state) => {
      state.createConditions = initialState.createConditions;
    },

    changeKits: (state, action) => {
      state.createKits[action.payload.value] = action.payload.label;
    },
    resetCreateKits: (state) => {
      state.createKits = initialState.createKits;
    },

    setIsLoadingVerifications: (state, action) => {
      state.isLoadingVerifications = action.payload;
    },
    setIsLoadingTickets: (state, action) => {
      state.isLoadingTickets = action.payload;
    },
    setIsLoadingPersonalMetrolog: (state, action) => {
      state.isLoadingPersonalMetrolog = action.payload;
    },
    setIsLoadingConditions: (state, action) => {
      state.isLoadingConditions = action.payload;
    },
    setIsLoadingComplect: (state, action) => {
      state.isLoadingComplect = action.payload;
    },
    setIsLoadingReceived: (state, action) => {
      state.isLoadingReceived = action.payload;
    },
    setIsLoadingArchive: (state, action) => {
      state.isLoadingArchive = action.payload;
    },

    setJournalId: (state, action) => {
      state.journalId = action.payload;
    },
    setMasterArshin: (state, action) => {
      state.masterArshine = action.payload;
    },
    setJournalIdFilter: (state, action) => {
      state.journalIdFilter = action.payload;
    },
    setEntryId: (state, action) => {
      state.entryId = action.payload;
    },

    setDateFrom: (state, action) => {
      state.dateFrom = action.payload;
    },
    setDateUntil: (state, action) => {
      state.dateUntil = action.payload;
    },
    setCreateDateFrom: (state, action) => {
      state.createDateFrom = action.payload;
    },
    setCreateDateUntil: (state, action) => {
      state.createDateUntil = action.payload;
    },
    setViewVariantMain: (state, action) => {
      state.viewVariantMain = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setComplete: (state, action) => {
      state.complete = action.payload;
    },
    setCreator: (state, action) => {
      state.creator = action.payload;
    },
    setTotalAccepted: (state, action) => {
      state.totalAccepted = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setArchiveFromPerson: (state, action) => {
      state.archiveFromPerson = action.payload;
    },
    setArchiveFromDate: (state, action) => {
      state.archiveFromDate = action.payload;
    },
    setArchiveToDate: (state, action) => {
      state.archiveToDate = action.payload;
    },

    setArchiveFrom: (state, action) => {
      state.archiveFrom = action.payload;
    },
    setArchiveTo: (state, action) => {
      state.archiveTo = action.payload;
    },
    resetArchiveFrom: (state) => {
      state.archiveFrom = initialState.archiveFrom;
    },
    resetArchiveTo: (state) => {
      state.archiveTo = initialState.archiveTo;
    },
  },
});

export const {
  changeReceived,
  resetReceived,

  changeTickets,
  resetTickets,

  changePersonal,
  resetPersonal,

  changeConditions,
  resetCreateConditions,

  changeComplect,
  changeKits,

  setDataVerifications,
  setMetaVerifications,

  setDataTickets,
  setMetaTickets,

  setDataPersonalMetrolog,
  setMetaPersonalMetrolog,

  setDataConditions,
  setMetaConditions,

  setDataComplect,
  setMetaComplect,

  setDataReceived,
  setMetaReceived,

  setDataArchive,
  setMetaArchive,

  resetCreateKits,
  resetCreateTickets,
  resetCreateComplect,
  resetArchiveFrom,
  resetArchiveTo,

  setIsLoadingVerifications,
  setIsLoadingTickets,
  setIsLoadingPersonalMetrolog,
  setIsLoadingConditions,
  setIsLoadingComplect,
  setIsLoadingReceived,
  setIsLoadingArchive,

  setJournalId,
  setMasterArshin,
  setJournalIdFilter,
  setEntryId,

  setDateFrom,
  setDateUntil,
  setCreateDateFrom,
  setCreateDateUntil,

  setViewVariantMain,
  setSearchQuery,
  setComplete,
  setCreator,
  setTotalAccepted,
  setDocuments,
  setArchiveFromPerson,
  setArchiveFromDate,
  setArchiveToDate,

  setArchiveFrom,
  setArchiveTo,
} = journalReducer.actions;
export default journalReducer.reducer;
