// ----------------------------------------------------------------
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import BasicTabs from "../../components/LocalElements/TabsMenu/TabsMenuVertical"

import R from "../../../services/app/request.service"
import Feedback from "../../components/LocalElements/Feedback/Feedback"
import { setShow } from "../../../store/slices/app/comps/feedback"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import users from "../../../img/menu/users.svg"

const ShowUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = localStorage.getItem("userRole")

  const { users_id } = useParams()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const [ userData, setUserData ] = useState([])
  const [ viewVariant, setViewVariant ] = useState(0)

  const show = useSelector((state) => state.feedbackReducer.show)

  useEffect(() => {
    ( async () => {
      dispatch(setShow(true))
      try {
        const { data: usersData } = await R.getUsersItem(users_id)
        const data = usersData.data
        setUserData(data)
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [ users_id, dispatch ])

  const navigateToProtocols = () => {
    navigate("/metriva/users")
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      { width > 1050 && <React.Fragment>
        { show ? (
          <Feedback />
        ) : (
          <React.Fragment>
            <div className="path_to_back" onClick={navigateToProtocols} style={{ marginBottom: '50px' }}>
              <i
                className="fas fa-arrow-left icon_back"
                style={{ width: "30px", height: "30px", marginRight: "6px" }}
              />
              <p>Назад к списку пользователей</p>
            </div>

            <div className="box" style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', paddingTop: '60px' }}>

              {/* ---------------------------------------------------------------- */}
              {/* в конечном итоге превратить данный блок в полноценные хлебные крошки */}
              {/* ---------------------------------------------------------------- */}

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  width: '90%',
                  position: 'absolute',
                  top: 0,
                  marginTop: '-40px'
                }}
              > 
                <span
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '80px',
                    height: '80px',
                    zIndex: 2,
                    borderRadius: '50%',
                    backgroundColor: '#e5f3fc',
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                  }}
                >
                  <img 
                    alt=""
                    src={users}
                    style={{
                      width: '50%'
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    backgroundColor: '#e5f3fc',
                    height: '50px',
                    alignItems: 'center',
                    paddingLeft: '30px',
                    paddingRight: '20px',
                    marginLeft: '-14px',
                    borderRadius: '10px',
                    gap: '13px'
                  }}
                >
                  <span style={{ fontSize: '13px' }}>Метрива</span>
                  <span 
                    style={{
                      display: 'block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      backgroundColor: 'gray'
                    }}
                  />
                  <span style={{ fontSize: '13px' }}>Пользователи</span>
                  <span 
                    style={{
                      display: 'block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      backgroundColor: 'gray'
                    }}
                  />
                  <span style={{ fontSize: '13px' }}>{`${userData?.surname} ${userData?.name} ${userData?.patr}`}</span>

                  <div style={{ display: 'flex', marginLeft: '100px', gap: '8px' }}>
                    <span
                      className="fas fa-arrow-left icon_back"
                      onClick={navigateToProtocols}
                    />
                    { userRole !== "brigadier" && <span
                      className="fas fa-pencil-alt icon_back"
                      onClick={() => navigate(`/metriva/users/${users_id}/edit`)}
                    /> }
                  </div>
                </div>
              </div>

              {/* ---------------------------------------------------------------- */}
              {/* ---------------------------------------------------------------- */}

              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '20%', marginRight: '40px', display: 'flex', flexFlow: 'column', gap: '16px' }}>
                  <h2 style={{ width: '100%', textAlign: 'center', lineHeight: '28px' }}>
                    { userRole !== "brigadier" ? `${userData?.surname} ${userData?.name} ${userData?.patr}` : 'Пользователь' }
                  </h2>
                  <BasicTabs 
                    viewVariant={viewVariant}
                    callback={setViewVariant}
                    tabs={[
                      { label: 'Личные данные пользователя' },
                      { label: 'Рабочие данные' }
                    ]}
                  />
                </div>

                { viewVariant === 0 && <div style={{ width: '80%' }}>
                  <div
                    className="flexContainerWithGap"
                    style={{ marginBottom: "15px" }}
                  >
                    <p>Логин пользователя :</p>
                    <h4 style={{ fontWeight: 500 }}>{userData?.login}</h4>
                  </div>

                  <div
                    className="flexContainerWithGap"
                    style={{ marginBottom: "15px" }}
                  >
                    <p>Email пользователя:</p>
                    <h4 style={{ fontWeight: 500 }}>{userData?.email}</h4>
                  </div>

                  <div className="flexContainerWithGap">
                    <p>Название и код региона :</p>
                    <div className="flexContainerWithGap">
                      { userData?.regions?.map((item) => (
                        <h4 style={{ fontWeight: 500 }} key={item.id}>{item.name}</h4>
                      ))}
                    </div>
                  </div>
                </div> }
                { viewVariant === 1 && <div style={{ width: '80%' }}>
                  <div
                    className="flexContainerWithGap"
                    style={{ marginBottom: "15px" }}
                  >
                    <p>Должность пользователя :</p>
                    <h4 style={{ fontWeight: 500 }}>
                      { userData?.role === "brigadier"
                        ? "Руководитель"
                        : userData?.role === "metrologist"
                        ? "Мастер поверитель"
                        : userData?.role === "admin"
                        ? "Администратор"
                        : userData?.role === "super"
                        ? "Супер-админ"
                        : "нет данных"}
                    </h4>
                  </div>

                  {( userData?.role === "brigadier" || userData?.role === "metrologist" ) && (
                    <div
                      className="flexContainerWithGap"
                      style={{ marginBottom: "15px" }}
                    >
                      <p>
                        { userData?.role === "brigadier"
                          ? "Сотрудники в подчинении :"
                          : "Руководитель :"}
                      </p>
                      <h4 style={{ fontWeight: 500 }}>
                        { userData?.role === "brigadier"
                          ? userData?.metrologists
                              .map((metrologist) => metrologist.surname)
                              .join(", ")
                          : `${userData?.brigadier?.surname} ${userData?.brigadier?.name}`}
                      </h4>
                    </div>
                  )}

                  {( userData?.role === "brigadier" || userData?.role === "metrologist" ) && (
                    <div className="flexContainerWithGap">
                      <p>Привязанные комплекты СП :</p>

                      <div className="flexContainerWithGap">
                        { userData?.cases?.map((item) => (
                          <h4 style={{ fontWeight: 500 }} key={item.id}>{item.inner_name}</h4>
                        ))}
                      </div>
                    </div>
                  )}
                </div> }
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment> }
      { width <= 1050 && 
        <span 
          style={{ 
            color: 'gray',
            width: '100%' ,
            textAlign: 'center',
            display: 'block',
            lineHeight: '23px',
            marginTop: '30px'
          }}
        >
          Воспользуйтесь версией для ПК чтобы просмотреть страницу
        </span> 
      }
    </main>
  )
}

export default ShowUsers
