// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import R from "../../services/app/request.service"
import { useDispatch } from "react-redux"
import {
  setList,
  setMeta,
  setIsLoading as setIsLoadingCompanies,
} from "../../store/slices/app/views/companies"
import {
  setList as setListCases,
  setMeta as setMetaCases,
  setIsLoading as setIsLoadingCases,
} from "../../store/slices/app/views/cases"
import {
  setList as setListMasterArshin,
  setMeta as setMetaMasterArshin,
  setIsLoading as setIsLoadingMasterArshin,
} from "../../store/slices/app/views/masterArshin"
import {
  setList as setListMeters,
  setMeta as setMetaMeters,
  setIsLoading as setIsLoadingMeters,
  changeFilterParams,
} from "../../store/slices/app/views/meters"
import {
  setList as setListUsers,
  setMeta as setMetaUsers,
  setIsLoading as setIsLoadingUser,
} from "../../store/slices/app/views/users"
import {
  setList as setListProtocols,
  setMeta as setMetaProtocols,
  setIsLoading as setIsLoadingProtocol,
} from "../../store/slices/app/views/protocols"

import {
  setDataVerifications,
  setMetaVerifications,
  setIsLoadingVerifications,
  setDataTickets,
  setMetaTickets,
  setIsLoadingTickets,
  setDataConditions,
  setMetaConditions,
  setIsLoadingConditions,
  setDataComplect,
  setMetaComplect,
  setIsLoadingComplect,
  setDataReceived,
  setMetaReceived,
  setIsLoadingReceived,
  setDataArchive,
  setMetaArchive,
  setIsLoadingArchive,
} from "../../store/slices/app/views/journals"

import {
  setDataEtalon,
  setMetaEtalon,
  setIsLoading as setIsLoadingEtalon,
} from "../../store/slices/app/views/etalon"

import {
  setLogo,
  setRole,
  setSurName,
  setName,
  setIsTrusted,
  setCompanyId,
} from "../../store/slices/app/controlers/headerData"

const useUpdate = () => {
  const dispatch = useDispatch()

  const updateCompanies = async (searchQuery) => {
    dispatch(setIsLoadingCompanies(true))
    const { status: statusCompanies, data: dataCompanies } =
      await R.getCompanies(`?search=${searchQuery}&limit=10000`)
    dispatch(setIsLoadingCompanies(false))
    dispatch(setList(dataCompanies.data))
    dispatch(setMeta(dataCompanies.meta))

    false && false && console.log(dataCompanies)
  }
  const updateCases = async (searchQuery, companyId) => {
    dispatch(setIsLoadingCases(true))
    const { status: statusCases, data: dataCases } = await R.getCases(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`
    )
    dispatch(setIsLoadingCases(false))
    dispatch(setListCases(dataCases.data))
    dispatch(setMetaCases(dataCases.meta))

    false && false && console.log(dataCases)
  }
  const updateMasterArhin = async (companyId) => {
    dispatch(setIsLoadingMasterArshin(true))
    const { status: statusCases, data: dataMasterArshin } =
      await R.getMasterArshin(`?company_id=${companyId}&limit=10000`)
    dispatch(setIsLoadingMasterArshin(false))
    dispatch(setListMasterArshin(dataMasterArshin.data))
    dispatch(setMetaMasterArshin(dataMasterArshin.meta))

    false && false && console.log(dataMasterArshin)
  }
  const updateVerifications = async (companyId) => {
    dispatch(setIsLoadingVerifications(true))
    const { status: statusVerifications, data: dataVerifications } =
      await R.getVerifications(`?company_id=${companyId}&limit=10000`)
    dispatch(setIsLoadingVerifications(false))
    dispatch(setDataVerifications(dataVerifications.data))
    dispatch(setMetaVerifications(dataVerifications.meta))

    false && false && console.log(dataVerifications)
  }
  const updateTickets = async (
    companyId,
    searchQueryJournal,
    measurementIdTickets,
    arshinNameTickets,
    isCheckedTickets,
    completenessStatusTickets,
    createdByRoleTickets,
    createdAtFromTickets,
    createdAtToTickets,
    verificationDateFrom,
    verificationDateTo
  ) => {
    dispatch(setIsLoadingTickets(true))

    const formattedDateFrom =
      createdAtFromTickets === "Invalid Date" || createdAtFromTickets === ""
        ? ""
        : createdAtFromTickets
    const formattedDateTo =
      createdAtToTickets === "Invalid Date" || createdAtToTickets === ""
        ? ""
        : createdAtToTickets

    const formattedPlanedDateFrom =
      verificationDateFrom === "Invalid Date" || verificationDateFrom === ""
        ? ""
        : verificationDateFrom
    const formattedPlanetDateTo =
      verificationDateTo === "Invalid Date" || verificationDateTo === ""
        ? ""
        : verificationDateTo

    const { status: statusTickets, data: dataTickets } = await R.getTickets(
      companyId,
      `?company_id=${companyId}&search=${searchQueryJournal}&measurement_id=${measurementIdTickets}&arshin_name=${arshinNameTickets}&is_checked=${isCheckedTickets}&completeness_status=${completenessStatusTickets}&created_by_role=${createdByRoleTickets}&created_at_from=${formattedDateFrom}&created_at_to=${formattedDateTo}&planned_date_from=${formattedPlanedDateFrom}&planned_date_to=${formattedPlanetDateTo}&limit=10000`
    )
    dispatch(setIsLoadingTickets(false))
    dispatch(setDataTickets(dataTickets.data))
    dispatch(setMetaTickets(dataTickets.meta))

    console.log(dataTickets)
  }
  const updateConditions = async (
    companyId,
    searchQueryJournal,
    completeJournal,
    dateFromJournal,
    dateUntilJournal
  ) => {
    dispatch(setIsLoadingConditions(true))

    const formattedDateFrom =
      dateFromJournal === "Invalid Date" || dateFromJournal === ""
        ? ""
        : dateFromJournal
    const formattedDateTo =
      dateUntilJournal === "Invalid Date" || dateUntilJournal === ""
        ? ""
        : dateUntilJournal

    const { status: statusConditions, data: dataConditions } =
      await R.getConditions(
        companyId,
        `?company_id=${companyId}&search=${searchQueryJournal}&completeness_status=${completeJournal}&condition_date_from=${formattedDateFrom}&condition_date_to=${formattedDateTo}&limit=10000`
      )
    dispatch(setIsLoadingConditions(false))
    dispatch(setDataConditions(dataConditions.data))
    dispatch(setMetaConditions(dataConditions.meta))

    false && false && console.log(dataConditions)
  }

  const updateComplect = async (
    companyId,
    verifiedAtFormatted,
    expiredAtFormatted
  ) => {
    dispatch(setIsLoadingComplect(true))
    const { status: statusComplect, data: dataComplect } = await R.getComplect(
      `?company_id=${companyId}&issue_at_start=${verifiedAtFormatted}&issue_at_end=${expiredAtFormatted}&limit=10000`
    )
    dispatch(setIsLoadingComplect(false))
    dispatch(setDataComplect(dataComplect.data))
    dispatch(setMetaComplect(dataComplect.meta))

    false && false && console.log(dataComplect)
  }

  const updateReceived = async (
    companyId,
    searchQueryJournal,
    measurementIdFilter,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    creatorJournal,
    totalAcceptedJournal,
    createDateFromJournal,
    createDateUntilJournal
  ) => {
    dispatch(setIsLoadingReceived(true))
    const { status: statusReceived, data: dataReceived } =
      await R.getReceivedJournals(
        companyId,
        `?verification_date_from=${dateFromJournal}&verification_date_to=${dateUntilJournal}&completeness_status=${completeJournal}&created_by_role=${creatorJournal}&total_accepted_status=${totalAcceptedJournal}&arshin_name=&measurment_id=${measurementIdFilter}&search=${searchQueryJournal}&created_at_from=${createDateFromJournal}&created_at_to=${createDateUntilJournal}&limit=10000`
      )
    dispatch(setIsLoadingReceived(false))
    dispatch(setDataReceived(dataReceived.data))
    dispatch(setMetaReceived(dataReceived.meta))

    false && false && console.log(dataReceived)
  }

  const updateArchive = async (
    companyId,
    searchQueryJournal = "",
    archiveFromDate = "",
    archiveToDate ="",
    journalId = "",
    archiveFromPerson = "",
    complete = ""
  ) => {
    dispatch(setIsLoadingReceived(true))
    const { status: statusArchive, data: dataArchive } =
      await R.getArchiveJournals(
        companyId,
        `?search=${searchQueryJournal}&entry_date_from=${archiveFromDate}&entry_date_to=${archiveToDate}&journal_type_id=${journalId}&created_by_name=${archiveFromPerson}&completeness_status=${complete}&limit=10000`
      )
    dispatch(setIsLoadingArchive(false))
    dispatch(setDataArchive(dataArchive.data))
    dispatch(setMetaArchive(dataArchive.meta))

    false && false && console.log(dataArchive)
  }

  const updateEntriesArchive = async (
    companyId,
    journalId,
    entryId,

    searchQueryJournal,
    measurementIdFilter,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    creatorJournal,
    totalAcceptedJournal,
    createDateFromJournal,
    createDateUntilJournal
  ) => {
      if ( journalId === 1 ) {}
      if ( journalId === 2 ) {}
      if ( journalId === 3 ) {
        const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId
        )
      
        console.log(dataEntriesArchive)

        dispatch(setDataReceived(dataEntriesArchive.data))
        dispatch(setMetaReceived(dataEntriesArchive.meta))

        false && false && console.log(dataEntriesArchive)
      }

      if ( journalId === 4 ) {}
      if ( journalId === 5 ) {}
  }

  const updateEtalon = async (searchQuery, companyId) => {
    dispatch(setIsLoadingEtalon(true))
    const { status: statusEtalon, data: dataEtalon } = await R.getEtalon(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`
    )
    dispatch(setIsLoadingEtalon(false))
    dispatch(setDataEtalon(dataEtalon.data))
    dispatch(setMetaEtalon(dataEtalon.meta))

    false && false && console.log(dataEtalon)
  }

  const updateMeters = async (measurement_id, search_query) => {
    dispatch(setIsLoadingMeters(true))
    const { status: statusMeters, data: dataMeters } =
      await R.getMetersListWithParams(measurement_id, search_query)
    dispatch(setIsLoadingMeters(false))
    dispatch(setListMeters(dataMeters.data))
    dispatch(setMetaMeters(dataMeters.meta))

    dispatch(
      changeFilterParams({
        value: "methods",
        label: [{ value: 1, label: "МИ 1592-2015" }],
      })
    )
    dispatch(
      changeFilterParams({
        value: "titles",
        label: [
          ...new Set(
            dataMeters.data.map((meter) => {
              return meter.name_si
            })
          ),
        ].map((item) => ({ value: item, label: item })),
      })
    )
    dispatch(
      changeFilterParams({
        value: "customers",
        label: [
          ...new Set(
            dataMeters.data.map((meter) => {
              return meter.manufacturer.name
            })
          ),
        ].map((item) => ({ value: item, label: item })),
      })
    )

    false && console.log(dataMeters)
  }

  const updateUsers = async (searchQuery, companyId) => {
    dispatch(setIsLoadingUser(true))
    const { status: statusUsers, data: dataUsers } = await R.getUsers(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`
    )
    dispatch(setIsLoadingUser(false))
    dispatch(setListUsers(dataUsers.data))
    dispatch(setMetaUsers(dataUsers.meta))

    false && false && console.log(dataUsers)
  }

  const updateProtocols = async (
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo
  ) => {
    dispatch(setIsLoadingProtocol(true))

    const formattedDateFrom =
      dateFrom === "Invalid Date" || dateFrom === null ? "" : dateFrom
    const formattedDateTo =
      dateTo === "Invalid Date" || dateTo === null ? "" : dateTo

    const { status: statusProtocols, data: dataProtocols } =
      await R.getProtocolsList(
        `?company_id=${companyId}&search=${protocolSearchQuery}&measurement_id=${measurementIdFilter}&created_by=${userId}&total_accepted=${statusId}&status=${approvedTransfer}&status_arshin=${approvedArshin}&status_fsa=${approvedFsa}&verification_date_from=${formattedDateFrom}&verification_date_to=${formattedDateTo}&limit=10000`
      )

    dispatch(setIsLoadingProtocol(false))
    dispatch(setListProtocols(dataProtocols.data))
    dispatch(setMetaProtocols(dataProtocols.meta))
  }

  const updateHeaderData = async () => {
    const { status: statusHeaderData, data: headerData } =
      await R.getHeaderData()

    const logoPath = headerData.data.company.logo
      ? headerData.data.company.logo.path
      : null
    dispatch(setLogo(logoPath))
    dispatch(setRole(headerData.data.role))
    dispatch(setSurName(headerData.data.surname))
    dispatch(setName(headerData.data.name))
    dispatch(setIsTrusted(headerData.data.is_trusted))
    dispatch(setCompanyId(headerData.data.company_id))

    false && false && console.log(headerData)
  }

  return [
    updateCompanies,
    updateCases,
    updateMasterArhin,
    updateVerifications,
    updateTickets,
    updateConditions,
    updateComplect,
    updateReceived,
    updateArchive,
    updateEntriesArchive,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ]
}

export default useUpdate
