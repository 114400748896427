import React, { useState, useEffect } from "react"

const SearchQuery = ({ searchQuery, setSearchQuery, disabled = false }) => {
  const [inputValue, setInputValue] = useState(searchQuery)

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearchQuery(inputValue)
    }, 500)

    return () => clearTimeout(delay)
  }, [inputValue, setSearchQuery])

  const clearSearchQuery = () => {
    setInputValue("")
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="index_search">
        <i className="fas fa-search icon_search" />
        <input
          disabled={disabled}
          placeholder="Поиск по таблице"
          value={inputValue}
          onChange={handleInputChange}
          style={{
            backgroundColor: "transparent",
            marginLeft: "5px",
            width: "95%",
          }}
        />
        { inputValue && false && (
          <button
            className="clear_button"
            onClick={() => {
              clearSearchQuery()
            }}
          >
            <i className="fas fa-times" />
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

export default SearchQuery
