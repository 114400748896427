// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import css from '../styles.css.ts'
import Accordion from '../../app/components/LocalElements/Accordion/Accordion.jsx'
import { Button } from '@mui/material'

import MainJournals from '../comps/MainJ.tsx'
import PersonJournals from '../comps/PersonJ.tsx'

import R from '../../services/app/request.service.js'
import { setJournalSettings } from '../../store/slices/admin/admin.js'

const { Container, ContentRow } = css

const mock = [
  {
    title: 'Демонстрационная заглушка для журнала',
    date: new Date(),
    number: '20-22',
    executor: 'Некоторый ответственный',
  },
  {
    title: 'Демонстрационная заглушка для журнала',
    date: new Date(),
    number: '20-22',
    executor: 'Некоторый ответственный',
  },
]

const MJ = (
  <React.Fragment>
    <MainJournals />
  </React.Fragment>
)
const PJ = (
  <React.Fragment>
    <PersonJournals data={mock} />
  </React.Fragment>
)

const JournalsView = (): ReactElement => {
  const companyId = useSelector((state: any): void => state.companyIdReducer.companyId)

  const dispatch = useDispatch()

  useEffect((): void => {
    ;(async (): Promise<void> => {
      const { data } = await R.getJournalSettings(companyId)

      false && console.log(data)

      dispatch(setJournalSettings({ value: 'mainData', label: data.main }))
    })()
  }, [companyId])

  return (
    <React.Fragment>
      <Container>
        <ContentRow>
          <h2 style={{ marginBottom: '4px' }}>Основные настройки</h2>
        </ContentRow>
        <ContentRow>
          <Accordion
            title={`Общие журналы`}
            titleSize={'17px'}
            backgroundColor={'white'}
            padding={'14px 8px'}
            dataType={'custom'}
            data={MJ}
          />
        </ContentRow>
        <ContentRow>
          <Accordion
            title={`Персональные журналы`}
            titleSize={'17px'}
            backgroundColor={'white'}
            padding={'14px 8px'}
            dataType={'custom'}
            data={PJ}
          />
        </ContentRow>
        <ContentRow axios='end' gap={10}>
          <Button
            disabled={true}
            variant='contained'
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              marginTop: '14px',
              borderRadius: '12px',
            }}
          >
            {'Сохранить изменения'}
          </Button>
          <Button
            disabled={true}
            variant='outlined'
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              marginTop: '14px',
              borderRadius: '12px',
            }}
          >
            {'Отменить изменения'}
          </Button>
        </ContentRow>
      </Container>
      <Container>
        <ContentRow>
          <h2>Специальные настройки</h2>
        </ContentRow>
        <ContentRow></ContentRow>
        <ContentRow></ContentRow>
      </Container>
      <Container>
        <ContentRow>
          <h2>Уведомления</h2>
        </ContentRow>
        <ContentRow></ContentRow>
        <ContentRow></ContentRow>
      </Container>
    </React.Fragment>
  )
}

export default JournalsView
