// ----------------------------------------------------------------
/* eslint-disable no-fallthrough */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import { useSelector, useDispatch } from 'react-redux'
import api from '../../services/app/axios-config.service'
import Cookies from 'js-cookie'
import { saveAs } from 'file-saver'

import { setOpen as setOpenAlert, setMessage, setType } from '../../store/slices/app/comps/snackbar'

const useExcelCsv = () => {
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const journalId = useSelector(state => state.journalReducer.journalId)

  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurementId = useSelector(state => state.journalReducer.tickets.measurementId)
  const arshinName = useSelector(state => state.journalReducer.tickets.arshinName)
  const createdAtFrom = useSelector(state => state.journalReducer.tickets.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.tickets.createdAtTo)
  const isChecked = useSelector(state => state.journalReducer.tickets.isChecked)
  const verificationDateFrom = useSelector(state => state.journalReducer.tickets.verificationDateFrom)
  const verificationDateTo = useSelector(state => state.journalReducer.tickets.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.tickets.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.tickets.createdByRole)

  const measurementIdPersonalMetrolog = useSelector(state => state.journalReducer.personal.measurementId)
  const createdAtFromPersonalMetrolog = useSelector(state => state.journalReducer.personal.createdAtFrom)
  const createdAtToPersonalMetrolog = useSelector(state => state.journalReducer.personal.createdAtTo)
  const verificationDateFromPersonalMetrolog = useSelector(state => state.journalReducer.personal.verificationDateFrom)
  const verificationDateToPersonalMetrolog = useSelector(state => state.journalReducer.personal.verificationDateTo)
  const completenessStatusPersonalMetrolog = useSelector(state => state.journalReducer.personal.completenessStatus)
  const createdByRolePersonalMetrolog = useSelector(state => state.journalReducer.personal.createdByRole)

  const downloadFile = async (type, apiUrl) => {
    try {
      const token = Cookies.get('accessToken')

      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const response = await api.get(apiUrl, {
        headers,
        responseType: 'blob',
      })

      const currentDate = new Date()
        .toLocaleString('ru-RU', {
          timeZone: 'Europe/Moscow',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        .replace(/[.:]/g, '-')

      let filename = `${currentDate}`

      if (type === 'excel') {
        filename += '.xlsx'
      } else if (type === 'csv') {
        filename += '.csv'
      }

      saveAs(new Blob([response.data]), filename)
      dispatch(setMessage('Файл загружается.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
    } catch (error) {
      console.error(error)
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к api.'))
    }
  }

  const getApiUrl = type => {
    let apiUrl = ''
    switch (journalId) {
      case 1:
        apiUrl =
          type === 'excel'
            ? `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/excel?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
            : `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/csv?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
        break
      case 3:
        apiUrl =
          type === 'excel'
            ? `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/excel?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
            : `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/csv?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
        break
      case 2:
        apiUrl =
          type === 'excel'
            ? `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/excel?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
            : `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/conditions-of-cases/csv?company_id=${companyIdSuperAdmin}&completeness_status=${complete}&condition_date_from=${dateFrom}&condition_date_to=${dateUntil}`
        break
      case 4:
        apiUrl =
          type === 'excel'
            ? `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/excel?company_id=${companyIdSuperAdmin}&measurement_id=${measurementId}&arshin_name=${arshinName}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&is_checked=${isChecked}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}`
            : `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/tickets/csv?company_id=${companyIdSuperAdmin}&measurement_id=${measurementId}&arshin_name=${arshinName}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&is_checked=${isChecked}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}`
      case 6:
        apiUrl =
          type === 'excel'
            ? `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${masterArshine}/excel?company_id=${companyIdSuperAdmin}&measurement_id=${measurementIdPersonalMetrolog}&created_at_from=${createdAtFromPersonalMetrolog}&created_at_to=${createdAtToPersonalMetrolog}&verification_date_from=${verificationDateFromPersonalMetrolog}&verification_date_to=${verificationDateToPersonalMetrolog}&completeness_status=${completenessStatusPersonalMetrolog}&created_by_role=${createdByRolePersonalMetrolog}`
            : `${process.env.REACT_APP_API_URL}/api/journals/${companyId}/personal-metrolog/${masterArshine}/csv?company_id=${companyIdSuperAdmin}&measurement_id=${measurementIdPersonalMetrolog}&created_at_from=${createdAtFromPersonalMetrolog}&created_at_to=${createdAtToPersonalMetrolog}&verification_date_from=${verificationDateFromPersonalMetrolog}&verification_date_to=${verificationDateToPersonalMetrolog}&completeness_status=${completenessStatusPersonalMetrolog}&created_by_role=${createdByRolePersonalMetrolog}`
      default:
        break
    }

    return apiUrl
  }

  const handleDownload = type => {
    const apiUrl = getApiUrl(type)
    if (apiUrl) {
      downloadFile(type, apiUrl)
    }
  }

  return { handleDownload }
}

export default useExcelCsv
