import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeOverData } from "../../../store/slices/app/views/companies"
import Tooltip from "@mui/material/Tooltip"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Input from "../LocalElements/Input/InputMUI"
import DatePicker from "../LocalElements/DatePicker/DatePicker"
import AsyncSelector from "../LocalElements/Selector/reactSelect/Selector.async.customer"

import R from "../../../services/app/request.service"
import D from "../../../store/localStorage/dispatcher"

import useWindowDimensions from "../../hooks/useWindowDimensions"

const CompanyBank = ({ validationErrors }) => {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const rs = useSelector((state) => state.companiesReducer.createData.over.rs)
  const ks = useSelector((state) => state.companiesReducer.createData.over.ks)
  const bank = useSelector(
    (state) => state.companiesReducer.createData.over.bank
  )
  const inn = useSelector(
    (state) => state.companiesReducer.createData.over.inn
  )
  const kpp = useSelector(
    (state) => state.companiesReducer.createData.over.kpp
  )
  const ogrn = useSelector(
    (state) => state.companiesReducer.createData.over.ogrn
  )
  const bik = useSelector(
    (state) => state.companiesReducer.createData.over.bik
  )
  const bankAddress = useSelector(
    (state) => state.companiesReducer.createData.over.bankAddress
  )
  const code = useSelector(
    (state) => state.companiesReducer.createData.over.code
  )
  const num = useSelector(
    (state) => state.companiesReducer.createData.over.num
  )
  const orderNum = useSelector(
    (state) => state.companiesReducer.createData.over.orderNum
  )
  const orderDate = useSelector(
    (state) => state.companiesReducer.createData.over.orderDate
  )

  const [regionValue, setRegionValue] = useState(null)

  useEffect(() => {
    ( async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map((regionId) => regionId.id)
      setRegionValue(region)
    })()
  }, [])

  const getValue = (selectedOption) => {
    dispatch(
      changeOverData({
        value: "bankAddress",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const formatInputTextCompany = (inputText) => {
    const words = inputText.split(" ")
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    return capitalizedWords.join(" ")
  }

  const handleNameBankChange = (param) => {
    dispatch(
      changeOverData({
        value: "bank",
        label: formatInputTextCompany(param),
      })
    )
  }

  const handleSignCipherChange = (param) => {
    const limited = param.replace(/[^а-яА-Я\s:"']/g, "")
    const limitedValue = limited.slice(0, 32)
    dispatch(
      changeOverData({
        value: "code",
        label: limitedValue,
      })
    )
  }

  const handleAccreditationUniqueChange = (param) => {
    const limitedValue = param.slice(0, 32)
    dispatch(
      changeOverData({
        value: "num",
        label: limitedValue,
      })
    )
  }

  const handleAgreementChange = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 32)
    dispatch(
      changeOverData({
        value: "orderNum",
        label: limitedValue,
      })
    )
  }

  const handleAgreementDateChange = (date) => {
    if (date) {
      dispatch(
        changeOverData({
          value: "orderDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const changeRS = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 20)
    dispatch(
      changeOverData({
        value: "rs",
        label: limitedValue,
      })
    )
  }

  const changeCS = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 20)
    dispatch(
      changeOverData({
        value: "ks",
        label: limitedValue,
      })
    )
  }

  const changeInn = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 12)
    dispatch(
      changeOverData({
        value: "inn",
        label: limitedValue,
      })
    )
  }

  const changeKpp = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 9)
    dispatch(
      changeOverData({
        value: "kpp",
        label: limitedValue,
      })
    )
  }

  const changeBin = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 15)
    dispatch(
      changeOverData({
        value: "ogrn",
        label: limitedValue,
      })
    )
  }

  const changeBic = (param) => {
    const newValue = param.replace(/\D/g, "")
    const limitedValue = newValue.slice(0, 9)
    dispatch(
      changeOverData({
        value: "bik",
        label: limitedValue,
      })
    )
  }

  return (
    <div className="box">
      <h2 style={{ marginBottom: "25px" }}>Дополнительная информация</h2>

      <div className="flexContainerWithGap" style={ width > 1050 ? { marginBottom: "24px" } : {}}>
        <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={
              <div>
                Расчетный счет банка
                <Tooltip
                  title="Введите 20 цифр счета, который начинается с 40"
                  placement="top"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="fas fa-question-circle question" />
                </Tooltip>
              </div>
            }
            type={"text"}
            placeholder={"00000 000 0 0000 0000000"}
            value={rs}
            actions={{
              change: changeRS,
            }}
          />
          { validationErrors["checking_account"] && (
            <div className="error">{validationErrors["checking_account"]}</div>
          )}
        </div>

        <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={
              <div>
                Корреспондентский счет
                <Tooltip
                  title="Введите 20 цифр счета, который начинается с 301"
                  placement="top"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="fas fa-question-circle question" />
                </Tooltip>
              </div>
            }
            type={"text"}
            placeholder={"00000 000 0 0000 0000000"}
            value={ks}
            actions={{
              change: changeCS,
            }}
          />
          { validationErrors["correspondent_account"] && (
            <div className="error">
              {validationErrors["correspondent_account"]}
            </div>
          )}
        </div>

        <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={"Наименование банка"}
            type={"text"}
            placeholder={"Введите название банка"}
            value={bank}
            actions={{
              change: handleNameBankChange,
            }}
          />
          { validationErrors["bank_name"] && (
            <div className="error">{validationErrors["bank_name"]}</div>
          )}
        </div>
      </div>

      <div className="flexContainerWithGap" style={ width > 1050 ? { marginBottom: "24px" } : {}}>
        <div className="width24Point5Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={"ИНН"}
            type={"text"}
            placeholder={"Введите 10 или 12 цифр ИНН "}
            value={inn}
            actions={{
              change: changeInn,
            }}
          />
          { validationErrors["inn"] && (
            <div className="error">{validationErrors["inn"]}</div>
          )}
        </div>

        <div className="width24Point5Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={"КПП"}
            type={"text"}
            placeholder={"000000000"}
            value={kpp}
            actions={{
              change: changeKpp,
            }}
          />
          { validationErrors["kpp"] && (
            <div className="error">{validationErrors["kpp"]}</div>
          )}
        </div>

        <div className="width24Point5Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={
              <div>
                ОГРН или ОГРНИП
                <Tooltip
                  title="Введите 13 цифр ОГРН или 15 цифр ОГРНИП"
                  placement="top"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="fas fa-question-circle question" />
                </Tooltip>
              </div>
            }
            type={"text"}
            placeholder={"0000000000000"}
            value={ogrn}
            actions={{
              change: changeBin,
            }}
          />
          { validationErrors["bin"] && (
            <div className="error">{validationErrors["bin"]}</div>
          )}
        </div>

        <div className="width24Point5Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={"БИК банка"}
            type={"text"}
            placeholder={"000000000"}
            value={bik}
            actions={{
              change: changeBic,
            }}
          />
          { validationErrors["bic"] && (
            <div className="error">{validationErrors["bic"]}</div>
          )}
        </div>
      </div>

      {/* <p style={{ marginBottom: "9px" }}>Адрес филиала банка</p> */}

      <AsyncSelector
        callback={getValue}
        optionKey={"suggestions"}
        placeholder="Введите адрес вашего филиала банка. Необязательное поле"
        options={async (param) =>
          R.addresses(
            param,
            regionValue.map((regionId) => ({ kladr_id: regionId }))
          )
        }
        value={bankAddress}
      />
      { !bankAddress && validationErrors["bank_address"] && (
        <div className="error">{validationErrors["bank_address"]}</div>
      )}

      <div
        className="flexContainerWithGap"
        style={ width > 1050 ? { marginBottom: "15px", marginTop: "24px" } : { marginTop: "20px" }}
      >
        <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={
              <div>
                Шифр поверителя
                <Tooltip
                  title="Уникальный идентификатор или код, присваиваемый поверительной компании органом по аккредитации"
                  placement="top"
                  style={{ marginLeft: "7px" }}
                >
                  <i className="fas fa-question-circle question" />
                </Tooltip>
              </div>
            }
            type={"text"}
            placeholder={"ААА"}
            value={code}
            actions={{
              change: handleSignCipherChange,
            }}
          />
          { validationErrors["sign_cipher"] && (
            <div className="error">{validationErrors["sign_cipher"]}</div>
          )}
        </div>

        <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
          <Input
            label={"Номер аккредитации"}
            type={"text"}
            placeholder={"RA.RU.00000"}
            value={num}
            actions={{
              change: handleAccreditationUniqueChange,
            }}
          />
          { validationErrors["accreditation_unique_id"] && (
            <div className="error">
              {validationErrors["accreditation_unique_id"]}
            </div>
          )}
        </div>

        { userRole === "super" && (
          <div className="width32Percent" style={ width > 1050 ? {} : { marginBottom: "10px" }}>
            <Input
              label={" Номер договора"}
              type={"text"}
              placeholder={"Введите номер договора (необязательно)"}
              value={orderNum}
              actions={{
                change: handleAgreementChange,
              }}
            />
            { validationErrors["agreement_number"] && (
              <div className="error">
                {validationErrors["agreement_number"]}
              </div>
            )}
          </div>
        )}
      </div>

      { userRole === "super" && (
        <div className="flexContainerWithGap" style={ width > 1050 ? {} : { margin: 0 }}>
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата составления договора{" "}
              { !!!orderDate ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={orderDate}
              dateChange={handleAgreementDateChange}
            />

            { validationErrors["agreement_date"] && (
              <div className="error">{validationErrors["agreement_date"]}</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CompanyBank
