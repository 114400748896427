// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Selector from "../../../LocalElements/Selector/reactSelect/Selector";
import R from "../../../../../services/app/request.service";

import {
  setCompanyId,
  setMeasurementId,
  resetMeasurementId,
  setMethodId,
  resetMethodId,
  changeCase,
  resetCase,
} from "../../../../../store/slices/app/views/protocols";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Method = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const userRole = useSelector((state) => state.headerReducer.role);

  const [companies, setCompanies] = useState([]);
  const [measurement, setMeasurement] = useState([]);
  const [method, setMethod] = useState([]);
  const [cases, setCases] = useState([]);

  const companyId = useSelector((state) => state.protocolReducer.companyId);
  const measurementId = useSelector(
    (state) => state.protocolReducer.measurementId
  );
  const methodId = useSelector((state) => state.protocolReducer.methodId);
  const caseId = useSelector((state) => state.protocolReducer.case.caseId);

  const options = companies
    ? companies.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const getValue = (selectedOption) => {
    dispatch(setCompanyId(selectedOption ? selectedOption.value : ""));
    dispatch(resetMeasurementId());
    dispatch(resetMethodId());
    dispatch(resetCase());
  };

  useEffect(() => {
    if (userRole === "super") {
      (async () => {
        const { data: listCompanies } = await R.getCompaniesList();
        const data = listCompanies.data;
        setCompanies(data);
      })();
    }
  }, [userRole]);

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMeasurement = measurement
    ? measurement.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const getValueMeasurement = (selectedOption) => {
    dispatch(setMeasurementId(selectedOption ? selectedOption.value : ""));
    dispatch(resetMethodId());
    dispatch(resetCase());
  };

  useEffect(() => {
    (async () => {
      const { data: listMeasurement } = await R.getMeasurementItem();
      const measurement = listMeasurement.measurements;
      setMeasurement(measurement);
    })();
  }, []);

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMethod = method
    ? method.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const getValueMethod = (selectedOption) => {
    dispatch(setMethodId(selectedOption ? selectedOption.value : ""));
    dispatch(resetCase());
  };

  useEffect(() => {
    if (measurementId) {
      (async () => {
        const { data: listMethod } = await R.getMethodItem(
          `?company_id=${companyId}&measurement_id=${measurementId}`
        );

        false && console.log(listMethod);

        const method = listMethod.data;
        setMethod(method);
      })();
    }
  }, [companyId, measurementId]);

  useEffect(() => {
    if (method.length === 1) {
      const singleMethod = method[0];
      dispatch(setMethodId(singleMethod.id));
    }
  }, [method, dispatch]);

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsCases = cases
    ? cases.map((item) => ({
        value: item.id,
        label: item.inner_name,
      }))
    : [];

  const getValueCases = (selectedOption) => {
    if (selectedOption) {
      dispatch(changeCase({ value: "caseId", label: selectedOption.value }));

      const selectedComplect = cases.find(
        (item) => item.id === selectedOption.value
      );

      false && console.log(selectedComplect);

      dispatch(
        changeCase({
          value: "pointMethod",
          label: selectedComplect.points.map((point) => ({
            id: point.id,
            name: point.name,
            isOpticalSensor:
              point.isOpticalSensor !== undefined
                ? point.isOpticalSensor
                : null,
          })),
        })
      );

      dispatch(
        changeCase({
          value: "verificationType",
          label: selectedComplect.verification_type.id,
        })
      );
    }
  };

  useEffect(() => {
    if (methodId) {
      (async () => {
        const { data: listCases } = await R.getCasesItem(
          `?company_id=${companyId}&method_id=${methodId}`
        );
        const data = listCases.data;
        setCases(data);
      })();
    }
  }, [companyId, methodId]);

  const noOptionsMessageCases = methodId
    ? "Комплекты СП не найдены"
    : "Сначала выберите методику поверки";

  useEffect(() => {
    if (cases.length === 1) {
      const singleCase = cases[0];

      dispatch(changeCase({ value: "caseId", label: singleCase.id }));

      dispatch(
        changeCase({
          value: "pointMethod",
          label: singleCase.points.map((point) => ({
            id: point.id,
            name: point.name,
            isOpticalSensor:
              point.isOpticalSensor !== undefined
                ? point.isOpticalSensor
                : null,
          })),
        })
      );

      dispatch(
        changeCase({
          value: "verificationType",
          label: singleCase.verification_type.id,
        })
      );
    }
  }, [cases, dispatch]);

  return (
    <div
      className="flexContainerWithGap"
      style={width <= 1050 ? { margin: "0px" } : {}}
    >
      {userRole === "super" && (
        <div className="width24Point5Percent">
          <Selector
            placeholder={"Выберите компанию"}
            options={options}
            onChange={getValue}
            isClearable={false}
            isSearchable={true}
          />
        </div>
      )}

      <div className="width24Point5Percent">
        <Selector
          placeholder={"Область измерения"}
          onChange={getValueMeasurement}
          options={optionsMeasurement}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => "Выберите область измерения"}
          value={
            measurementId
              ? optionsMeasurement.find(
                  (option) => option.value === measurementId
                )
              : null
          }
        />
      </div>

      <div className="width24Point5Percent">
        <Selector
          placeholder={"Методика поверки"}
          onChange={getValueMethod}
          options={optionsMethod}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => "Сначало выберите область измерения"}
          value={
            methodId
              ? optionsMethod.find((option) => option.value === methodId)
              : null
          }
        />
      </div>

      <div className="width24Point5Percent">
        <Selector
          placeholder={"Комплект СП"}
          onChange={getValueCases}
          options={optionsCases}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => noOptionsMessageCases}
          value={
            caseId
              ? optionsCases.find((option) => option.value === caseId)
              : null
          }
        />
      </div>
    </div>
  );
};

export default Method;
