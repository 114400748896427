import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { styled } from "@mui/material"

const CustomTabs = styled(props => (
  <Tabs { ...props } />
))({
  '& .MuiTabs-indicator': {
    borderTopRightRadius: '3px',
    borderTopLeftRadius: '3px',
    height: '3px',
    opacity: 0.88
  },
})

export default function BasicTabs({
  tabs = [{ label: "Кнопки не заданы" }],
  containnerCSS,
  callback = () => {},
  viewVariant,
}) {
  const handleChange = (event, newValue) => {
    false && false && console.log(newValue)
    false && false && console.log(event)

    callback(newValue)
  }

  return (
    <Box
      sx={
        containnerCSS ? { ...containnerCSS, width: "100%" } : { width: "100%" }
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <CustomTabs
          value={viewVariant}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          { tabs &&
            tabs.map((map, index) => {
              return (
                <Tab
                  key={map.label + "*00" + index}
                  label={map.label}
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                  }}
                />
              )
            })}
        </CustomTabs>
      </Box>
    </Box>
  )
}
