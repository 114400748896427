import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  view: "journals",

  journalSettings: {
    mainData: [],
    personalData: [],
    specificData: [],
  }
}

const adminReducer = createSlice({
  name: "adminReducer",
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload
    },

    setJournalSettings: (state, action) => {
      state.journalSettings[action.payload.value] = action.payload.label
    }
  }
})

export const { setView, setJournalSettings } = adminReducer.actions
export default adminReducer.reducer