// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import api from "./axios-config.service"
import Cookies from "js-cookie"
import { saveAs } from "file-saver"
import Tooltip from "@mui/material/Tooltip"
import Excel from "../../img/icon/Excel"
import CsvIcon from "../../img/icon/CsvIcon"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/app/comps/snackbar"

const Csv = () => {
  const dispatch = useDispatch()
  const dateFrom = useSelector((state) => state.protocolReducer.dateFrom)
  const dateTo = useSelector((state) => state.protocolReducer.dateTo)
  const statusProtocol = useSelector((state) => state.protocolReducer.statusId)
  const approvedTransfer = useSelector(
    (state) => state.protocolReducer.approvedTransfer
  )
  const approvedArshin = useSelector(
    (state) => state.protocolReducer.approvedArshin
  )
  const approvedFsa = useSelector((state) => state.protocolReducer.approvedFsa)
  const userIdRedux = useSelector((state) => state.protocolReducer.userId)
  const companyId = useSelector((state) => state.companyIdReducer.companyId)
  const protocolSearchQuery = useSelector(
    (state) => state.protocolReducer.searchQuery
  )

  const downloadCsvAndExcel = async (type) => {
    try {
      const token = Cookies.get("accessToken")

      let apiUrl = `
        ${process.env.REACT_APP_API_URL}/api/protocols/csv?verification_date_from=${dateFrom}&verification_date_to=${dateTo}&search=${protocolSearchQuery}&created_by=${userIdRedux}&total_accepted=${statusProtocol}&status=${approvedTransfer}&status_arshin=${approvedArshin}&status_fsa=${approvedFsa}&company_id=${companyId}
      `
      if (type === "excel") {
        apiUrl = `
          ${process.env.REACT_APP_API_URL}/api/protocols/excel?verification_date_from=${dateFrom}&verification_date_to=${dateTo}&search=${protocolSearchQuery}&created_by=${userIdRedux}&total_accepted=${statusProtocol}&status=${approvedTransfer}&status_arshin=${approvedArshin}&status_fsa=${approvedFsa}&company_id=${companyId}
        `
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }

      const response = await api.get(apiUrl, {
        headers,
        responseType: "blob",
      })

      const currentDate = new Date()
        .toLocaleString("ru-RU", {
          timeZone: "Europe/Moscow",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(/[.:]/g, "-")

      let filename = `${currentDate}`

      if (type === "excel") {
        filename += ".xlsx"
      } else if (type === "csv") {
        filename += ".csv"
      }

      saveAs(new Blob([response.data]), filename)
      dispatch(setMessage("Файл загружается."))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
    } catch (error) {
      console.error(error)
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к api."))
    }
  }

  return (
    <div
      style={{
        width: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Tooltip title="Скачать файл в CSV" placement="top">
        <div
          style={{
            width: "20px",
            cursor: "pointer",
          }}
          onClick={() => downloadCsvAndExcel("csv")}
        >
          <CsvIcon className="excel-icon" style={{ fontSize: "24px" }} />
        </div>
      </Tooltip>
      <Tooltip title="Скачать файл в Excel" placement="top">
        <div
          style={{
            width: "20px",
            cursor: "pointer",
          }}
          onClick={() => downloadCsvAndExcel("excel")}
        >
          <Excel className="excel-icon" style={{ fontSize: "24px" }} />
        </div>
      </Tooltip>
    </div>
  )
}

export default Csv
