import React, { ReactElement } from "react"
import JCR from "./JournalContentRow.tsx"
import css from "../styles.css.ts"

import { IPersonJournalsProps } from "../types.ts"

const { ContentRow } = css

const PersonJournals: React.FC<IPersonJournalsProps> = (props: IPersonJournalsProps): ReactElement => {
  return (
    <React.Fragment>
      <ContentRow gap={20} mb={12}>
        <span style={{ fontWeight: 600, display: 'block', width: '25%' }}>Наименование документа</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Дата создания</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Учетный номер</span>
        <span style={{ fontWeight: 600, display: 'block', width: '30%' }}>Ответственный</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}></span>
      </ContentRow>
      <ContentRow mb={12}>
        <span
          style={{
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '1px',
            backgroundColor: '#d7ddea',
            opacity: 0.8
          }}
        />
      </ContentRow>
      { props.data && props.data.map(row => <JCR 
        name={row.title}
        date={{
          value: row.date,
          action: (): void => {}
        }}
        number={row.number}
        executor={row.executor}
        executorControllable={false}
        actions={false}
        gap={20}
        mb={12}
      />) }
    </React.Fragment>
  )
}

export default PersonJournals