import React, { useState, useEffect } from "react";
import Selector from "../Selector/reactSelect/Selector";
import { useSelector } from "react-redux";
import R from "../../../../services/app/request.service";

const MasterArshin = ({ masterId, setMasterId, label }) => {
  const [data, setData] = useState([]);

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: `${data.surname} ${data.name} ${data.patr}`,
      }))
    : [];

  const getValue = (selectedOption) => {
    setMasterId(selectedOption ? selectedOption.value : "");
  };

  useEffect(() => {
    (async () => {
      const { data: listMaster } = await R.getMasterItem();
      const master = listMaster.data;
      setData(master);
    })();
  }, []);

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите официального пользователя комплекта СП"}
        options={options}
        onChange={getValue}
        isClearable={true}
        isSearchable={true}
        value={
          masterId ? options.find((option) => option.value === masterId) : null
        }
        noOptionsMessage={() => "Нет доступных пользователей"}
        label={label}
      />
    </React.Fragment>
  );
};

export default MasterArshin;
