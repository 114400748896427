import React, { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JCR from './JournalContentRow.tsx'
import css from '../styles.css.ts'

const { ContentRow } = css

const MainJournals: React.FC = (): ReactElement => {
  const journalsData = useSelector((state: any) => state.adminReducer.journalSettings.mainData)

  useEffect(() => {
    console.log(journalsData)
  }, [journalsData])

  return (
    <React.Fragment>
      <ContentRow gap={20} mb={12}>
        <span style={{ fontWeight: 600, display: 'block', width: '25%' }}>
          Наименование документа
        </span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Дата создания</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Учетный номер</span>
        <span style={{ fontWeight: 600, display: 'block', width: '30%' }}>Ответственный</span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}>Автозаполнение</span>
      </ContentRow>
      <ContentRow mb={12}>
        <span
          style={{
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '1px',
            backgroundColor: '#d7ddea',
            opacity: 0.8,
          }}
        />
      </ContentRow>

      {journalsData &&
        journalsData.map(
          (
            journalItem: {
              account_number: string | null
              create_date: string
              is_autogenerate: boolean
              is_autogenerate_required: boolean
              journal_name: string
              journal_type_id: number
              responsible_name: string | null
            },
            index: number,
          ) => {
            const localIndex = index

            return (
              <JCR
                context='main'
                contextIndex={localIndex}
                name={journalItem.journal_name}
                date={{
                  value: journalItem.create_date,
                  action: (date: { format: (arg0: string) => any }): void => {
                    console.log(date.format('YYYY-MM-DD'))
                  },
                }}
                number={journalItem.account_number ?? 'Нет данных'}
                executor={journalItem.responsible_name || 'Нет данных'}
                gap={20}
                mb={12}
              />
            )
          },
        )}
    </React.Fragment>
  )
}

export default MainJournals
