// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'

import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import api from '../../../services/app/axios-config.service'
import Checkbox from '@mui/material/Checkbox'
import Selector from '../LocalElements/Selector/reactSelect/Selector'

import R from '../../../services/app/request.service'
import { changeData } from '../../../store/slices/app/views/cases'

const Kits = () => {
  const token = useSelector(state => state.authReducer.authData.token)
  const dispatch = useDispatch()
  const [kits, setKits] = useState([])

  const deviceIds = useSelector(state => state.casesReducer.createData.deviceIds)

  const [selectedKits, setSelectedKits] = useState({})
  const [checkboxState, setCheckboxState] = useState({})

  const companyId = useSelector(state => state.casesReducer.createData.companyId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)

  useEffect(() => {
    if (methodPointIds.length === 0) {
      return
    }

    const fetchKits = async () => {
      try {
        const uniquePointIds = [...new Set(methodPointIds)]
        const pointsIds = uniquePointIds.map(id => `points[]=${id}`).join('&')
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/devices?verification_type_id=${typeMethodId}&${pointsIds}`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const kits = response.data.data
        setKits(kits)

        if (deviceIds.length === 0) {
          const initialCheckboxState = {}
          kits.forEach(kit => {
            initialCheckboxState[kit.id] = kit.is_send_to_arshin
          })
          setCheckboxState(initialCheckboxState)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchKits()
  }, [typeMethodId, methodPointIds, token])

  const [data, setData] = useState([])
  const optionsByKitId = {}

  kits.forEach(kit => {
    optionsByKitId[kit.id] = data
      ? data
          .filter(item => item.device_id === kit.id)
          .map(data => ({
            value: data.id,
            label: data.device_manufacturer_number,
          }))
      : []
  })

  const getValue = (selectedOption, kitId) => {
    setSelectedKits(prevSelectedKits => ({
      ...prevSelectedKits,
      [kitId]: selectedOption ? selectedOption.value : null,
    }))
  }

  const handleCheckboxChange = (event, kitId) => {
    const { checked } = event.target
    setCheckboxState(prevState => ({
      ...prevState,
      [kitId]: checked,
    }))
  }

  useEffect(() => {
    const initialSelectedKits = {}
    deviceIds.forEach(device => {
      initialSelectedKits[device.id] = device.kit_id
    })
    setSelectedKits(initialSelectedKits)
  }, [deviceIds])

  useEffect(() => {
    const initialIsRequiredArshin = {}
    deviceIds.forEach(device => {
      initialIsRequiredArshin[device.id] = device.requiredArshin
    })

    setCheckboxState(initialIsRequiredArshin)
  }, [deviceIds])

  useEffect(() => {
    const kitIdsArray = kits.reduce((accumulator, kit) => {
      const id = selectedKits[kit.id]
      const is_send_to_arshin = checkboxState[kit.id]
      if (id !== undefined && id !== null) {
        accumulator.push({
          id: id,
          is_send_to_arshin: is_send_to_arshin || false,
        })
      }
      return accumulator
    }, [])

    dispatch(changeData({ value: 'kitIds', label: kitIdsArray }))
  }, [selectedKits, checkboxState, kits, dispatch])

  useEffect(() => {
    ;(async () => {
      const { data: listKits } = await R.getEtalonList(`?company_id=${companyId}`)
      const data = listKits.data
      setData(data)
    })()
  }, [companyId])

  return (
    <React.Fragment>
      {kits.map((kit, index) => (
        <div
          className='flexContainerWithGap'
          key={kit.id}
          style={{
            marginBottom: index !== kits.length - 1 ? '14px' : '0px',
            borderRadius: '12px',
            background:
              'linear-gradient(90deg, rgba(248,249,252,1) 0%, rgba(248,249,252,1) 66%, rgba(248,249,252,0) 100%)',
            padding: '20px',
            paddingRight: '0px',
          }}
        >
          <h3 style={{ width: '33.8%', display: 'flex', alignItems: 'center' }}>
            {kit.name}{' '}
            {!selectedKits[kit.id] ? (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '1px',
                }}
              />
            ) : (
              <CheckCircleIcon
                sx={{
                  color: '#63E6BE',
                  marginLeft: '8px',
                  marginTop: '1px',
                }}
              />
            )}
          </h3>

          <div
            style={{
              width: '68.2%',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Tooltip title='Подтверждение отправки эталона в Аршин' placement='top-start'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  disabled={kit.is_send_to_arshin}
                  checked={checkboxState[kit.id] || false}
                  onChange={event => handleCheckboxChange(event, kit.id)}
                />
              </div>
            </Tooltip>
            <div className='width50Percent' style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите эталон из списка'}
                options={optionsByKitId[kit.id]}
                isClearable={true}
                isSearchable={false}
                noOptionsMessage={() => 'Нет доступных эталонов'}
                value={
                  selectedKits[kit.id]
                    ? optionsByKitId[kit.id].find(option => option.value === selectedKits[kit.id])
                    : null
                }
                onChange={selectedOption => getValue(selectedOption, kit.id)}
              />
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default Kits
