// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Input from '../../LocalElements/Input/InputMUI'
import Feedback from '../../LocalElements/Feedback/Feedback'
import Approved from '../../LocalElements/Filter/Approved'
import R from '../../../../services/app/request.service'
import { changePersonal, resetPersonal } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshPersonalMetrolog } from '../../../../store/slices/app/controlers/updater'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurement = useSelector(state => state.journalReducer.personal.measurement)
  const numSi = useSelector(state => state.journalReducer.personal.numSi)
  const nameSi = useSelector(state => state.journalReducer.personal.nameSi)
  const typeSi = useSelector(state => state.journalReducer.personal.typeSi)
  const numFactory = useSelector(state => state.journalReducer.personal.numFactory)
  const arshinFullName = useSelector(state => state.journalReducer.personal.arshinFullName)
  const verificationDate = useSelector(state => state.journalReducer.personal.verificationDate)
  const numNotification = useSelector(state => state.journalReducer.personal.numNotification)
  const numProtocol = useSelector(state => state.journalReducer.personal.numProtocol)

  const changeMeasurement = param => {
    dispatch(changePersonal({ value: 'measurement', label: param }))
  }

  const changeNumSi = param => {
    const validInput = param.replace(/[^0-9\- ]/g, '')
    dispatch(changePersonal({ value: 'numSi', label: validInput }))
  }

  const changeNameSi = param => {
    dispatch(changePersonal({ value: 'nameSi', label: param }))
  }

  const changeIsNameSi = event => {
    dispatch(changePersonal({ value: 'nameSi', label: event.target.value }))
  }

  const changeTypeSi = param => {
    dispatch(changePersonal({ value: 'typeSi', label: param }))
  }

  const changeNumFactory = param => {
    dispatch(changePersonal({ value: 'numFactory', label: param }))
  }

  const handleArshinFullName = param => {
    dispatch(changePersonal({ value: 'arshinFullName', label: param }))
  }

  const handleNumNotification = param => {
    dispatch(changePersonal({ value: 'numNotification', label: param }))
  }

  const handleNumProtocol = param => {
    dispatch(changePersonal({ value: 'numProtocol', label: param }))
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDate', label: '' }))
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data: personalData } = await R.getPersonalMetrologItem(
          journal_id,
          companyId,
          masterArshine,
        )
        const data = personalData.data

        dispatch(
          changePersonal({
            value: 'measurement',
            label: data?.measurement_id,
          }),
        )
        dispatch(
          changePersonal({
            value: 'numSi',
            label: data?.fif_number,
          }),
        )

        dispatch(
          changePersonal({
            value: 'nameSi',
            label: data?.customer_arshin_name,
          }),
        )

        dispatch(
          changePersonal({
            value: 'typeSi',
            label: data?.name_type_si,
          }),
        )

        dispatch(
          changePersonal({
            value: 'numFactory',
            label: data?.meter_factory_number,
          }),
        )

        dispatch(
          changePersonal({
            value: 'arshinFullName',
            label: data?.arshin_master_name,
          }),
        )

        dispatch(
          changePersonal({
            value: 'numNotification',
            label: data?.arshin_id,
          }),
        )

        dispatch(
          changePersonal({
            value: 'numProtocol',
            label: data?.protocol_number,
          }),
        )

        dispatch(
          changePersonal({
            value: 'verificationDate',
            label: data?.verification_date,
          }),
        )
      } catch (error) {
        console.error(error)
      }
    })()
  }, [journal_id, companyId, masterArshine, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetPersonal())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurement,
      arshin_master_name: arshinFullName,
      fif_number: numSi,
      customer_arshin_name: nameSi,
      name_type_si: typeSi,
      meter_factory_number: numFactory,
      verification_date: verificationDate,
      arshin_id: numNotification,
      protocol_number: numProtocol,
    }

    const { status } = await R.putPersonalMetrolog(journal_id, companyId, masterArshine, data)

    if (status === 200) {
      dispatch(setMessage('Журнал изменен.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshPersonalMetrolog())
      dispatch(resetPersonal())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Журнал не изменен. - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className='path_to_back' onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <p>Все документы</p>
          </div>

          <div className='path_to_edit'>
            <h4>Персональный журнал инженера по метрологии</h4>
          </div>

          <div className='box'>
            <h2 style={{ marginBottom: '25px' }}>Информация о журнале</h2>
            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div className='width32Percent'>
                <div style={{ width: '100%' }}>
                  <Measurement
                    measurementId={measurement}
                    setMeasurementId={changeMeasurement}
                    label={{
                      text: 'Область измерения',
                      topBg: 'white',
                      bottomBg: 'white',
                    }}
                  />
                </div>
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Номер СИ в госреестре'}
                  placeholder={'Только цифры и символы'}
                  type={'text'}
                  value={numSi}
                  actions={{
                    change: changeNumSi,
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '25px' }}>
              <div
                className='width32Percent'
                style={{ gap: '8px', display: 'flex', flexFlow: 'column' }}
              >
                <Input
                  label={'Наименование владельца СИ'}
                  placeholder={'Наименование владельца СИ'}
                  type={'text'}
                  value={nameSi}
                  actions={{
                    change: changeNameSi,
                  }}
                />
                <Approved
                  approved={nameSi}
                  changeApproved={changeIsNameSi}
                  labelTrue={'Физическое лицо'}
                  labelFalse={'Юридическое лицо'}
                  valueTrue={'Физическое лицо'}
                  valueFalse={'Юридическое лицо'}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Наименование, тип СИ'}
                  placeholder={'Наименование, тип СИ'}
                  type={'text'}
                  value={typeSi}
                  actions={{
                    change: changeTypeSi,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'Заводской номер'}
                  placeholder={'Заводской номер средства измерения'}
                  type={'text'}
                  value={numFactory}
                  actions={{
                    change: changeNumFactory,
                  }}
                />
              </div>
            </div>

            <div className='flexContainerWithGap' style={{ marginBottom: '14px' }}>
              <div className='width32Percent'>
                <Input
                  label={'Ф.И.О инженера по метрологии'}
                  placeholder={'Ф.И.О инженера по метрологии'}
                  type={'text'}
                  value={arshinFullName}
                  actions={{
                    change: handleArshinFullName,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'№ свидетель-ства / извещения'}
                  placeholder={'№ свидетель-ства / извещения'}
                  type={'text'}
                  value={numNotification}
                  actions={{
                    change: handleNumNotification,
                  }}
                />
              </div>

              <div className='width32Percent'>
                <Input
                  label={'№ протокола поверки'}
                  placeholder={'№ протокола поверки'}
                  type={'text'}
                  value={numProtocol}
                  actions={{
                    change: handleNumProtocol,
                  }}
                />
              </div>
            </div>

            <div className='width32Percent'>
              <p
                style={{
                  marginBottom: '9px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Дата поверки
                {!!verificationDate ? (
                  <CheckCircleIcon
                    sx={{
                      color: '#63E6BE',
                      marginLeft: '8px',
                      marginTop: '-2px',
                    }}
                  />
                ) : (
                  <ErrorIcon
                    sx={{
                      color: '#FFD43B',
                      marginLeft: '8px',
                      marginTop: '-1px',
                    }}
                  />
                )}
              </p>
              <DatePicker
                selectedDate={verificationDate}
                dateChange={handleVerificationDateChange}
              />
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Редактировать запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default Edit
