// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import moment from 'moment'
import Table from '../../LocalElements/Table/Table'
import Skeleton from '../../LocalElements/Skeleton/Skeleton'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Approved from '../../LocalElements/Filter/Approved'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import useExcelCsv from '../../../hooks/useExcelCsv'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import R from '../../../../services/app/request.service'
import { refreshConditions, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import {
  setSearchQuery,
  setComplete,
  setDateFrom,
  setDateUntil,
  setArchiveFrom,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'

import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const TableConditions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { handleDownload } = useExcelCsv()
  const userRole = useSelector(state => state.headerReducer.role)
  const isLoading = useSelector(state => state.journalReducer.isLoadingConditions)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const data = useSelector(state => state.journalReducer.dataConditions)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)

  const entryId = useSelector(state => state.journalReducer.entryId)
  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (dateFrom) filtersCount++
    if (dateUntil) filtersCount++

    if (!complete && !dateFrom && !dateUntil) filtersCount = 0
    return filtersCount
  }, [complete, dateFrom, dateUntil])

  const resetActions = [setComplete, setDateFrom, setDateUntil]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeComplete = event => {
    dispatch(setComplete(event.target.value))
  }

  const handleDateFromChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(''))
    }
  }

  const handleDateUntilChange = date => {
    if (date) {
      dispatch(dateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(dateUntil(''))
    }
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_conditions-of-cases`)
  }

  const archiveMessage = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
        некоторых записях документа
      </span>
      <span style={{ lineHeight: '24px' }}>
        Пожалуйста, найдите подобные записи с помощью фильтра{' '}
        <span style={{ fontWeight: 'bold' }}>"Заполнение записи"</span>, дождитесь появления /
        внесите недостающие данные и повторите процесс архивации.
      </span>
    </section>
  )

  const archiveTitle = <span style={{ color: 'red' }}>Архивация приостановлена</span>

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveConditionsJournal(companyId, {
      date_from: archiveDateFrom,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(false))
      dispatch(refreshConditions())
      dispatch(refreshArchives())
      dispatch(resetState())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpenModal(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    }
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getConditionsPdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'cond.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getConditionsCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'cond.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getConditionsExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'cond.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  useEffect(() => {
    if (archiveDateFrom && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFrom, archiveDateTo])

  let colData = [
    { field: 'conditionDate', headerName: 'Дата', flex: 1 },
    { field: 'temp', headerName: 'Температура, ˚С', flex: 1 },
    { field: 'humidity', headerName: 'Влажность, %', flex: 1 },
    { field: 'pressure', headerName: 'Давление, mm Hg', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      flex: 1,
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              gap: '14px',
            }}
          >
            <Tooltip title='Редактировать запись' placement='top-end'>
              <span
                onClick={() => handleEditClick(params.row.id)}
                className='action-container-block'
              >
                <span
                  className='fas fa-pencil-alt icon'
                  style={{
                    fontSize: '15px',
                    marginTop: '1px',
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    },
  ]

  const modalContent = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <span style={{ lineHeight: '24px' }}>
        Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа
        будет доступна только в режиме просмотра.
      </span>
      <span style={{ lineHeight: '24px' }}>
        Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
      </span>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>С какого</span>
          <DatePicker
            selectedDate={archiveDateFrom}
            dateChange={date => {
              date
                ? dispatch(setArchiveFrom(date.format('YYYY-MM-DD')))
                : dispatch(setArchiveFrom(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <span style={{ fontWeight: 500 }}>По какое (включительно)</span>
          <DatePicker
            selectedDate={archiveDateTo}
            dateChange={date => {
              date
                ? dispatch(setArchiveTo(date.format('YYYY-MM-DD')))
                : dispatch(setArchiveTo(null))
            }}
            maxDate={dayjs().subtract(1, 'day')}
            disableFuture={true}
          />
        </div>
      </div>
    </section>
  )

  return (
    <main>
      <RightSideContainer blockTitle={'Фильтры'}>
        <div className='right-side-container'>
          <div className='right-side-container-block'>
            <span className='label-filter'>Дата регистрации</span>
            <DatePicker selectedDate={dateFrom} dateChange={handleDateFromChange} />

            <DatePicker selectedDate={dateUntil} dateChange={handleDateUntilChange} />
          </div>

          <div className='right-side-container-block'>
            <span className='label-filter'>Заполнение записи</span>
            <Approved
              approved={complete}
              changeApproved={changeComplete}
              labelTrue={'Частичное'}
              labelFalse={'Полное'}
              valueTrue={'non-complete'}
              valueFalse={'complete'}
            />
          </div>

          <Button
            variant='outlined'
            onClick={handleResetFilter}
            style={{
              width: '100%',
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </RightSideContainer>

      <div
        className='header_box'
        style={{
          marginTop: '12px',
          position: 'relative',
          paddingRight: count > 0 ? '50px' : '0px',
        }}
      >
        <div style={{ width: width > 1050 ? '80%' : '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
        </div>

        <div className='flexContainerWithGap' style={{ gap: '12px' }}>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры' placement='top'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                <Pdf />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Csv' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
                <Csv />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <Tooltip title='Скачать документ в Excel' placement='top'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
                <Excel />
              </div>
            </Tooltip>
          )}
          {!!!entryId && (
            <React.Fragment>
              {count > 0 ? (
                <Tooltip title='Для архивации документа отключите фильтры' placement='top'>
                  <div style={{ cursor: 'default', opacity: '40%' }}>
                    <ArchiveIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Архивировать документ' placement='top'>
                  <div style={{ cursor: 'pointer' }}>
                    <ArchiveIcon
                      onClick={() => {
                        dispatch(setTitle('Архивация документа'))
                        dispatch(setChildren(modalContent))
                        dispatch(setBA('Архивировать'))
                        dispatch(setConfirmAction(handleArchiveClick))
                        dispatch(setOpenModal(true))
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          style={{
            position: 'relative',
            width: width > 1050 ? '220px' : '100%',
          }}
        >
          <Button
            disabled={!!!entryId ? false : true}
            variant='outlined'
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterAltIcon />}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              borderRadius: '12px',
              width: '100%',
              height: '54.4px',
            }}
          >
            Фильтры
          </Button>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '22px',
              height: '22px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: 'white',
              fontSize: '11px',
              lineHeight: '21px',
              textAlign: 'center',
              top: 0,
              left: '100%',
              marginLeft: '-18px',
              marginTop: '-4px',
              transition: 'all 300ms',
              opacity: count > 0 ? 1 : 0,
            }}
          >
            {count}
          </span>
        </div>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </div>

      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            '& .MuiDataGrid-cell:hover': {
              cursor: 'pointer',
            },
          }}
          colData={colData}
          rowData={data.map(item => ({
            id: item.id,
            conditionDate: moment(item.condition_date).format('DD-MM-YYYY'),
            temp: item.temperature_environment,
            humidity: item.relative_humidity,
            pressure: item.atmospheric_pressure,
          }))}
          rowCallback={handleEditClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default TableConditions
