// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import Input from "../../LocalElements/Input/InputMUI";
import Feedback from "../../LocalElements/Feedback/Feedback";
import { setShow } from "../../../../store/slices/app/comps/feedback";
import R from "../../../../services/app/request.service";
import {
  changeConditions,
  resetCreateConditions,
} from "../../../../store/slices/app/views/journals";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../../store/slices/app/comps/snackbar";
import { refreshConditions } from "../../../../store/slices/app/controlers/updater";

const Edit = () => {
  const { journal_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.headerReducer.role);
  const show = useSelector((state) => state.feedbackReducer.show);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const companyIdSuperAdmin = useSelector(
    (state) => state.companyIdReducer.companyId
  );
  const companyIdUser = useSelector((state) => state.headerReducer.companyId);
  const companyId = userRole === "super" ? companyIdSuperAdmin : companyIdUser;

  const temp = useSelector(
    (state) => state.journalReducer.createConditions.temp
  );
  const humidity = useSelector(
    (state) => state.journalReducer.createConditions.humidity
  );
  const pressure = useSelector(
    (state) => state.journalReducer.createConditions.pressure
  );

  const handleTempChange = (param) => {
    dispatch(changeConditions({ value: "temp", label: param }));
  };
  const handleHumidityChange = (param) => {
    dispatch(changeConditions({ value: "humidity", label: param }));
  };
  const handlePressureChange = (param) => {
    dispatch(changeConditions({ value: "pressure", label: param }));
  };

  useEffect(() => {
    (async () => {
      dispatch(setShow(true));
      try {
        const { data: conditionsItemData } = await R.getConditionsItem(
          journal_id,
          companyId
        );
        const data = conditionsItemData.data;

        dispatch(
          changeConditions({
            value: "temp",
            label: data.temperature_environment,
          })
        );

        dispatch(
          changeConditions({
            value: "humidity",
            label: data.relative_humidity,
          })
        );

        dispatch(
          changeConditions({
            value: "pressure",
            label: data.atmospheric_pressure,
          })
        );
      } catch (error) {
        console.error(error);
      }
      dispatch(setShow(false));
    })();
  }, [journal_id, companyId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateConditions());
    };
  }, []);

  const handleSave = async () => {
    setIsDisabledBtn(true);

    const data = {
      temperature_environment: temp,
      relative_humidity: humidity,
      atmospheric_pressure: pressure,
    };

    if (userRole === "super") {
      data.company_id = companyId;
    }

    const { status, data: conditionsAddData } = await R.editConditions(
      journal_id,
      companyId,
      data
    );

    if (status === 200) {
      dispatch(setMessage("Запись успешно отредактирована"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshConditions());
      navigate("/metriva/journals");
    } else {
      if (conditionsAddData.errors) {
        const errors = conditionsAddData.errors;
      }
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Запись не отредактирована - ошибка валидации"));
      setIsDisabledBtn(false);
    }
  };

  const navigateToProtocols = () => {
    navigate("/metriva/journals");
  };

  return (
    <main className="main">
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все документы</p>
          </div>

          <div className="path_to_edit">
            <h4>Журнал регистрации условий хранения средств поверки</h4>
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "25px" }}>Информация о журнале</h2>
            <div className="flexContainerWithGap">
              <div className="width32Percent">
                <Input
                  label={"Температура, ˚С"}
                  type={"text"}
                  placeholder={"20"}
                  value={temp || ""}
                  actions={{
                    change: handleTempChange,
                  }}
                />
              </div>

              <div className="width32Percent">
                <Input
                  label={"Влажность, %"}
                  type={"text"}
                  placeholder={"50"}
                  value={humidity || ""}
                  actions={{
                    change: handleHumidityChange,
                  }}
                />
              </div>

              <div className="width32Percent">
                <Input
                  label={"Давление, mm Hg"}
                  type={"text"}
                  placeholder={"90"}
                  value={pressure || ""}
                  actions={{
                    change: handlePressureChange,
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "14px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Редактировать запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

export default Edit;
