// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'

import SelectJournal from '../../components/Journals/Select/SelectJournal'
import MasterArshinSelect from '../../components/LocalElements/Filter/MasterArshinSelect'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import { setName } from '../../../store/slices/app/controlers/pageName'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import TableConditions from '../../components/Journals/JournalConditionsCases/Table'
import TableReceived from '../../components/Journals/JournalReceivedForVerification/Table'
import TableTickets from '../../components/Journals/JournalTickets/Table'
import TablePersonalMetrolog from '../../components/Journals/JournalPersonal/Table'

import { setMasterArshin } from '../../../store/slices/app/views/journals'

const MagazinesIndex = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const journalId = useSelector(state => state.journalReducer.journalId)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const changeMasterId = param => {
    dispatch(setMasterArshin(param))
  }

  const changeViewVariant = param => {
    param === 0 && navigate('/metriva/journals')
    param === 1 && navigate('/metriva/settings/journals')
    param === 2 && navigate('archive')
  }

  useEffect(() => {
    dispatch(setName('История журналов'))
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
            marginBottom: '10px',
          }}
        >
          <FilterCompany />
        </div>
      )}

      {(userRole === 'brigadier' || userRole === 'metrologist') && (
        <div className='selector' style={{ width: '50%' }}>
          <SelectJournal />
        </div>
      )}

      <div style={{ margin: '0px 0 20px' }}>
        <BasicTabs
          viewVariant={0}
          callback={changeViewVariant}
          tabs={
            userRole === 'super' || userRole === 'admin'
              ? [{ label: 'Актуальная версия документа' }, { label: 'Настройки' }, { label: 'Архивные' }]
              : [{ label: 'Актуальная версия документа' }, { label: 'Настройки' }]
          }
        />
      </div>

      <div className='header_box'>
        {(userRole === 'super' || userRole === 'admin') && (
          <div className='selector' style={{ width: '50%' }}>
            <SelectJournal />
          </div>
        )}

        {journalId === 6 && (
          <div className='selector' style={{ width: '50%' }}>
            <MasterArshinSelect
              masterId={masterArshine}
              setMasterId={changeMasterId}
              label={{
                text: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Ответственный за документ
                    <Tooltip
                      title='Владелец комплекта СП, чьи данные передаются во ФГИС «Аршин» и ФСА'
                      placement='top'
                      style={{ marginLeft: '7px' }}
                    >
                      <i className='fas fa-question-circle question' style={{ marginLeft: '7px', cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                ),
                topBg: '#f8f9fc',
                bottomBg: 'white',
              }}
            />
          </div>
        )}
      </div>

      {(!!!journalId || !!!companyId) && (
        <div className='box' style={{ marginTop: '12px' }}>
          <div className='page_null'>
            <h4 className='context-null-table'>Для просмотра записей выберите компанию и документ</h4>
          </div>
        </div>
      )}

      {companyId && (
        <React.Fragment>
          {journalId === 2 && <TableConditions />}
          {journalId === 3 && <TableReceived />}
          {journalId === 4 && <TableTickets />}
          {journalId === 5 && (
            <div className='box' style={{ marginTop: '12px' }}>
              <div className='page_null'>
                <h4 className='context-null-table'>Данный функционал находится в разработке</h4>
              </div>
            </div>
          )}
          {journalId === 6 && <TablePersonalMetrolog />}
        </React.Fragment>
      )}
    </main>
  )
}

export default MagazinesIndex
