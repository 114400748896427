// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import Logo from "../../../img/metriva-logo.svg"
import { useNavigate } from "react-router-dom"

import Cookies from "js-cookie"
import Input from "../../components/LocalElements/Input/InputMUI"
import Button from "@mui/material/Button"
import Feedback from "../../components/LocalElements/Feedback/Feedback"
import PositionedSnackbar from "../../components/LocalElements/Snackbar/Snackbar"

import { setAuthLogin, 
  setAuthPass, 
  setAuthToken } from "../../../store/slices/app/controlers/auth"
import { setShow } from "../../../store/slices/app/comps/feedback"
import { setCompanyId } from "../../../store/slices/app/controlers/companyId"
import { setCompanyId as setCompanyIdHeader } from "../../../store/slices/app/controlers/headerData"
import { setOpen, setType, setMessage } from "../../../store/slices/app/comps/snackbar"
import { useDispatch, useSelector } from "react-redux"
import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/app/request.service"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const authLogin = useSelector((state) => state.authReducer.authData.login)
  const authPass = useSelector((state) => state.authReducer.authData.pass)
  const token = useSelector(state => state.authReducer.authData.token)

  const TOKEN = Cookies.get("accessToken")

  const finishloading = () => {
    dispatch(setShow(false))
  }

  useEffect(() => {
    if ( TOKEN ) {

      dispatch(setOpen(true))
      dispatch(setType("success"))
      dispatch(setMessage("Вы успешно авторизовались в системе"))

      setTimeout(() => {
        dispatch(setOpen(false))
        navigate("/metriva/protocol")
      }, 800)
    }

    false && console.log(Cookies.get("accessToken"))
  }, [ navigate, token ])

  const handleAuth = async () => {
    dispatch(setShow(true))

    const { status, data } = await R.auth(authLogin, authPass, finishloading)

    if ( status === 200 && data.token ) {
      const token = data.token

      false && console.log(data)

      false && D.set("userRole", data.user.role)
      false && D.set("userName", data.user.name)
      false && D.set("userSurName", data.user.surname)
      
      D.set("companyId", data.user.company_id)
      D.set("activeMenuItem", "protocol")

      data.user.company_id && false && dispatch(setCompanyId(data.user.company_id))
      data.user.company_id && false && dispatch(setCompanyIdHeader(data.user.company_id))

      Cookies.set("accessToken", token, { secure: true })
      dispatch(setAuthToken(token))

      // ----------------------------------------------------------------
      // внимание область говнокода - нужно придумать получать куки без обновления
      // ----------------------------------------------------------------
      // eslint-disable-next-line no-self-assign
      document.location.href = document.location.href
    } else {
      dispatch(setOpen(true))
      dispatch(setType("error"))
      dispatch(
        setMessage("Введены неверные логин или пароль - попробуйте снова")
      )
    }
  }

  const handleKeyPress = (e) => {
    e.key === "Enter" && handleAuth()
  }

  const changeLogin = (param) => {
    dispatch(setAuthLogin(param))
  }
  const changePass = (param) => {
    dispatch(setAuthPass(param))
  }

  return (
    <React.Fragment>
      <Feedback />
      <PositionedSnackbar />

      <div className="login_wrapper">
        <div className="login_container">
          <div className="logo_container">
            <img src={Logo} className="logoLogin" alt="Logo" />
          </div>
          <div style={{ margin: " 10px 0 30px" }}>
            <Input
              label={"Введите логин"}
              type={"text"}
              placeholder={"Название вашего аккаунта"}
              value={authLogin}
              isDisabled={!Cookies.get("accessToken") ? false : true}
              actions={{
                change: changeLogin,
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Input
              label={"Введите пароль"}
              type={"password"}
              placeholder={"********"}
              value={authPass}
              isDisabled={!Cookies.get("accessToken") ? false : true}
              actions={{
                change: changePass,
              }}
            />
          </div>
          <Button
            variant="contained"
            disabled={authLogin && authPass ? false : true}
            onClick={handleAuth}
            sx={{ mt: 1, mr: 1 }}
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "10px 20px 12px",
              margin: 0,
              borderRadius: "12px",
              marginTop: "10px",
            }}
          >
            Войти в систему
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
