import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"

import Input from "../../components/LocalElements/Input/InputMUI"
import api from "../../../services/app/axios-config.service"

const EditPassword = () => {
  const navigate = useNavigate()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const token = useSelector(state => state.authReducer.authData.token)

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const currentPassportChange = (param) => {
    setCurrentPassword(param)
  }

  const newPassportChange = (param) => {
    setNewPassword(param)
  }

  const confirmPassportChange = (param) => {
    setConfirmPassword(param)
  }

  const [errors, setErrors] = useState({})

  const handlePasswordChange = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/password`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }

      const requestData = {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmPassword,
      }

      await api.post(apiUrl, requestData, { headers })

      navigate("/")
    } catch (error) {
      setErrors(error.response.data.errors)
    }
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="edit-password-box">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <h2>Сменить пароль</h2>
          <div
            className="close-icon"
            onClick={() => navigate("/metriva/protocols")}
          >
            <i className="fas fa-times user-icon-exit" />
          </div>
        </div>

        <div style={{ width: "100%", marginBottom: "20px" }}>
          <Input
            label={"Текущий пароль"}
            type={"password"}
            placeholder={"***********"}
            value={currentPassword || ""}
            actions={{
              change: currentPassportChange,
            }}
          />
          { errors.current_password && (
            <div className="error">{errors.current_password[0]}</div>
          )}
        </div>
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <Input
            label={"Новый пароль"}
            type={"password"}
            placeholder={"***********"}
            value={newPassword || ""}
            actions={{
              change: newPassportChange,
            }}
          />
          { errors.password && <div className="error">{errors.password[0]}</div> }
        </div>
        <div style={{ width: "100%", marginBottom: "25px" }}>
          <Input
            label={"Подтвердить пароль"}
            type={"password"}
            placeholder={"***********"}
            value={confirmPassword || ""}
            actions={{
              change: confirmPassportChange,
            }}
          />
          { errors.password_confirmation && (
            <div className="error">{errors.password_confirmation[0]}</div>
          )}
        </div>

        <div style={{ marginTop: "10px", width: "100%" }}>
          <Button
            variant="contained"
            onClick={handlePasswordChange}
            sx={{ mt: 1, mr: 1 }}
            style={{
              textTransform: "none",
              fontSize: "15px",
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: "11px 20px 11px",
              margin: 0,
              borderRadius: "12px",
              width: "100%",
            }}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </main>
  )
}

export default EditPassword
