// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from "react";
import { Routes, Route } from "react-router-dom";

import EditPassword from "../app/pages/Auth/EditPassword";

import NewProtocol from "../app/pages/Protocols/NewProtocol";
import ProtocolsIndex from "../app/pages/Protocols/ProtocolsIndex";
import EditProtocol from "../app/pages/Protocols/EditProtocol";
import CopyProtocol from "../app/pages/Protocols/CopyProtocol";
import Show from "../app/pages/Protocols/Show";

import Users from "../app/pages/Users/Users";
import AddUsers from "../app/pages/Users/AddUsers";
import EditUsers from "../app/pages/Users/EditUsers";
import ShowUsers from "../app/pages/Users/ShowUsers";

import Meters from "../app/pages/Meters/Meters";
import AddMetersWater from "../app/pages/Meters/water/AddMeters";
import AddMetersGas from "../app/pages/Meters/gas/AddMetersGas";
import MeterShow from "../app/pages/Meters/MeterShow";
import EditMetersWater from "../app/pages/Meters/water/EditMeters";
import EditMetersGas from "../app/pages/Meters/gas/EditMetersGas";

import Cases from "../app/pages/Cases/Cases";
import AddOfficialUser from "../app/pages/Cases/AddOfficialUser";
import EditOfficialUser from "../app/pages/Cases/EditOfficialUser";
import AddCases from "../app/pages/Cases/AddCases";
import ShowCases from "../app/pages/Cases/ShowCases";
import EditCases from "../app/pages/Cases/EditCases";

import Company from "../app/pages/Сompanies/Сompanies";
import AddCompany from "../app/pages/Сompanies/AddСompanies";
import ShowCompany from "../app/pages/Сompanies/ShowСompanies";

import CompanyShow from "../app/pages/Company/CompanyShow";
import CompanyEdit from "../app/pages/Company/CompanyEdit";

import Journals from "../app/pages/Journals/Journals";
import AddTickets from "../app/components/Journals/JournalTickets/Add";
import EditTickets from "../app/components/Journals/JournalTickets/Edit";
import AddPersonalMetrolog from "../app/components/Journals/JournalPersonal/Add";
import EditPersonalMetrolog from "../app/components/Journals/JournalPersonal/Edit";
import EditJournalConditionsCase from "../app/components/Journals/JournalConditionsCases/Edit";
import AddReceived from "../app/components/Journals/JournalReceivedForVerification/Add";

import JournalsArchive from "../app/pages/Journals/JournalsArchive";
import JournalsArchiveEntries from "../app/pages/Journals/JournalsArchiveEntries";
import JournalsSettings from "../app/pages/Journals/JournalsSettings";

import Etalon from "../app/pages/Etalon/IndexEtalon";
import AddEtalon from "../app/pages/Etalon/AddEtalon";
import EditEtalon from "../app/pages/Etalon/EditEtalon";

import NotForMobile from "../app/pages/NotForMobile";

import useWindowDimensions from "../app/hooks/useWindowDimensions";

const RoutesComponent = () => {
  const { width } = useWindowDimensions();

  return (
    <Routes>
      <Route path="protocol" element={<NewProtocol />} />

      <Route
        path="protocols"
        element={width > 100 ? <ProtocolsIndex /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id"
        element={width > 1050 ? <Show /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id/copy"
        element={width > 100 ? <CopyProtocol /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id/edit"
        element={width > 100 ? <EditProtocol /> : <NotForMobile />}
      />

      <Route
        path="users"
        element={width > 1050 ? <Users /> : <NotForMobile />}
      />
      <Route
        path="users/create"
        element={width > 1050 ? <AddUsers /> : <NotForMobile />}
      />
      <Route path="users/:users_id/edit" element={<EditUsers />} />
      <Route path="users/:users_id" element={<ShowUsers />} />

      <Route path="meters" element={<Meters />} />
      <Route path="meters/create_water_base" element={<AddMetersWater />} />
      <Route path="meters/create_gas_base" element={<AddMetersGas />} />
      <Route path="meters/:meters_id" element={<MeterShow />} />
      <Route
        path="meters/:meters_id/edit_water"
        element={<EditMetersWater />}
      />
      <Route path="meters/:meters_id/edit_gas" element={<EditMetersGas />} />

      <Route
        path="cases"
        element={width > 100 ? <Cases /> : <NotForMobile />}
      />
      <Route path="cases/create" element={<AddCases />} />
      <Route path="cases/create_official_user" element={<AddOfficialUser />} />
      <Route path="cases/:cases_id" element={<ShowCases />} />
      <Route path="cases/:cases_id/edit" element={<EditCases />} />
      <Route
        path="cases/:master_id/edit_master"
        element={<EditOfficialUser />}
      />

      <Route
        path="companies"
        element={width > 1050 ? <Company /> : <NotForMobile />}
      />
      <Route path="companies/create" element={<AddCompany />} />
      <Route path="companies/:company_id" element={<ShowCompany />} />

      <Route path="company" element={<CompanyShow />} />
      <Route path="company/:companyAdminId/edit" element={<CompanyEdit />} />

      <Route path="edit_password" element={<EditPassword />} />

      <Route
        path="journals"
        element={width > 1050 ? <Journals /> : <NotForMobile />}
      />
      <Route
        path="journals/archive"
        element={width > 1050 ? <JournalsArchive /> : <NotForMobile />}
      />
      <Route
        path="journals/archive_entries"
        element={width > 1050 ? <JournalsArchiveEntries /> : <NotForMobile />}
      />
      <Route
        path="journals/create_line_journal_complect"
        element={<React.Fragment />}
      />
      <Route
        path="journals/:journal_id/edit_complect"
        element={<React.Fragment />}
      />
      <Route
        path="journals/create_line_verification_schedule"
        element={<React.Fragment />}
      />
      <Route
        path="journals/:journal_id/edit_verification"
        element={<React.Fragment />}
      />
      <Route
        path="journals/:journal_id/edit_conditions-of-cases"
        element={<EditJournalConditionsCase />}
      />

      <Route path="journals/create_line_tickets" element={<AddTickets />} />
      <Route
        path="journals/:journal_id/edit_tickets"
        element={<EditTickets />}
      />

      <Route
        path="journals/create_line_personal_master-poveritel"
        element={<AddPersonalMetrolog />}
      />
      <Route
        path="journals/:journal_id/edit_personal_master-poveritel"
        element={<EditPersonalMetrolog />}
      />

      <Route path="journals/create_line_received" element={<AddReceived />} />

      <Route
        path="etalon"
        element={width > 1050 ? <Etalon /> : <NotForMobile />}
      />
      <Route path="etalon/create" element={<AddEtalon />} />
      <Route path="etalon/:etalon_id/edit" element={<EditEtalon />} />

      <Route path="settings">
        <Route
          path="journals"
          element={width > 1050 ? <JournalsSettings /> : <NotForMobile />}
        />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
