import React, { ReactElement } from "react"
import { useSelector } from "react-redux"
import JournalsView from "./views/JournalsView.tsx"

const Settings: React.FC = (): ReactElement => {
  const viewVariant = useSelector((state: any) => state.adminReducer.view)

  return (
    <React.Fragment>
      { viewVariant === 'journals' && <JournalsView /> }
    </React.Fragment>
  )
}

export default Settings