// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import R from "../../../services/app/request.service"
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Collapse,
  Tooltip,
  Checkbox,
} from "@mui/material"
import DataUser from "../../components/Users/DataUser"
import SelectedRole from "../../components/Users/SelectedRole"
import SelectedRegions from "../../components/Users/SelectedRegions"
import SelectedUsers from "../../components/Users/SelectedUsers"
import SelectedCases from "../../components/Users/SelectedCases"
import Input from "../../components/LocalElements/Input/InputMUI"
import Selector from "../../components/LocalElements/Selector/reactSelect/Selector"
import {
  changeData,
  resetCreateData,
} from "../../../store/slices/app/views/users"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/app/comps/snackbar"
import {
  refreshUsers,
  refreshHeader,
} from "../../../store/slices/app/controlers/updater"

const AddUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const userRole = useSelector((state) => state.headerReducer.role)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyId = useSelector((state) => state.companyIdReducer.companyId)
  const regionIds = useSelector(
    (state) => state.usersReducer.createData.regionIds
  )
  const rolesId = useSelector((state) => state.usersReducer.createData.rolesId)
  const casesIds = useSelector(
    (state) => state.usersReducer.createData.casesIds
  )
  const userId = useSelector((state) => state.usersReducer.createData.userId)
  const usersId = useSelector((state) => state.usersReducer.createData.usersId)
  const isTrusted = useSelector(
    (state) => state.usersReducer.createData.isTrusted
  )
  const name = useSelector((state) => state.usersReducer.createData.name)
  const surname = useSelector((state) => state.usersReducer.createData.surname)
  const patr = useSelector((state) => state.usersReducer.createData.patr)
  const login = useSelector((state) => state.usersReducer.createData.login)
  const password = useSelector(
    (state) => state.usersReducer.createData.password
  )
  const email = useSelector((state) => state.usersReducer.createData.email)

  const [isOfficialUser, setIsOfficialUser] = useState("")
  const [role, setUseRole] = useState("")
  const [snils, setSnils] = useState("")
  const [measurementIds, setMeasurementIds] = useState("")

  const handleTrustedChange = (event) => {
    const value = event.target.value === "true"
    dispatch(
      changeData({
        value: "isTrusted",
        label: value,
      })
    )
  }

  const handleIsOfficialChange = (event) => {
    setIsOfficialUser(event.target.checked)
  }

  const handleRoleChange = (param) => {
    setUseRole(param)
  }

  const handleSnilsChange = (param) => {
    let formattedValue = param
      .replace(/\D/g, "")
      .slice(0, 11)
      .replace(/(\d{3})(?=\d{3})/g, "$1-")
      .replace(/(\d{2})$/, " $1")
    setSnils(formattedValue)
  }

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOptions) => {
    const selectedIds = selectedOptions.map((options) => options.value)
    setMeasurementIds(selectedIds)
  }

  useEffect(() => {
    (async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      surname: surname,
      patr: patr,
      login: login,
      password: password,
      role: rolesId,
      email: email,
      metrologist_ids: usersId,
      region_ids: regionIds,
      case_ids: casesIds,
      brigadier_id: userId,
    }

    if (isOfficialUser) {
      data.arshin_master = {
        snils: snils,
        post: role,
        measurements: measurementIds,
      }
    }

    if (userRole === "super" && companyId) {
      data.company_id = companyId
    }

    if (rolesId === "metrologist") {
      data.is_trusted = isTrusted
    }

    const { status } = await R.addUser(data)

    if (status === 200) {
      dispatch(refreshUsers())
      dispatch(refreshHeader())
      dispatch(setMessage("Пользователь успешно создан"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      navigate("/metriva/users")
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Пользователь не добавлен - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate("/metriva/users")
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocols}>
        <i
          className="fas fa-arrow-left icon_back"
          style={{ width: "30px", height: "30px", marginRight: "6px" }}
        />
        <p>Назад к списку пользователей</p>
      </div>

      <div className="box">
        <h2 style={{ marginBottom: "25px" }}>
          Данные учетной записи пользователя
        </h2>
        <DataUser />

        <h2 style={{ marginBottom: "14px" }}>Рабочие параметры</h2>
        <div className="flexContainerWithGap" style={{ marginBottom: "20px" }}>
          <SelectedRegions />
          <SelectedRole />
        </div>

        {( rolesId === "brigadier" || rolesId === "metrologist" ) && <div className="flexContainerWithGap" style={{ marginBottom: "20px" }}>
          { rolesId === "metrologist" && (
            <div className="width24Point5Percent">
              <span
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Статус "Опытный"
                <Tooltip
                  title="Данные необходимы для передачи в Росаккредитацию ФСА"
                  placement="top"
                  style={{ marginLeft: "7px" }}
                >
                  <i
                    className="fas fa-question-circle question"
                    style={{ marginLeft: "7px", cursor: "pointer" }}
                  />
                </Tooltip>
              </span>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={isTrusted}
                onChange={handleTrustedChange}
                sx={{
                  height: "50px",
                  alignItems: "end",
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={"Есть"}
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily:
                        "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={"Нет"}
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily:
                        "'Wix Madefor Display', sans-serif !important",
                      fontSize: "15px !important",
                      fontWeight: 400,
                      color: "#132532",
                    },
                  }}
                />
              </RadioGroup>
            </div>
          )}
          {( rolesId === "brigadier" || rolesId === "metrologist" ) && <div className="width50Percent">
            
            <SelectedUsers />

          </div> }
          {( rolesId === "brigadier" || rolesId === "metrologist" ) && <div className="width50Percent">
            
            <SelectedCases />
            
          </div> }
        </div> }

        <h2 style={{ marginBottom: "14px" }}>Официальная отчетность</h2>
        <p style={{ marginLeft: "10px" }}>
          Создать Официального пользователя СП
          <Tooltip
            // ----------------------------------------------------------------
            // title="Информация из учетной записи будет использована для регистрации Официального пользователя СП, данные которого доступны для передачи во ФГИС «Аршин», ФСА и для формирования отчетности по поверочной деятельности (см. разделы «Комплекты СП» и «Журналы»)."
            // ----------------------------------------------------------------
            placement="top"
            style={{ marginLeft: "7px" }}
          >
            <i
              className="fas fa-question-circle question"
              style={{ marginLeft: "7px", cursor: "pointer" }}
            />
          </Tooltip>
        </p>

        <div
          className="flexContainer"
          style={{ alignItems: "center", gap: "3px", margin: "3px 0 0" }}
        >
          <Checkbox
            checked={isOfficialUser}
            onChange={handleIsOfficialChange}
            id="isOfficial"
          />
          <label for="isOfficial" style={{ cursor: "pointer" }}>
            Использовать данные учетной записи для регистрации нового Официального пользователя СП
          </label>
        </div>

        <Collapse in={isOfficialUser}>
          <div className="flexContainerWithGap" style={{ margin: "16px 0 0" }}>
            <div className="width24Point5Percent">
              <Input
                label={
                  <div>
                    Должность в компании
                    <Tooltip
                      title="Рабочая должность официального пользователя комплекта СП, нужна для заполнения журнала выдачи средств поверки"
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i className="fas fa-question-circle question" />
                    </Tooltip>
                  </div>
                }
                type={"text"}
                placeholder={"Пример: Поверитель СИ"}
                value={role}
                actions={{
                  change: handleRoleChange,
                }}
                autoComplete={"new-password"}
              />
            </div>
            <div className="width24Point5Percent">
              <Input
                label={
                  <div>
                    Снилс
                    <Tooltip
                      title="Информация необходима для передачи данных в Росаккредитацию (ФСА)"
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i className="fas fa-question-circle question" />
                    </Tooltip>
                  </div>
                }
                type={"text"}
                placeholder={"XXX-XXX-XXX XX"}
                value={snils}
                actions={{
                  change: handleSnilsChange,
                }}
                autoComplete={"new-password"}
              />
            </div>
            <div className="width50Percent">
              <Selector
                placeholder={"Выберите область измерния"}
                options={options}
                value={options.filter((option) =>
                  measurementIds.includes(option.value)
                )}
                onChange={getValue}
                isMulti={true}
                isSearchable={true}
                noOptionsMessage={() => "Нет доступных областей измерения"}
                label={{
                  text: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Рабочая область измерения
                      <Tooltip
                        title="Области измерения, в рамках которых пользователь имеет официальное право осуществлять поверочную деятельность"
                        placement="top"
                      >
                        <i
                          className="fas fa-question-circle"
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                        />
                      </Tooltip>
                    </div>
                  ),
                  topBg: "white",
                  bottomBg: "white",
                }}
              />
            </div>
          </div>
        </Collapse>
      </div>

      <div style={{ marginTop: "14px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Добавить пользователя
        </Button>
      </div>
    </main>
  )
}

export default AddUsers
