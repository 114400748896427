import React, { useState } from "react"

import MuiAccordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import AddBoxIcon from "@mui/icons-material/AddBox"
import { styled } from "@mui/material/styles"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
  borderRadius: "6px",
  padding: "14px 8px",
  "&::before": {
    display: "none",
  },
}))

const AccordionComponent = (props) => {
  const {
    title = "",
    data = [],
    dataType = "default",
    backgroundColor = null,
    border = null,
    titleSize = null,
    padding = null,
    mt = null,
    isExpanded = false,
  } = props

  const [expandedDescription, setExpandedDescription] = useState(isExpanded)

  return (
    <React.Fragment>
      <Accordion
        onChange={() => setExpandedDescription((prev) => !prev)}
        expanded={expandedDescription}
        style={{
          backgroundColor: backgroundColor ?? "#F8F9FC",
          border: border ?? "none",
          boxShadow: "none",
          borderRadius: "12px",
          padding: padding ?? "14px 8px",
          marginTop: mt ? mt + "px" : "0px",
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            fontSize: titleSize ?? "15px",
            fontWeight: 600,
            paddingTop: "0px",
          }}
        >
          {expandedDescription ? (
            <IndeterminateCheckBoxIcon
              fontSize="medium"
              sx={{
                color: "#0084e2",
                marginRight: "10px",
                marginTop: "-1px",
              }}
            />
          ) : (
            <AddBoxIcon
              fontSize="medium"
              sx={{
                color: "#0084e2",
                marginRight: "10px",
                marginTop: "-1px",
              }}
            />
          )}
          <h3 style={{ marginTop: "-1px" }}>{title}</h3>
        </AccordionSummary>
        <AccordionDetails>
          {dataType === "default" && <React.Fragment></React.Fragment>}
          {dataType === "custom" && <React.Fragment>{data}</React.Fragment>}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default AccordionComponent
