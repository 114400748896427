// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../store/slices/app/controlers/auth'
import axios from "axios"
import Cookies from "js-cookie"
import D from "../../store/localStorage/dispatcher"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const AxiosInterceptor = ({ children  }) => {
  const [isSet, setIsSet] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const resInter = response => {
      return response
    }
    const errInter = error => {
      if ( error.response && error.response.status === 401 ) {
        D.cls()
        
        Cookies.remove("accessToken")
        dispatch(setAuthToken(""))

        // ----------------------------------------------------------------
        // нарушает принципы spa приложений, убрать при возможности
        // ----------------------------------------------------------------
        
        // window.location.href = "/"

        // ----------------------------------------------------------------
        // ----------------------------------------------------------------
      }

      return Promise.reject(error)
    }

    const interceptor = instance.interceptors.response.use(resInter, errInter)
    setIsSet(true)

    return () => instance.interceptors.response.eject(interceptor)
  }, [])

  return isSet && children
}

export default instance
export { AxiosInterceptor }
