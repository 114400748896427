// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import api from "../../services/app/axios-config.service"

import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { IconButton } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import LockIcon from "@mui/icons-material/Lock"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import MoreVertIcon from "@mui/icons-material/MoreVert"

import Fade from "@mui/material/Fade"
import StoreIcon from "@mui/icons-material/Store"

import protocols from "../../img/menu/protocols.svg"
import magazines from "../../img/menu/magazines.svg"
import users from "../../img/menu/users.svg"
import meters from "../../img/menu/meters.svg"
import etalons from "../../img/menu/etalons.svg"
import cases from "../../img/menu/cases.svg"
import companies from "../../img/menu/companies.svg"

import Logo from "../../img/metriva-logo.svg"
import refreshImg from "../../img/refresh.svg"
import defaultLogo from "../../img/logo.svg"

import { setIsToggle } from "../../store/slices/app/controlers/toggle"
import { refreshHeader } from "../../store/slices/app/controlers/updater"
import { setAuthToken } from "../../store/slices/app/controlers/auth"
import useWindowDimensions from "../hooks/useWindowDimensions"

import D from "../../store/localStorage/dispatcher"

const NavBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useSelector(state => state.authReducer.authData.token)
  
  // ----------------------------------------------------------------
  // подстраховка на случай если все сломается
  // const token = useSelector(state => state.authReducer.authData.token)
  // ----------------------------------------------------------------

  const refreshImgRef = useRef(null)
  const { width } = useWindowDimensions()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const logoPath = useSelector((state) => state.headerReducer.logo)
  const role = useSelector((state) => state.headerReducer.role)
  const surname = useSelector((state) => state.headerReducer.surname)
  const name = useSelector((state) => state.headerReducer.name)

  const pageTitle = useSelector((state) => state.pageNameReducer.title)

  // ----------------------------------------------------------------
  // const logoPath = useSelector((state) => state.headerReducer.logo)
  // const role = D.get("userRole")
  // const surname = D.get("userSurName")
  // const name = D.get("userName")
  // ----------------------------------------------------------------

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const companiesPing = useSelector((state) => state.updaterReducer.companiesPing)
  const casesPing = useSelector((state) => state.updaterReducer.casesPing)
  const verificationsPing = useSelector((state) => state.updaterReducer.verificationsPing)
  const ticketsPing = useSelector((state) => state.updaterReducer.ticketsPing)
  const conditionsPing = useSelector((state) => state.updaterReducer.conditionsPing)
  const complectsPing = useSelector((state) => state.updaterReducer.complectsPing)
  const etalonsPing = useSelector((state) => state.updaterReducer.etalonsPing)
  const metersPing = useSelector((state) => state.updaterReducer.metersPing)
  const usersPing = useSelector((state) => state.updaterReducer.usersPing)
  const protocolsPing = useSelector((state) => state.updaterReducer.protocolsPing)
  const headerPing = useSelector((state) => state.updaterReducer.headerPing)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const openMenu = Boolean(anchorElMenu)

  const handleCloseMenu = () => setAnchorElMenu(null)

  const translateRole = useMemo(() => {
    let roleLocal = ""

    if ( role === "admin" ) {
      roleLocal = "админ"
    } else if ( role === "super" ) {
      roleLocal = "суперадмин"
    } else if ( role === "metrologist" ) {
      roleLocal = "поверитель"
    } else if ( role === "brigadier" ) {
      roleLocal = "руководитель"
    }

    return roleLocal
  }, [role])

  const handleLogout = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/logout`

      const axiosInstance = api.create({
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })

      const response = await axiosInstance.post(apiUrl)

      if ( response.status === 200 ) {
        handleCloseMenu()

        Cookies.remove("accessToken")
        localStorage.clear()
        
        dispatch(setAuthToken(''))
      } else {
        console.error("Ошибка при выходе из системы")
      }
    } catch (error) {
      console.error("Произошла ошибка:", error)
    }
  }

  const handleEditPassword = () => {
    navigate(`edit_password`)
    handleCloseMenu()
  }

  const handleSidebarToggle = (event) => {
    dispatch(setIsToggle(!isToggle))
    setAnchorElMenu(event.currentTarget)
  }

  useEffect(() => {
    width > 1050 && refreshImgRef.current.setAttribute("class", "pop_up_exit_refresh_img")

    setTimeout(() => {
      width > 1050 && refreshImgRef.current.removeAttribute("class")
    }, 2400)
  }, [
    companiesPing,
    casesPing,
    verificationsPing,
    ticketsPing,
    conditionsPing,
    complectsPing,
    etalonsPing,
    metersPing,
    usersPing,
    protocolsPing,
    headerPing,
  ])

  useEffect(() => {
    dispatch(refreshHeader())
  }, [])

  return (
    <React.Fragment>
      <nav 
        className="header" 
        style={ 
          width > 1050 
            ? {} 
            : { 
                height: '68px', 
                paddingLeft: '24px', 
                paddingRight: '24px', 
                backgroundColor: '#f8f9fc',
                borderBottom: 'none',
                zIndex: 3
              }}
      >
        { width <= 1050 && (
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorElMenu}
            open={openMenu}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
                mt: "-42px",
                ml: "-6px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                dispatch(refreshHeader())
                navigate(`/metriva/protocol`)
                handleCloseMenu()
              }}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "50px",
                paddingRight: "60px",
                display: 'flex',
                flexFlow: 'row',
                marginBottom: '8px',
                gap: '12px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '40px',
                  height: '40px',
                  backgroundColor: 'rgb(248, 249, 252)',
                  borderRadius: '50%'
                }}
              >
                <img
                  alt={""}
                  src={magazines}
                  style={{ height: "20px" }}
                />
              </div>
              <h5>Новый протокол</h5>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                dispatch(refreshHeader())
                navigate(`/metriva/protocols`)
                handleCloseMenu()
              }}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "50px",
                display: 'flex',
                flexFlow: 'row',
                marginBottom: '8px',
                gap: '12px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '40px',
                  height: '40px',
                  backgroundColor: 'rgb(248, 249, 252)',
                  borderRadius: '50%'
                }}
              >
                <img
                  alt={""}
                  src={protocols}
                  style={{ height: "20px" }}
                />
              </div>
              <h5>Протоколы</h5>
            </MenuItem>
            {( userRole === "super" ) && (
              <MenuItem
                onClick={() => {
                  handleClose()
                  dispatch(refreshHeader())
                  navigate(`/metriva/journals`)
                  handleCloseMenu()
                }}
                style={{
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  fontSize: "15px",
                  height: "50px",
                  display: 'flex',
                  flexFlow: 'row',
                  marginBottom: '8px',
                  gap: '12px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgb(248, 249, 252)',
                    borderRadius: '50%'
                  }}
                >
                  <img
                    alt={""}
                    src={magazines}
                    style={{ height: "20px" }}
                  />
                </div>
                <h5>Журналы</h5>
              </MenuItem>
            )}
            { userRole !== "metrologist" && (
              <MenuItem
                onClick={() => {
                  handleClose()
                  dispatch(refreshHeader())
                  navigate(`/metriva/users`)
                  handleCloseMenu()
                }}
                style={{
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  fontSize: "15px",
                  height: "50px",
                  display: 'flex',
                  flexFlow: 'row',
                  marginBottom: '8px',
                  gap: '12px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgb(248, 249, 252)',
                    borderRadius: '50%'
                  }}
                >
                  <img
                    alt={""}
                    src={users}
                    style={{ height: "24px" }}
                  />
                </div>
                <h5>Пользователи</h5>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose()
                dispatch(refreshHeader())
                navigate(`/metriva/meters`)
                handleCloseMenu()
              }}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "50px",
                display: 'flex',
                flexFlow: 'row',
                marginBottom: '8px',
                gap: '12px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '40px',
                  height: '40px',
                  backgroundColor: 'rgb(248, 249, 252)',
                  borderRadius: '50%'
                }}
              >
                <img
                  alt={""}
                  src={meters}
                  style={{ height: "24px" }}
                />
              </div>
              <h5>База приборов</h5>
            </MenuItem>
            {( userRole === "super" || userRole === "admin" ) && (
              <MenuItem
                onClick={() => {
                  handleClose()
                  dispatch(refreshHeader())
                  navigate(`/metriva/etalon`)
                  handleCloseMenu()
                }}
                style={{
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  fontSize: "15px",
                  height: "50px",
                  display: 'flex',
                  flexFlow: 'row',
                  marginBottom: '8px',
                  gap: '12px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgb(248, 249, 252)',
                    borderRadius: '50%'
                  }}
                >
                  <img
                    alt={""}
                    src={etalons}
                    style={{ height: "24px" }}
                  />
                </div>
                <h5>Эталоны</h5>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose()
                dispatch(refreshHeader())
                navigate(`/metriva/cases`)
                handleCloseMenu()
              }}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "50px",
                display: 'flex',
                flexFlow: 'row',
                marginBottom: '8px',
                gap: '12px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '40px',
                  height: '40px',
                  backgroundColor: 'rgb(248, 249, 252)',
                  borderRadius: '50%'
                }}
              >
                <img
                  alt={""}
                  src={cases}
                  style={{ height: "24px" }}
                />
              </div>
              <h5>Комплекты СП</h5>
            </MenuItem>
            { userRole === "super" && (
              <MenuItem
                onClick={() => {
                  handleClose()
                  dispatch(refreshHeader())
                  navigate(`/metriva/companies`)
                  handleCloseMenu()
                }}
                style={{
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  fontSize: "15px",
                  height: "50px",
                  display: 'flex',
                  flexFlow: 'row',
                  marginBottom: '8px',
                  gap: '12px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgb(248, 249, 252)',
                    borderRadius: '50%'
                  }}
                >
                  <img
                    alt={""}
                    src={companies}
                    style={{ height: "24px" }}
                  />
                </div>
                <h5>Компании</h5>
              </MenuItem>
            )}
            { userRole === "admin" && (
              <MenuItem
                onClick={() => {
                  handleClose()
                  dispatch(refreshHeader())
                  navigate(`/metriva/company`)
                  handleCloseMenu()
                }}
                style={{
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  fontSize: "15px",
                  height: "50px",
                  display: 'flex',
                  flexFlow: 'row',
                  marginBottom: '8px',
                  gap: '12px'
                }}
              >
                <StoreIcon style={{ marginRight: "16px" }} />
                <h5>Компания</h5>
              </MenuItem>
            )}
            { width <= 1050 && false && <MenuItem
              onClick={handleEditPassword}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "46px",
              }}
            >
              <LockIcon style={{ marginRight: "8px" }} />
              Сменить пароль
            </MenuItem> }
            { width <= 1050 && false && <MenuItem
              onClick={handleLogout}
              style={{
                fontFamily: '"Wix Madefor Display", sans-serif',
                fontSize: "15px",
                height: "46px",
              }}
            >
              <ExitToAppIcon style={{ marginRight: "8px" }} />
              Выйти из системы
            </MenuItem> }
          </Menu>
        )}

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 3px rgba(0,0,0,0.32))",
              mt: 1.5,
              ml: 1.3,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1.5,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 18,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={handleEditPassword}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: "15px",
              height: "46px",
            }}
          >
            <LockIcon style={{ marginRight: "8px" }} />
            Сменить пароль
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: "15px",
              height: "46px",
            }}
          >
            <ExitToAppIcon style={{ marginRight: "8px" }} />
            Выйти из системы
          </MenuItem>
        </Menu>

        <div className="toggleLogoContainer">
          { width <= 1050 && <ToggleButtonGroup
            size="small"
            style={{ zIndex: "1000", marginLeft: "-9px" }}
            aria-label="Small sizes"
            onClick={handleSidebarToggle}
          >
            <ToggleButton style={{ color: "#0084e2", border: "none" }}>
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </ToggleButtonGroup> }

          { width > 1050 && <div 
            className="logo"
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            { false && <img
              src={logoPath ? logoPath : Logo}
              alt="Default Logo"
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
              }}
            /> }
            <img 
              alt={""}
              src={defaultLogo}
              style={{
                width: '30px',
              }}
            />
            <h1
              style={{
                color: 'rgb(0, 132, 226)',
                display: 'block',
                fontSize: '24px',
                letterSpacing: '2px',
                fontWeight: 800,
                marginTop: '14px'
              }}
            >
              МЕТРИВА
            </h1>
          </div> }
        </div>
        
        { width <= 1050 && <div >
          <h3 
            style={{ 
              fontSize: "18px" 
            }}
          >
            { pageTitle ? pageTitle : "Заголовок страницы" }
          </h3>
        </div> }
        <div className="profile">
          { width > 1050 && <div 
            className="profile_user"
            style={{ gap: "8px" }}
          >
            <span>{ translateRole }</span>
            <h3>
              {`${surname} ${name}`}
            </h3>
          </div> }

          { width <= 1050 && <img 
            alt={""}
            src={users}
            onClick={handleClick}
            style={{
              display: "block",
              position: "relative",
              color: "rgb(0, 132, 226)"
            }}
          /> }

          { width > 1050 && <div
            className="pop_up_exit_system"
            style={{
              width: width > 1050 ? "88px" : "30px",
            }}
          >
            { width > 1050 && <div
              className="pop_up_exit"
              style={{
                display: "flex",
                backgroundColor: "rgba(0, 132, 226, 1)",
                border: "1px solid rgba(0, 132, 226, 1)",
              }}
            >
              <img alt={""} src={refreshImg} ref={refreshImgRef} />
            </div> }
            { width > 1050 && <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              style={{
                width: "30px",
                height: "30px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
              }}
            >
              <MoreVertIcon sx={{ width: 22, height: 22 }} />
            </IconButton> }
          </div> }
        </div>
      </nav>
    </React.Fragment>
  )
}

export default NavBar
